import React from "react";
import { useDispatch } from "react-redux";
import { axiosRequest, addMessage, errorHandler } from "app/utils";

import { CONSTANT } from "common/static";

const fetchData = (api, params) => async (dispatch) => {
  return await axiosRequest
    .get(api, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((err) => {
      dispatch(addMessage(errorHandler(err)));
      return Promise.reject(err);
    });
};
const useFetchApi = ({
  api,
  params = {},
  init = { contents: [] },
  onFetched = () => {},
  defaultFilterData,
}) => {
  const [filterFormData, setFilterFormData] = React.useState(
    defaultFilterData ?? {}
  );

  const paramsRef = React.useRef(params);
  const onFetchedRef = React.useRef(onFetched);
  const initRef = React.useRef(init);

  const [pageSize, setPageSize] = React.useState(
    params.pageSize || CONSTANT.DEFAULT_PAGE_SIZE
  );
  const [pageNumber, setPageNumber] = React.useState(1);

  const [data, setData] = React.useState(init);

  //state used for fetching export Report Data
  const [exportData, setExportData] = React.useState(init);

  const dispatch = useDispatch();

  const getData = React.useCallback(
    (apiArg) => {
      const params = paramsRef.current;
      if (apiArg?.api ?? api)
        dispatch(
          fetchData(apiArg?.api ?? api, {
            ...params,
            ...filterFormData,
            ...(apiArg?.params ?? {}),
            pageSize,
            pageNumber,
          })
        )
          .then((res) => {
            setData(res);
            setExportData(res);
            //console.log(res);
            onFetchedRef.current();
          })
          .catch(() => {
            setData(initRef.current);
          });
    },
    [
      dispatch,
      api,
      filterFormData,
      pageSize,
      pageNumber,
      paramsRef,
      onFetchedRef,
      initRef,
    ]
  );

  React.useEffect(() => {
    getData();
  }, [getData]);

  const changePage = React.useCallback((n) => setPageNumber(n), []);

  const changePageSize = React.useCallback((n) => setPageSize(n), []);

  const filter = React.useCallback((data) => setFilterFormData(data), []);

  const currentPageSize = React.useMemo(() => pageSize, [pageSize]);
  const currentPageNumber = React.useMemo(() => pageNumber, [pageNumber]);

  return {
    exportData,
    data,
    changePage,
    changePageSize,
    filter,
    getData,
    pageSize: currentPageSize,
    pageNumber: currentPageNumber,
  };
};

export default useFetchApi;
