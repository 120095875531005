import { axiosRequest, errorHandler, addMessage } from "app/utils";
import axiosQuery from "app/utils/axiosQuery";
import {
  FETCH_FISCAL_YEAR,
  FETCH_FISCAL_YEAR_DATE,
} from "./fiscalYearActionType";

const ENDPOINT_FISCAL_YEAR = "fiscalYear";

export function getFiscalYearList() {
  return (dispatch) => {
    axiosQuery
      .get(`${ENDPOINT_FISCAL_YEAR}/list`)
      .then((res) => {
        let data = ["076/077", "077/078", "078/079", "079/080"];
        let sortedData = res.data?.sort((a, b) => b.id - a.id);
        dispatch({
          type: FETCH_FISCAL_YEAR,
          payload: sortedData.filter((x) => data.some((p) => p == x.name)),
        });
      })
      .catch((error) => {
        dispatch(addMessage(errorHandler(error)));
      });
  };
}

export function searchFiscalYear(date) {
  return (dispatch) => {
    axiosRequest
      .get(`${ENDPOINT_FISCAL_YEAR}/${date}`, { params: date })
      .then((res) => {
        dispatch({ type: FETCH_FISCAL_YEAR_DATE, payload: res.data });
      })
      .catch((error) => {
        dispatch(addMessage(errorHandler(error)));
      });
  };
}
