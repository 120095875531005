import React from "react";
import "./footer.css";

function Footer({ children }) {
  return (
    <>
      {children}
      <footer className="footer">© 2023 Waterflow Technology</footer>;
    </>
  );
}

export default Footer;
