import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import * as id from "./authId";
import accessAll from "./accessAll";
import * as allComponentId from "./componentId";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "app/pages/ErrorPage/";
// authId                   eg. "SETUP_BROKER"
// componentId              eg. "CREATE"

// Change this to true to enable Authorization
const enableAuthorization = true;

export const useAuth = () => {
  const data = useSelector((state) => state.getAuth);

  //console.log('ss', data);

  const permissions = enableAuthorization ? data.permissions : accessAll;

  const allPermissionsKeys = Object.keys(permissions);

  // checks if a sidebar label needs to be displayed on sidebar
  const checkMenuAuth = (props) => {
    const { authId, componentId } = props;
    if (Array.isArray(authId)) {
      if (authId.some((e) => allPermissionsKeys.includes(e))) return true;
      else return false;
    }
    if (authId) {
      // IF COMPONENTiD is also provided then return true only if that audthId has that componentid permission
      if (componentId) {
        if (permissions[authId]) {
          if (permissions[authId]?.includes(componentId)) {
            return true;
          } else {
            return false;
          }
        }
      }
      if (permissions[authId]) {
        return true;
      }
      if (id[authId]) {
        if (id[authId].some((e) => allPermissionsKeys.includes(e))) return true;
      }
      return false;
    }
    throw new Error(
      "You might have forgotten to pass authId. Please Check where you have used checkMenuAuth function"
    );
  };

  //Checks if particular children component or parent component is authorized
  // if it is directly from route then pass authId
  // if it is from individual component then pass both authId and componentId
  const isAuthorized = React.useCallback(
    (props) => {
      const { authId, componentId } = props;
      const allComponenentIdValues = Object.values(allComponentId);
      if (componentId) {
        //Check if correct componentId has been supplied
        if (!allComponenentIdValues.includes(componentId))
          throw new Error(
            `Please pass one the following values as componentId: ${allComponenentIdValues}`
          );
        if (!authId) {
          throw new Error(
            "Please pass authId as well if you are passing componentId also."
          );
        }
        const isAuthorized = permissions[authId]
          ? permissions[authId].includes(componentId.toUpperCase())
          : false;
        return isAuthorized;
      } else if (authId) {
        const isAuthorized = permissions[authId];

        if (isAuthorized) {
          return isAuthorized;
        }
        return false;
      }
      throw new Error(
        "You might have forgotten to pass authId or component Id. Please Check where you have used isAuthorized function"
      );
    },
    [permissions]
  );
  //returns the parent component with authId prop passed along
  const checkAuth = (Wrapped, authId) => {
    if (!Wrapped || !authId) {
      throw new Error(
        "You might have forgotten to pass authId. Please Check where you've wrapped a component with checkAuth"
      );
    }
    if (authId) {
      const isAuthorized = permissions[authId];

      if (isAuthorized) {
        return function () {
          return (
            <ErrorBoundary FallbackComponent={ErrorPage}>
              <Wrapped
                authId={authId}
                allowedComponentId={permissions[authId]}
              />
            </ErrorBoundary>
          );
        };
      }
    }
    return function () {
      return (
        <div>You aren't Authorized to View This Page. Please contact admin</div>
      );
    };
  };

  return { isAuthorized, checkAuth, checkMenuAuth };
};

/**
 * Returns the component if authorized
 * @param {string} authId - authId that can be accessed as props in index.js
 * @param {"CREATE"|| "READ"|| "UPDATE"||"DELETE"} componentId
 * @param {string || boolean} renderMessage - renders the message supplied if it is string. if boolean then predefined message is rendered
 */
const ProtectedComponent = (props) => {
  const { authId, componentId, children, renderMessage } = props;
  const data = useSelector((state) => state.getAuth);
  const permissions = enableAuthorization ? data.permissions : accessAll;

  const allComponenentIdValues = Object.values(allComponentId);

  if (componentId) {
    //Check if correct componentId has been supplied
    if (!allComponenentIdValues.includes(componentId))
      throw new Error(
        `Please pass one the following values as componentId: ${allComponenentIdValues}`
      );

    // console.log(permissions);
    const isAuthorized = permissions[authId]
      ? permissions[authId].includes(componentId.toUpperCase())
      : false;
    if (isAuthorized) {
      return children;
    }
    return <UnAuthorizedRenderer renderMessage={renderMessage} />;
  } else if (authId) {
    const isAuthorized = permissions[authId];

    if (isAuthorized) {
      return children;
    }
    return <UnAuthorizedRenderer renderMessage={renderMessage} />;
  } else
    throw new Error(
      "You might have forgotten to pass componentId or authId. Please check ProtectedComponent wrapper"
    );
};

ProtectedComponent.propTypes = {
  authId: PropTypes.string.isRequired,
  componentId: function (props, propName, componentName) {
    if (!Object.values(allComponentId).includes(props[propName])) {
      return new Error(
        "Invalid prop `" +
          propName +
          " " +
          props[propName] +
          " " +
          "` supplied to" +
          " `" +
          componentName +
          "`. Validation failed. Please pass one of the following " +
          Object.values(allComponentId)
      );
    }
  },
  renderMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.bool,
  ]),
  children: PropTypes.element.isRequired,
};
//Renders conditionally based on renderMessage props if the user isn't authorized
function UnAuthorizedRenderer({ renderMessage }) {
  if (renderMessage) {
    if (typeof renderMessage === "boolean")
      return <div>You aren't authorized. Please contact the admin</div>;
    else return <div> {renderMessage}</div>;
  } else return null;
}

export default ProtectedComponent;
export { allComponentId as componentId };
