import { addMessage, axiosRequest, errorHandler } from "app/utils";

const ENDPOINT_COMPANY = "Company";

export const searchCompany = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT_COMPANY}/search`, { params, runInBackground: true })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};
export const fetchStockDetails = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT_COMPANY}/StockDetails`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};
export const fetchCompany = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT_COMPANY}`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export function addCompany(data) {
  return (dispatch) =>
    axiosRequest
      .post(ENDPOINT_COMPANY, data)
      .then((res) => {
        dispatch(
          addMessage({
            type: `success`,
            data: "Company Added Successfully",
            title: ``,
          })
        );
      })
      .catch((err) => dispatch(addMessage(errorHandler(err))));
}

export function editCompany(data) {
  return (dispatch) =>
    axiosRequest
      .put(`${ENDPOINT_COMPANY}/${data.id}`, data)
      .then((res) => {
        dispatch(
          addMessage({
            type: `success`,
            data: "Successfully edited",
            title: ``,
          })
        );
      })
      .catch((err) => dispatch(addMessage(errorHandler(err))));
}

export const deleteCompany = (id, remarks) => async (dispatch) => {
  return axiosRequest
    .delete(`${ENDPOINT_COMPANY}/${id}`, {
      params: { id, deleteRemarks: remarks },
    })
    .then((res) => {
      if (res.data) {
        dispatch(
          addMessage({
            type: `success`,
            data: "Successfully Deleted",
            title: ``,
          })
        );

        return Promise.resolve(res.data);
      } else {
        return Promise.reject("ERROR");
      }
    })
    .catch((err) => {
      dispatch(addMessage(errorHandler(err, "company")));
      return Promise.reject("ERROR");
    });
};

export const fetchStockSummary = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT_COMPANY}/stock`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};
