import { lazy } from "react";
import * as id from "app/authorization/authId";

const BankAssessment = lazy(() =>
  import("app/pages/FixedReturn/BankAssessment")
);
const FrAccount = lazy(() => import("app/pages/FixedReturn/FrAccounts"));
const FrPayment = lazy(() => import("app/pages/FixedReturn/FrPayments"));
const AddFixedReturn = lazy(() =>
  import("app/pages/FixedReturn/FrAccounts/AddFixedReturn")
);
const BfiBankAccount = lazy(() =>
  import("app/pages/FixedReturn/BfiBankAccount")
);
const BfiBankLimit = lazy(() => import("app/pages/FixedReturn/BfiBankLimit"));

const FixedReturn = lazy(() => import("app/pages/FixedReturn/FRReport"));
const FRSebonReport = lazy(() => import("app/pages/FixedReturn/FRSebonReport"));
const FRWithdrawal = lazy(() => import("app/pages/FixedReturn/FRWithdrawal"));
const FRInterest = lazy(() => import("app/pages/FixedReturn/FRInterest"));
const FRDashboard = lazy(() => import("app/pages/FixedReturn/FRDashboard"));

const fixedReturnRoutes = [
  {
    path: "/app/fixedReturn/FRdashboard",
    name: "Dashboard",
    component: FRDashboard,
    authId: id.FR_ACCOUNT,
  },
  {
    path: "/app/fixedReturn/bank-assessment",
    name: "Bank Assessment",
    component: BankAssessment,
    authId: id.BANK_ASSESSMENT,
  },
  {
    path: "/app/fixedReturn/bfi-bank-account",
    name: "BFI Bank Limit",
    component: BfiBankAccount,
    authId: id.BFI_BANK_ACCOUNT,
  },

  {
    path: "/app/fixedReturn/fr-account",
    name: "Accounts",
    exact: true,
    component: FrAccount,
    authId: id.FR_ACCOUNT,
  },

  {
    path: "/app/fixedReturn/fr-interest",
    name: "Interest",
    component: FRInterest,
    authId: id.FR_ACCOUNT,
  },
  {
    path: "/app/fixedReturn/fr-withdrawal",
    name: "Withdrawal",
    component: FRWithdrawal,
    authId: id.FD_APPROVAL,
  },
  // },
  {
    path: "/app/fixedReturn/fr-payment",
    name: "FR Payment",
    exact: true,
    component: FrPayment,
    authId: id.FRPAYMENT,
  },
  {
    path: "/app/fr-account/add-fixed-return",
    name: "Add Fixed Return",
    exact: true,
    component: AddFixedReturn,
    authId: id.FR_ACCOUNT,
    componentId: "CREATE",
    hideInSidebar: true,
  },
  // {
  //   path: "/app/add-bfi-bank-limit",
  //   name: "BFI Bank Limit",
  //   exact: true,
  //   component: BfiBankLimit,
  //   authId: id.BFI_BANK_LIMIT,
  //   // hideInSidebar: true,
  // },
];

export default fixedReturnRoutes;
