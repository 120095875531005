import { lazy } from "react";
import * as id from "app/authorization/authId";

const OrderHistory = lazy(() =>
  import("app/pages/OrderManagement/OrderHistory")
);
const BuyOrder = lazy(() => import("app/pages/OrderManagement/BuyOrder"));
const SellOrder = lazy(() => import("app/pages/OrderManagement/SellOrder"));

const OrderedList = lazy(() => import("app/pages/OrderManagement/OrderedList"));
const UploadBuySellData = lazy(() =>
  import("app/pages/OrderManagement/UploadBuySellData")
);

const orderManagementRoutes = [
  {
    path: "/app/order-management/upload-buy-sell-data",
    name: "Upload Buy/Sell Data",
    component: UploadBuySellData,
    authId: id.UPLOAD,
    hideInSidebar: true,
  },
  {
    path: "/app/order-management/buy-order",
    name: "Buy Order",
    component: BuyOrder,
    authId: id.ORDER,
    componentId: "READ",
  },
  {
    path: "/app/order-management/sell-order",
    name: "Sell Order",
    component: SellOrder,
    authId: id.ORDER,
  },
  {
    path: "/app/order-management/ordered-list",
    name: "Ordered List",
    component: OrderedList,
    authId: id.ORDER_LIST,
  },
  {
    path: "/app/order-History",
    name: "Order History",
    component: OrderHistory,
    authId: id.ORDER_HISTORY,
    icon: "mdi mdi-contacts",
  },
];

export default orderManagementRoutes;
