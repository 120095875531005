import { useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import useFetchApi from "./useFetchApi";

/**
 * hello.com?mobile=rejected&email=hello@gmail.com,
 * const {mobile,email} = useQuery()
 * @returns {object} -
 */
const useQuerySearch = () => {
  const location = useLocation();

  // if (!arrayOfSearchKeys) {
  const allSearchEntries = new URLSearchParams(location.search);
  let paramObj = {};
  for (const [key, value] of allSearchEntries) {
    paramObj = { ...paramObj, [key]: value };
  }
  return paramObj;
  // console.log(paramObj);
  // // }
  // const querySearchValue = arrayOfSearchKeys?.reduce((obj, curr) => {
  //   return { ...obj, [curr]: new URLSearchParams(location.search).get(curr) };
  // }, {});
  // return { ...querySearchValue };
};

// const useLocalStorageState=(key,value,defaultValue="")=>{
//   const [state,setState] = useState(()=>window.localStorage.getItem() || defaultValue)

//   useEffect(()=>{
//     window.localStorage.setItem(key,value);
//   },[key])

//   return
// }

const useClickAwayListener = (ref) => {
  const [isClickAway, setIsClickAway] = useState();
  useEffect(() => {
    function handleClickAway(e) {
      if (ref.current && !ref.current.contains(e.target)) {
        setIsClickAway(true);
      } else {
        setIsClickAway(false);
      }
    }
    document.addEventListener("mousedown", handleClickAway);
    return () => {
      document.removeEventListener("mousedown", handleClickAway);
    };
  }, [ref]);
  return isClickAway;
};

const useGoToTopOnRouteChange = () => {
  const routePath = useLocation();
  const ref = useRef();

  const onTop = () => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  return { ref };
};

// function useAsync(asyncFn, onSuccess) {
//   useEffect(() => {
//     let isMounted = true;
//     asyncFn().then(data => {
//       if (isMounted) onSuccess(data);
//     });
//     return () => { isMounted = false };
//   }, [asyncFn, onSuccess]);
// }

// function useAsync((res)=>{
//   asyncfunction().then()
// },[deps])

const useScrollToError = (className = "scrollHereOnError") => {
  const ref = useRef();
  useEffect(() => {
    if (ref.current?.children) {
      const firstRef = Array.from(ref.current?.children).find((e) =>
        e.className.includes(className)
      );
      if (firstRef) {
        firstRef.scrollIntoView();
      }
    }
  });

  return { ref };
};

const useScroll = () => {
  const ref = useRef();
  const scrollToBottom = () => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  const scrollToTop = () => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  return { ref, scrollToBottom, scrollToTop };
};

export {
  useQuerySearch,
  useClickAwayListener,
  useScrollToError,
  useGoToTopOnRouteChange,
  useScroll,
  useFetchApi,
};
