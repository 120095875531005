import {
  addMessage,
  axiosRequest,
  errorHandler,
  // removeEmptyFields,
} from "app/utils";

const ENDPOINT = "/AnnoucementShare";



export const getAnnouncementShare = (id, portfolioAccountId, isTransaction) => {
  return (dispatch) => {
    return axiosRequest
      .get(`${ENDPOINT}`, {
        params: {
          announcementId: id,
          isApproved: true,
          portfolioAccountId,
          isTransaction,
        },
      })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(addMessage(errorHandler(error)));
        return Promise.reject(error);
      });
  };
};
export const getPendingShare = (id, portfolioAccountId) => {
  // debugger;
  return (dispatch) => {
    return axiosRequest
      .get(`${ENDPOINT}`, {
        params: { announcementId: id, isApproved: false, portfolioAccountId },
      })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(addMessage(errorHandler(error)));
        return Promise.reject(error);
      });
  };
};

export const fetchSearch = async (params) => {
  //   debugger;
  return await axiosRequest
    .get(ENDPOINT, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      //   dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const createAnnouncementShare = async (dispatch, params) => {
  // debugger;
  return await axiosRequest
    .post(ENDPOINT, params)
    .then((res) => {
      dispatch(
        addMessage({
          type: `success`,
          data: `Share Created Successfully`,
          title: ``,
        })
      );
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const manualAddStock = (data) => (dispatch) => {
  return axiosRequest
    .post(`${ENDPOINT}/ManualAddStock`, data)
    .then((res) => {
      dispatch(
        addMessage({
          type: `success`,
          data: `Announcement Adjusted Successfully`,
          tiile: ``,
        })
      );
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const editAnnouncementShare = (dispatch, data) => {
  return axiosRequest
    .put(`${ENDPOINT}/Approve`, data)
    .then((res) => {
      dispatch(
        addMessage({
          type: `success`,
          data: "Successfully Approved",
          title: ``,
        })
      );
      // dispatch(getBankList());
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch(addMessage(errorHandler(err)));
      return Promise.reject("ERROR");
    });
};

export function deleteAnnouncementShare(id, remarks) {
  // debugger;
  return (dispatch) =>
    axiosRequest
      .delete(`${ENDPOINT}/${id}`, {
        params: { id, deleteRemarks: remarks },
      })
      .then((res) => {
        dispatch(
          addMessage({
            type: `success`,
            data: " Deleted successfully",
            title: ``,
          })
        );
        return Promise.resolve(res);
      })
      .catch((error) => {
        dispatch(addMessage(errorHandler(error, "bill")));
        return Promise.reject(error);
      });
}
