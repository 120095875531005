import React from "react";
import { Collapse } from "react-bootstrap";
import { Link } from "react-router-dom";

const CollapsedMenu = ({ subRoutes, open, isPathActive }) => {
  return (
    <Collapse in={open}>
      <ul className="d-flex collapsed-menu text-white flex-column sub-menu">
        {subRoutes.map((e) => {
          if (e.hideInSidebar) return null;
          return (
            <li className="nav-item" key={e.path}>
              <Link
                className={isPathActive(e.path) ? " active" : " text-white"}
                to={e.path}
              >
                <span className="menu-title">{e.name}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </Collapse>
  );
};

export default CollapsedMenu;
