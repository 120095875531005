import { getFdAccountList } from "api/fdAccountList";
import { fetchFrClientDetails } from "api/fixedDepositAccount";
import axiosQuery from "app/utils/axiosQuery";
import axios from "axios";
import { Config } from "Config";
import { useFetchApi } from "hooks";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const GetFrClientOptions = () => {
  const dispatch = useDispatch();
  const [frClientOptions, setFrClientOptions] = useState([]);

  useEffect(() => {
    getFdAccountList(dispatch)
      .then((res) => {
        if (res) {
          const uniqueClients = res.reduce((acc, curr) => {
            acc[curr.clientId] = curr;
            return acc;
          }, {});
          const uniqueClientArray = Object.values(uniqueClients).map((x) => {
            return {
              value: x.clientId,
              label: `${x.clientName}(${x.accountNumber})`,
            };
          });
          setFrClientOptions(uniqueClientArray);
        }
      })
      .catch((err) => {});
  }, [dispatch]);
  return frClientOptions;
};

const paymentModeOptions = [
  { label: "Cheque", value: 1 },
  { label: "Cash", value: 2 },
  { label: "Online Payment", value: 3 },
];
const paymentModeOptionsArray = ["-", "Cheque", "Cash", "Online Payment"];

const isFiveYearsOptions = [
  { label: "Last 5 years", value: true },
  { label: "All time", value: false },
];
const quarterYearOptions = [
  { label: "1st", value: 1 },
  { label: "2nd", value: 2 },
  { label: "3rd", value: 3 },
  { label: "4th", value: 4 },
];

const buySellOptions = [
  { label: "Sell", value: 1 },
  { label: "Buy", value: 2 },
];
const buySellOptionsWithAll = [{ label: "All", value: "" }, ...buySellOptions];
const buySellOptionsArray = ["-", "Sell", "Buy"];

const chargeTypeOptions = [
  { label: "Percentage", value: 1 },
  { label: "Amount", value: 2 },
];
const chargeTypeOptionsArray = ["-", "Percentage", "Amount"];

const groupByFilterOptions = [
  { label: "Product", value: 1 },
  { label: "Broker", value: 2 },
  { label: "RelationManager", value: 3 },
];
const groupByFilterOptionsArray = ["Product", "Broker", "RelationManager"];

const clientTypeOptions = [
  { label: "Individual", value: 1 },
  { label: "Institution", value: 2 },
  { label: "Joint", value: 3 },
];
const clientTypeLabelArray = ["-", "Individual", "Institution"];

//Announcement select type
const announcementTypeOptions = [
  { label: "Bonus Share", value: 1 },
  { label: "Cash Dividend", value: 2 },
  { label: "IPO", value: 3, ipoApply: true },
  { label: "Right Share", value: 4, ipoApply: true },
  { label: "Auction", value: 5, ipoApply: true },
];

const tdsPercentOPtions = [
  { label: 0, value: 0 },
  { label: 5, value: 5 },
  { label: 15, value: 15 },
];

const announcementTypeLabelArray = [
  "",
  "Bonus Share",
  "Cash Dividend",
  "IPO",
  "Right Share",
  "Auction",
];

const mapAnnouncemntIdToRateLabel = [
  "",
  "bonusPercent",
  "cashDividendPercent",
  "ipoPercent", // ipoPercent is always null
  "rightSharePercent",
];
//Setup Bank-> Class
const bankClass = [
  { label: "A", value: 1 },
  { label: "B", value: 2 },
  { label: "C", value: 3 },
  { label: "D", value: 4 },
  { label: "E", value: 5 },
];

const transactionMethodOptions = [
  { label: "All", value: "" },
  { label: "Stock", value: 1 },
  { label: "Cash", value: 2 },
];
const transactionMethodLabelArray = transactionMethodOptions.map(
  (e) => e.label
);

const transactionNatureOptions = [
  { label: "All", value: "" },
  { label: "Receivable", value: 1, allowedToStockTransaction: true }, //Option allowed if transaction method is stock
  { label: "Payable", value: 2, allowedToStockTransaction: true },
  { label: "Receipt", value: 3 },
  { label: "Payment", value: 4 },
];

const transactionNatureLabelArray = transactionNatureOptions.map(
  (e) => e.label
);
const userTypeOptions = [
  { value: 1, label: "Active" },
  { value: 2, label: "Suspended" },
  { value: 3, label: "Closed" },
];

const receiptTypeOption = [
  { label: "Additional Investment", value: 1 },
  { label: "Other Receipt", value: 2 },
];

const eligibilityOptions = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

const reportTypeOptions = [
  { value: 1, label: "FR Expiration Report" },
  { value: 2, label: "BFI FR Report" },
];

const bankAssessmentReportType = [
  { value: 1, label: "Audited" },
  { value: 2, label: "Unaudited" },
];

const quaterlyAnnuallyOptions = [
  { value: 1, label: "Q1" },
  { value: 2, label: "Q2" },
  { value: 3, label: "Q3" },
  { value: 4, label: "Q4" },
  { value: 5, label: "Annually" },
];

const billPaymentOptions = [
  { label: "Not Paid", value: 0 },
  { label: "Partially Paid", value: 1 },
  { label: "Fully Paid", value: 2 },
  // { label: "Paid (Partial & Paid)", value: 2 },
];

const amcTypeOptions = [
  { label: "Fiscal Year", value: 1 },
  { label: "Commencement Date", value: 2 },
];

//Used in KYC for Input Component
const nationalityOptions = [
  { label: "Nepali", value: 1 },
  { label: "NRN", value: 2 },
  { label: "PRN", value: 3 },
];
const educationalqualificationOptions = [
  { label: "Illiterate", value: "Illiterate" },
  { label: "Literate", value: "Literate" },
  { label: "SEE/SLC", value: "SEE/SLC" },
  { label: "Intermediate", value: "Intermediate" },
  { label: "Graduate", value: "Graduate" },
  { label: "Post Graduate", value: "Post Graduate" },
  { label: "PHD/MPhil", value: "PHD/Mphil" },
];
const pmsProductTypeOptions = [
  { label: "Discretionary ", value: 1 },
  { label: "Non-Discretionary ", value: 2 },
];
const investmentTypeOptions = [
  { label: "Stock ", value: 1 },
  { label: "Cash ", value: 2 },
];

const quarterYear = [
  { label: "Q1", value: 1 },
  { label: "Q2", value: 2 },
  { label: "Q3", value: 3 },
  { label: "Q4", value: 4 },
];

const IncomeTypeFilter = [
  { label: "Total Income", value: 1 },
  { label: "Sebon Commission", value: 2 },
  { label: "Branch Income", value: 3 },
  { label: "Nabil Income", value: 4 },
];

const isMatured = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

const interestPeriod = [
  { label: "Daily", value: 1 },
  { label: "Monthly", value: 2 },
  { label: "Quaterly", value: 3 },
  { label: "Half Yearly", value: 4 },
  { label: "Yearly", value: 5 },
];
const interestPeriodLabelArray = [
  "",
  "Daily",
  "Monthly",
  "Quarterly",
  "Half Yearly",
  "Yearly",
];

const institutionTypeOptions = [
  { label: "Govt.", value: 1 },
  { label: "Public Sector Undertaking", value: 2 },
  { label: "Public", value: 3 },
  { label: "Private", value: 4 },
  { label: "Others", value: 5 },
];

const documentTypeOptions = [
  { label: "N/A", value: 0 },
  { label: "Registration Certificate", value: 1 },
  { label: "PAN", value: 2 },
];

const portfolioTransactionTypeLabel = ["", "Cash", "Stock", "Charge"];

const priceTypeOptions = [
  { label: "Max Price", value: 1 },
  { label: "Min Price", value: 4 },
  { label: "Best Price", value: 2 },
  { label: "Exact Price", value: 3 },
];
const priceTypeLabelArray = [
  "",
  "Max Price",
  "Best Price",
  "Exact Price",
  "Min Price",
];

const sellPriceTypeOptions = [
  { label: "Min Price", value: 4 },
  { label: "Best Price", value: 2 },
  { label: "Exact Price", value: 3 },
];
const buyPriceTypeOptions = [
  { label: "Max Price", value: 1 },
  { label: "Best Price", value: 2 },
  { label: "Exact Price", value: 3 },
];

const orderTypeOptions = [
  { label: "Percentage", value: 1 },
  { label: "Absolute", value: 2 },
];
const orderStatusOptions = [
  { label: "Review Pending", value: 1 },
  { label: "Rejected", value: 2 },
  { label: "Approved", value: 3 },
  { label: "Ordered", value: 4 },
];
const orderStatusLabelArray = [
  "",
  "Review Pending",
  "Rejected",
  "Approved",
  "Ordered",
];

const orderTypeLabelArray = ["", "Percentage", "Absolute"];

export {
  paymentModeOptions,
  buySellOptions,
  buySellOptionsArray,
  buySellOptionsWithAll,
  paymentModeOptionsArray,
  chargeTypeOptions,
  chargeTypeOptionsArray,
  educationalqualificationOptions,
  clientTypeOptions,
  clientTypeLabelArray,
  announcementTypeOptions,
  announcementTypeLabelArray,
  bankClass,
  transactionMethodOptions,
  transactionMethodLabelArray,
  transactionNatureOptions,
  transactionNatureLabelArray,
  userTypeOptions,
  mapAnnouncemntIdToRateLabel,
  receiptTypeOption,
  eligibilityOptions,
  reportTypeOptions,
  bankAssessmentReportType,
  quaterlyAnnuallyOptions,
  billPaymentOptions,
  amcTypeOptions,
  tdsPercentOPtions,
  pmsProductTypeOptions,
  investmentTypeOptions,
  quarterYear,
  IncomeTypeFilter,
  isMatured,
  interestPeriod,
  interestPeriodLabelArray,
  institutionTypeOptions,
  documentTypeOptions,
  nationalityOptions,
  portfolioTransactionTypeLabel,
  sellPriceTypeOptions,
  buyPriceTypeOptions,
  priceTypeLabelArray,
  priceTypeOptions,
  orderTypeOptions,
  orderTypeLabelArray,
  orderStatusOptions,
  orderStatusLabelArray,
  groupByFilterOptions,
  groupByFilterOptionsArray,
  quarterYearOptions,
  isFiveYearsOptions,
  GetFrClientOptions,
};
