const checkModal = () =>
  document.querySelector("div.fade.modal.show") ? true : false;

const init = { flag: false, loadingCount: 0, top: 72, fullPageLoader: false };
export default function loading(state = init, action) {
  switch (action.type) {
    case "ACTIVATE_LOADING":
      return {
        flag: true,
        loadingCount: state.loadingCount + 1,
        top: checkModal() ? 0 : 72,
        fullPageLoader: checkModal() ? true : false,
      };
    case "INCREASE_LOADING_COUNT":
      return {
        ...state,
        loadingCount: state.loadingCount + 1,
      };

    case "DEACTIVATE_LOADING":
      if (state.loadingCount > 1) {
        return {
          ...state,
          loadingCount: state.loadingCount - 1,
        };
      } else {
        return { ...init };
      }
    case "RESET_LOADING":
      return { ...init };
    default:
      return state;
  }
}
