import React from "react";
import { NavLink } from "react-router-dom";

const LandingPage = () => {
  return (
    <React.Fragment>
      <div className="form-group mt-5">
        <div className="row">
          <div className="col-12 col-sm-6 mb-5">
            <h2 className="mb-3">Client KYC</h2>
            <p className="mb-4">
              If you have personal account, click button below to fill personal
              KYC form.
            </p>
            <NavLink to="/kyc/client" className="btn btn-success btn-sm">
              Go to Client KYC
            </NavLink>
          </div>
          <div className="col-12 col-sm-6 mb-5">
            <h2 className="mb-3">Institutional KYC</h2>
            <p className="mb-4">
              If you have company account, click button below to fill
              institutional KYC form.
            </p>
            <NavLink to="/kyc/institution" className="btn btn-info btn-sm">
              Go to Institutional KYC
            </NavLink>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default LandingPage;
