export function setDefaultValueForSelect({
  setValue,
  editData,
  defaultValueArray,
}) {
  defaultValueArray.forEach(({ options, name }) => {
    setValue(
      name,
      options?.find((e) => e.value === editData[name])
    );
  });
}

export function sortOptionByLabel(options) {
  return options?.sort((a, b) => {
    const isString = (s) => typeof s === "string";
    const fa = isString(a.label) ? a.label.toLowerCase() : a.label;
    const fb = isString(b.label) ? b.label.toLowerCase() : b.label;

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
}
