import { addMessage, axiosRequest, errorHandler } from "app/utils";
import axiosQuery from "app/utils/axiosQuery";

const ENDPOINT_FD_ACCOUNT_LIST = "/fixedDepositAccount";

export const getFdAccountList = async (dispatch, params) => {
  return await axiosQuery
    .get(`${ENDPOINT_FD_ACCOUNT_LIST}/getFdAccountList`)
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((err) => {
      dispatch(addMessage(errorHandler(err)));
      return Promise.reject(err);
    });
};
