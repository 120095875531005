import { axiosRequest, errorHandler, addMessage } from "app/utils";
import { FETCH_DP } from "./ipoActionType";

const ENDPOINT_DP = "/Ipo/GetDepositoryParticipants";

export function getDP() {
  return (dispatch) =>
    axiosRequest
      .get(`${ENDPOINT_DP}`)
      .then((res) => {
        dispatch({ type: FETCH_DP, payload: res.data });
        return Promise.resolve(res);
      })
      .catch((error) => {
        dispatch(addMessage(errorHandler(error)));
        return Promise.reject(error);
      });
}
