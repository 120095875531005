
export const Config = {
  Title: `Nabil PMS`,
  BaseUrl: `${process.env.REACT_APP_URL}api/`,
  ImageUrl: `${process.env.REACT_APP_URL}content/images/`,
  PdfUrl: `${process.env.REACT_APP_URL}`,
  accountingUrl: `${process.env.REACT_APP_ACCOUNTING_URL}Authorization.aspx`,
  amcChargeId: process.env.REACT_APP_AMC_CHARGE_ID,
  pfChargeId: process.env.REACT_APP_PF_CHARGE_ID,
  elfChargeId: process.env.REACT_APP_ELF_CHARGE_ID,
  LevelWiseApprovals: process.env.REACT_APP_LevelWiseApprovals, 
  PortfolioAnalyst: process.env.REACT_APP_PORTFOLIO_ANALYST,
  ResourceManager: process.env.REACT_APP_RESOURCE_MANAGER,
};
