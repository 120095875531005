import { lazy } from "react";
import * as id from "app/authorization/authId";

//components
const GenerateBill = lazy(() => import("app/pages/Billing/GenerateBill"));
const CreateBillPayment = lazy(() =>
  import("app/pages/Billing/CreateBillPayment")
);
const CreateBillReceipt = lazy(() =>
  import("app/pages/Billing/CreateBillReceipt")
);

const BillList = lazy(() => import("app/pages/Billing/BillList"));
const Floorsheet = lazy(() => import("app/pages/Floorsheet"));
const FloorsheetDetails = lazy(() =>
  import("app/pages/Floorsheet/FloorsheetDetails/FloorsheetDetails")
);
const BillAdjustment = lazy(() => import("app/pages/Billing/BillAdjustment"));
const Receipt = lazy(() => import("app/pages/Billing/Receipt"));
const Payment = lazy(() => import("app/pages/Billing/Payment"));
const ViewPayment = lazy(() => import("app/pages/Billing/ViewPayment"));
const ViewReceipt = lazy(() => import("app/pages/Billing/ViewReceipt"));

const BillMapping = lazy(() => import("app/pages/Billing/BillMapping"));

// const Wacc = lazy(() => import("app/pages/Billing/Wacc"));
// const Edis = lazy(() => import("app/pages/Billing/EDIS"));

// Bank Reconciliation Routes imports
const BankReconciliation = lazy(() =>
  import("app/pages/Billing/BankReconciliation")
);
const Reconcile = lazy(() =>
  import("app/pages/Billing/BankReconciliation/Reconcile")
);
const AdjustBill = lazy(() =>
  import("app/pages/Billing/BillAdjustment/AdjustBillsModal")
);
const AdjustBillView = lazy(() =>
  import("app/pages/Billing/BillAdjustment/AdjustBillViewModal")
);
const CloseOut = lazy(() => import("app/pages/Billing/Closeout"));

//console.log("id on route>>>", id);

//routes configuration
const billingRoutes = [
  {
    path: "/app/billing/bill-mapping",
    name: "Bill Mapping",
    component: BillMapping,
    authId: id.BILL_MAPPING,
    hideInSidebar: true,
  },
  // {
  //   path: "/app/billing/Wacc",
  //   name: "Wacc",
  //   exact: true,
  //   component: Wacc,
  //   authId: id.WACC,
  //   icon: "mdi mdi-gamepad-down",
  // },
  {
    path: "/app/billing/generate-bill",
    name: "Generate Bill",
    exact: true,
    component: GenerateBill,
    authId: id.GENERATE_BILL,
    hideInSidebar: true,
  },
  {
    path: "/app/billing/bills-list",
    name: "All Bills",
    exact: true,
    component: BillList,
    authId: id.BILLS_LIST,
  },
  {
    path: "/app/billing/bill-adjustment",
    name: "Bill Adjustment",
    exact: true,
    component: BillAdjustment,
    authId: id.BILL_ADJUSTMENT,
  },
  {
    path: "/app/billing/bill-adjustment/adjustView",
    name: "adjustBill",
    component: AdjustBill,
    authId: id.BILL_ADJUSTMENT,
    hideInSidebar: true,
  },
  {
    path: "/app/billing/bill-adjustmentview/adjustView",
    name: "adjustmentBillView",
    component: AdjustBillView,
    authId: id.BILL_ADJUSTMENT,
    hideInSidebar: true,
  },
  {
    path: "/app/billing/create-bill-payment",
    name: "Create Buy Payment", //buy bill
    exact: true,
    component: CreateBillPayment,
    authId: id.CREATE_BILL_PAYMENT_RECEIPT,
  },
  {
    path: "/app/billing/create-bill-receipt",
    name: "Create Sell Receipt", //buy bill
    exact: true,
    component: CreateBillReceipt,
    authId: id.CREATE_BILL_PAYMENT_RECEIPT,
  },

  {
    path: "/app/billing/view-receipt",
    name: "View Receipt",
    exact: true,
    component: ViewReceipt,
    authId: id.VIEW_RECEIPT,
    hideInSidebar: true,
  },
  {
    path: "/app/billing/view-payment",
    name: "View Payment",
    exact: true,
    component: ViewPayment,
    authId: id.VIEW_PAYMENT,
    hideInSidebar: true,
  },

  {
    path: "/app/billing/receipt",
    name: "Receipt",
    exact: true,
    component: Receipt,
    authId: id.RECEIPT,
  },
  {
    path: "/app/billing/payment",
    name: "Payment",
    exact: true,
    component: Payment,
    authId: id.PAYMENT,
  },

  // {
  //   path: "/app/billing/Edis",
  //   name: "EDIS",
  //   exact: true,
  //   component: Edis,
  //   authId: id.EDIS,
  // },
  {
    path: "/app/billing/bank-reconciliation",
    name: "Bank Reconciliation",
    component: BankReconciliation,
    authId: id.BANK_RECONCILIATION,
  },
  {
    path: "/app/billing/bank-reconciliation/reconcile",
    name: "Reconcile",
    component: Reconcile,
    authId: id.PORTFOLIO_SUMMARY_REPORT,
    hideInSidebar: true,
  },
  {
    path: "/app/billing/bank-reconciliation/reconcile",
    name: "Reconcile",
    component: Reconcile,
    authId: id.PORTFOLIO_SUMMARY_REPORT,
    hideInSidebar: true,
  },
  {
    path: "/app/billing/closeout",
    name: "Closeout",
    component: CloseOut,
    authId: id.CLOSE_OUT,
  },
  {
    path: "/app/billing/floorsheet",
    name: "Floorsheet",
    component: Floorsheet,
    authId: id.FLOORSHEET,
  },
  {
    path: "/app/billing/floorsheetDetails/:id",
    name: "Floorsheet Details",
    component: FloorsheetDetails,
    exact: true,
    hideInSidebar: true,
    authId: id.FLOORSHEET,
  },
];

export default billingRoutes;
