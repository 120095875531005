import {
  axiosRequest,
  errorHandler,
  addMessage,
  removeEmptyFields,
} from "app/utils";
const ENDPOINT = "portfolioAccounts";

export const fetchPortfolioAccountDetailsReportApi = `${ENDPOINT}`;

export const fetchPortfolioAccountDetails =
  (params, runInBackground = true) =>
  async (dispatch) => {
    return await axiosRequest
      .get(`${ENDPOINT}`, { params, runInBackground })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.reject("ERROR");
      });
  };

export const fetchPortfolioAccountLists = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/list`, { ...params, runInBackground: true })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch(addMessage(errorHandler(err)));
      return Promise.reject("ERROR");
    });
};

export const fetchPortfolioAccountListsForSelect =
  (params) => async (dispatch) => {
    return await axiosRequest
      .get(`${ENDPOINT}/list`, {
        params: { name: params },
        runInBackground: true,
      })
      .then((res) => {
        return Promise.resolve(res.data);
      });
   
  };

export const fetchPortfolioAccountSelf = (id) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/list`, {
      runInBackground: true,
      params: {
        clientId: id,
      },
    })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      // dispatch(addMessage(errorHandler(err)));
      return Promise.reject("ERROR");
    });
};

export const fetchPortfolioAccountsExpired = (params) => async (dispatch) => {
  
  return await axiosRequest
    .get(`${ENDPOINT}/Expired`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};
export const fetchPortfolioExpiration = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/portfolioExpiryReport`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const fetchStockDetailsApi = `${ENDPOINT}/StockDetails`;

export const fetchStockDetails = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/BuyOrderDetails`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const fetchStockDetailsApiSell = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/StockDetails`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const fetchClientStock = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/clientStockDetails`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

// Newly added (testing going on) : /PortfolioAccounts/ClientReport
export const fetchClientReport = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/ClientReport`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const fetchPortfolioClientDetailsFull = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/ThresholdReport`, { params })
    .then((res) => {
    
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const renewOrExtendPortfolio = (data) => async (dispatch) => {
  return await axiosRequest
    .post(`${ENDPOINT}/renew`, removeEmptyFields(data))
    .then((res) => {
      dispatch(
        addMessage({
          type: `success`,
          data: `Portfolio ${
            data.isRenew ? "Renewed" : "Extended"
          } Successfully`,
          title: ``,
        })
      );
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const fetchRenewOrExtendedList = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/renew`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export function deleteExtendedOrRenew(id, remarks) {
  return (dispatch) =>
    axiosRequest
      .delete(`${ENDPOINT}/renew/${id}`, {
        params: { id, deleteRemarks: remarks },
      })
      .then((res) => {
        dispatch(
          addMessage({
            type: "success",
            data: "Deleted Successfully",
          })
        );
        return Promise.resolve(res);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.resolve(err);
      });
}

export const editRenewOrExtended = (id, data) => async (dispatch) => {
  return await axiosRequest
    .put(`${ENDPOINT}/renew/${id}`, { id, ...data })
    .then((res) => {
      if (res.data) {
        dispatch(
          addMessage({
            type: `success`,
            data: "Edited Successfully",
            title: ``,
          })
        );
        return Promise.resolve(res.data);
      } else {
        return Promise.reject("ERROR");
      }
    })
    .catch((err) => {
      dispatch(addMessage(errorHandler(err)));
      return Promise.reject(err);
    });
};

export const fetchProductAndPortfolioDetails = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/productPorfolioDetails`, { params })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      dispatch(addMessage(errorHandler(err)));
      return Promise.reject(err);
    });
};

export const fetchPortfolioTradingDetails = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/portfolioTradingDetails`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};
export const fetchPortfolioInvestmentDetails = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/PortfolioInvestmentDetails`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};
export const fetchPortfolioAccountDetailsApi = `${ENDPOINT}/PortfolioClientDetails`;

// export const fetchPortfolioAccountDetailsApi2 = (params) => async (
//   dispatch
// ) => {
//   return await axiosRequest
//     .get(fetchPortfolioAccountDetailsApi, { params })
//     .then((res) => {
//       if (res.data) {
//         return Promise.resolve(res.data);
//       }
//     })
//     .catch((error) => {
//       dispatch(addMessage(errorHandler(error)));
//       return Promise.reject(error);
//     });
// };

export const fetchPeriodWiseClientProductAmount =
  (params) => async (dispatch) => {
    return await axiosRequest
      .get(`${ENDPOINT}/periodWiseClientProductAmount`, { params })
      .then((res) => {
        if (res.data) {
          return Promise.resolve(res.data);
        }
      })
      .catch((error) => {
        dispatch(addMessage(errorHandler(error)));
        return Promise.reject(error);
      });
  };

export const fetchPeriodWiseClientProductAmountDetail =
  (params) => async (dispatch) => {
    return await axiosRequest
      .get(`${ENDPOINT}/periodWiseClientProductAmountDetail`, { params })
      .then((res) => {
        if (res.data) {
          return Promise.resolve(res.data);
        }
      })
      .catch((error) => {
        dispatch(addMessage(errorHandler(error)));
        return Promise.reject(error);
      });
  };
