import { lowercaseFirstLetter } from "./GeneralHelpers";

export const errorHandler = (error, key) => {
  if (error.toString() === "Error: Network Error" || !error.response) {
    return {
      type: "error",
      data: "Server Error or Network Error",
    };
  } else {
    if (error.response.status) {
      switch (error.response.status) {
        case 400:
          let errorPayload = {};
          Object.keys(error.response.data).forEach((i) => {
            errorPayload[lowercaseFirstLetter(i)] = error.response.data[i];
          });
          //console.log("errorPayload", errorPayload);
          if (key) {
            return {
              type: "error",
              data:
                errorPayload[lowercaseFirstLetter(key)]?.toString() ??
                "Error Processing",
            };
          }

          //console.log("entered in key");
          const errorMessage = Object.values(errorPayload);
          return {
            type: "error",
            data: errorMessage.toString() ?? "Error in validation",
          };

        default:
          return {
            type: "error",
            data:
              error.response.data && error.response.data.title
                ? error.response.data.title.toString()
                : error.response.data
                ? error.response.data.toString()
                : "Error in Response",
          };
      }
    } else {
      return {
        type: "error",
        data: error.response.toString(),
      };
    }
  }
};
