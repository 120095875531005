import {
  FETCH_APPROVED_ORDER_FROM_BROKER,
  FETCH_UNAPPROVED_ORDER_FROM_BROKER,
  ADD_APPROVED_ORDER_FROM_BROKER,
} from "./orderActionTypes";

const init = {
  approvedOrderFromBroker: {},
  approvalPendingOrderFromBroker: {},
  portfolioMappingDto: {},
  approvedDataPagination: {},
  approvalPendingDataPagination: {},
};
const clientReducer = (state = init, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_UNAPPROVED_ORDER_FROM_BROKER:
      return {
        ...state,
        approvalPendingOrderFromBroker: payload.orderMappingObj,
        portfolioMappingDto: payload.portfolioMappingObj,
        approvalPendingDataPagination: {
          currentPage: payload.currentPage,
          totalPages: payload.totalPages,
        },
      };
    case FETCH_APPROVED_ORDER_FROM_BROKER:
      return {
        ...state,
        approvedOrderFromBroker: payload.data,
        approvedDataPagination: payload.pagination,
      };
    case ADD_APPROVED_ORDER_FROM_BROKER:
      if (!Array.isArray(payload)) {
        console.error("Expected payload is an array");
        return;
      }
      const tempApprovalPendingOrder = {
        ...state.approvalPendingOrderFromBroker,
      };

      payload.forEach((e) => {
        delete tempApprovalPendingOrder[e.floorsheetImportDetailId];
      });

      return {
        ...state,
        approvalPendingOrderFromBroker: { ...tempApprovalPendingOrder },
      };
    default:
      return state;
  }
};

export default clientReducer;
