import XLSX from "xlsx";

function excelToJSON(file, cb) {
  const reader = new FileReader();

  reader.onload = function (e) {
    const data = e.target.result;
    const workbook = XLSX.read(data, {
      type: "binary",
    });

    const firstSheet = workbook.SheetNames[0];
    const json = XLSX.utils.sheet_to_json(workbook.Sheets[firstSheet]);
    cb(json);
  };

  reader.onerror = function (ex) {
    //console.log(ex);
  };

  reader.readAsBinaryString(file);
}

export default excelToJSON;
