import { lazy } from "react";
import * as id from "app/authorization/authId";
import { componentId } from "app/authorization";

const FixedReturn = lazy(() => import("app/pages/FixedReturn/FRReport"));

const StockStatement = lazy(() => import("app/pages/Reports/StockStatement"));
const PortfolioExpirationList = lazy(() =>
  import("app/pages/Reports/PortfolioExpirationList")
);
const TotalClients = lazy(() => import("app/pages/Reports/TotalClients"));
const AumReport = lazy(() => import("app/pages/Reports/AumReport"));
const ClientReport = lazy(() => import("app/pages/Reports/ClientReportManage"));
const FRSebonReport = lazy(() => import("app/pages/FixedReturn/FRSebonReport"));
const FRManagementReport = lazy(() =>
  import("app/pages/FixedReturn/FRManagementReport")
);
const InvestmentReturnReport = lazy(() =>
  import("app/pages/Reports/InvestmentReturnReport")
);
const RMWiseClientAUMReport = lazy(() =>
  import("app/pages/Reports/RMWiseClientAUMReport")
);

//Client Report Sub Routes
const ChargeReports = lazy(() =>
  import("app/pages/Reports/ClientReport/ChargeReport")
);
const PortfolioSummary = lazy(() =>
  import("app/pages/Reports/ClientReport/PortfolioSummaryReport")
);
const BankStatementReport = lazy(() =>
  import("app/pages/Reports/ClientReport/BankStatementReport")
);
const PortfolioExpiredRenewedReport = lazy(() =>
  import("app/pages/Reports/ClientReport/PortfolioExpiredRenewedReport")
);
//Management Report Sub Routes
const FrClientDetailReport = lazy(() =>
  import("app/pages/Reports/ManagementReports/FrClientDetailReport")
);
const ClientInformationReport = lazy(() =>
  import("app/pages/Reports/ManagementReports/ClientInformationReport")
);
const BankReconciliationReport = lazy(() =>
  import("app/pages/Reports/ManagementReports/BankReconciliationReport")
);
const FeeDeductionReport = lazy(() =>
  import("app/pages/Reports/ManagementReports/FeeDeductionReport")
);
const PurchaseSalesReport = lazy(() =>
  import("app/pages/Reports/ManagementReports/PurchaseSalesReport")
);
const PortfolioInvestmentReport = lazy(() =>
  import("app/pages/Reports/ManagementReports/PortfolioInvestmentReport")
);
const RMNProductWiseIncomeReport = lazy(() =>
  import("app/pages/Reports/ManagementReports/RMNProductWiseIncomeReport")
);
const StockDeviationReport = lazy(() =>
  import("app/pages/Reports/ManagementReports/StockDeviationReport")
);

const TTRReport = lazy(() =>
  import("app/pages/Reports/ManagementReports/TTRReport")
);
//Sebon Report Sub Routes
const ClientAgreement = lazy(() =>
  import("app/pages/Reports/SebonReport/ClientAgreement")
);
const ClientDetailsReport = lazy(() =>
  import("app/pages/Reports/SebonReport/ClientDetailsReport")
);
const PortfolioAndProductDetails = lazy(() =>
  import("app/pages/Reports/SebonReport/PortfolioAndProductDetails")
);
const PortfolioTradingReport = lazy(() =>
  import("app/pages/Reports/SebonReport/PortfolioTradingReport")
);

const REPORT = componentId.REPORT;
const READ = componentId.READ;

const clientReportRoutes = [
  {
    path: "/app/reports/portfolio-summary",
    name: "Portfolio Summary Report",
    component: PortfolioSummary,
    authId: id.PORTFOLIO_SUMMARY_REPORT,
    componentId: REPORT,
  },
  {
    path: "/app/reports/bank-statement",
    name: "Bank Statement",
    component: BankStatementReport,
    authId: id.BANK_STATEMENT,
    componentId: REPORT,
  },
  {
    path: "/app/reports/charge-reports",
    shortPath: "/reports/charge-reports",
    name: "Charge Report",
    component: ChargeReports,
    authId: id.CHARGE_REPORTS,
  },
  {
    path: "/app/reports/portfolio-expired-renewed-report",
    name: "Portfolio Extended/Renewed Report",
    component: PortfolioExpiredRenewedReport,
    authId: id.RENEW_EXTEND,
    componentId: REPORT,
  },
];
const managementReportRoutes = [
  {
    path: "/app/reports/client-information-report",
    name: "Client Information Report",
    component: ClientInformationReport,
    authId: id.CLIENT_INFORMATION_REPORT,
    componentId: "REPORT",
  },
  {
    path: "/app/reports/client-detail-report",
    name: " FR Client Detail Report",
    component: FrClientDetailReport,
    authId: id.FR_CLIENT_DETAIL_REPORT,
    componentId: "REPORT",
  },
  {
    path: "/app/reports/bank-reconciliation-report",
    name: "Bank Reconciliation Report",
    component: BankReconciliationReport,
    authId: id.BANK_RECONCILIATION,
    componentId: "REPORT",
  },
  {
    path: "/app/reports/fee-deduction-report",
    name: "Fee Deduction Report",
    component: FeeDeductionReport,
    authId: id.FEE_DEDUCTION_REPORT,
    componentId: REPORT,
  },

  {
    path: "/app/reports/portfolio-expiration-list",
    name: "Portfolio Expiration Report",
    component: PortfolioExpirationList,
    authId: id.PORTFOLIO_EXPIRATION_LIST,
    componentId: REPORT,
  },
  {
    path: "/app/reports/portfolio-investment-report",
    name: "Portfolio Investment Report",
    component: PortfolioInvestmentReport,
    authId: id.PORTFOLIO_INVESTMENT_REPORT,
    componentId: REPORT,
  },
  {
    path: "/app/reports/purchase-sell-report",
    name: "Purchase/Sales Report",
    component: PurchaseSalesReport,
    authId: id.PURCHASE_SALES_REPORT,
    componentId: REPORT,
  },
  {
    path: "/app/reports/income-report",
    name: "RM/Product Wise Income Report",
    component: RMNProductWiseIncomeReport,
    authId: id.INCOME_REPORT,
    componentId: REPORT,
  },
  {
    path: "/app/reports/RM-wise-Client-AUM-report",
    name: "RM Wise Client AUM Report",
    component: RMWiseClientAUMReport,
    authId: id.AUM_REPORT,
    hideInSidebar: true,
  },
  {
    path: "/app/reports/stock-deviation",
    name: "Stock Deviation Report",
    component: StockDeviationReport,
    authId: id.STOCK_DEVIATION_REPORT,
    componentId: REPORT,
  },

  {
    path: "/app/reports/stock-statement",
    name: "Stock Holding Statement",
    component: StockStatement,
    authId: id.STOCK_STATEMENT,
    componentId: REPORT,
  },

  {
    path: "/app/reports/aum-report",
    name: "AUM Report",
    component: AumReport,
    authId: id.AUM_REPORT,
  },

  {
    path: "/app/reports/client-report",
    name: "Client Report",
    component: ClientReport,
    authId: id.CLIENT_REPORT,
  },

  {
    path: "/app/reports/ttr-report",
    name: "TTR Report",
    component: TTRReport,
    authId: id.TTR_REPORT,
    componentId: REPORT,
  },
  {
    path: "/app/reports/investment-return-report",
    name: "Investment Return Report",
    component: InvestmentReturnReport,
    authId: id.FR_REPORTS,
    componentId: "REPORT",
  },
];
const frReportsListRoutes = [
  {
    path: "/app/reports/fixed-return?tab=1",
    name: "BFI Report",
    component: FixedReturn,
    authId: id.FR_REPORTS,
    componentId: "REPORT",
  },
  {
    path: "/app/reports/fixed-return?tab=2",
    name: "Expiration Report",
    component: FixedReturn,
    authId: id.FR_REPORTS,
    componentId: "REPORT",
  },
  {
    path: "/app/reports/fixed-return?tab=3",
    name: "Interest Report",
    component: FixedReturn,
    authId: id.FR_REPORTS,
    componentId: "REPORT",
  },
  {
    path: "/app/reports/fixed-return?tab=4",
    name: "Individual Interest Report",
    component: FixedReturn,
    authId: id.FR_REPORTS,
    componentId: "REPORT",
  },
  {
    path: "/app/reports/fixed-return?tab=5",
    name: "Income Report",
    component: FixedReturn,
    authId: id.FR_REPORTS,
    componentId: "REPORT",
  },
  {
    path: "/app/reports/fixed-return?tab=6",
    name: "Payable Report",
    component: FixedReturn,
    authId: id.FR_REPORTS,
    componentId: "REPORT",
  },
];
const frReportRoutes = [
  {
    path: "/app/reports/fixed-return",
    name: "General Reports",
    component: FixedReturn,
    authId: id.FR_REPORTS,
    componentId: "REPORT",
    dropDownMenu: frReportsListRoutes,
  },
  {
    path: "/app/reports/fr-sebon-report",
    name: "FR Sebon Report",
    component: FRSebonReport,
    authId: id.FR_REPORTS,
    componentId: "REPORT",
  },
  {
    path: "/app/reports/fr-management-report",
    name: "FR Management Report",
    component: FRManagementReport,
    authId: id.FR_REPORTS,
    componentId: "REPORT",
  },
];

const sebonReportRoutes = [
  {
    path: "/app/reports/client-agreement",
    name: "Client Agreement (अनुसुची १४)",
    component: ClientAgreement,
    authId: id.CLIENT_AGREEMENT,
  },
  {
    path: "/app/reports/client-details-report",
    name: "Client Details Report",
    component: ClientDetailsReport,
    authId: id.CLIENT_DETAILS_REPORT,
  },
  {
    path: "/app/reports/portfolio-trading-report",
    name: "Portfolio Trading Report (अनुसुची १५)",
    component: PortfolioTradingReport,
    authId: id.PORTFOLIO_TRADING_REPORT,
  },
  {
    path: "/app/reports/product-portfolio",
    name: "Product & Portfolio Details (अनुसुची १६)",
    component: PortfolioAndProductDetails,
    authId: id.PORTFOLIO_PRODUCT_REPORT,
  },
];

const reportsRoutes = [
  {
    path: "/app/reports/fr-report",
    name: "FR Reports",
    dropDownMenu: frReportRoutes,
    authId: id.FR_REPORTS,
  },
  {
    path: "/app/reports/client-report",
    name: "Client Report",
    authId: id.PORTFOLIO_SUMMARY_REPORT,
    dropDownMenu: clientReportRoutes,
  },
  {
    path: "/app/reports/management-report",
    name: "Management Report",
    authId: id.PORTFOLIO_SUMMARY_REPORT,
    dropDownMenu: managementReportRoutes,
  },
  {
    path: "/app/reports/sebon-report",
    name: "Sebon Report",
    authId: id.PORTFOLIO_SUMMARY_REPORT,
    dropDownMenu: sebonReportRoutes,
  },
];
export {
  clientReportRoutes,
  managementReportRoutes,
  sebonReportRoutes,
  frReportRoutes,
};
export default reportsRoutes;
