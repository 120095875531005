const init = {
  openAlert: false,
  message: "Something went wrong!",
  severity: "error",
};
const errorModalReducer = (state = init, action) => {
  const { type, payload } = action;

  switch (type) {
    case "OPEN_ALERT_MODAL":
      return {
        openAlert: true,
        message: payload.message,
        severity: payload.severity,
      };
    case "CLOSE_ALERT_MODAL":
      return { ...state, openAlert: false };
    default:
      return state;
  }
};

export default errorModalReducer;
