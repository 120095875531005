import React from "react";
import DashboardHeader from "./DashboardHeader";
import OutsideHeader from "./OutsideHeader";

const Header = ({ admin, ...props }) => {
  if (admin) {
    return <DashboardHeader {...props} />;
  } else {
    return <OutsideHeader {...props} />;
  }
};

export default Header;
