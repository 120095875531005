import { PROVINCE_FETCHED } from "./dataActionTypes";
import {
  fetchProvinces,
  fetchBankData,
  fetchBankBranchData,
  fetchRelationshipData,
  fetchOccupationType,
  fetchBusinessType,
  fetchAnnualIncome,
  fetchDesignation,
  fetchOccupationDesignation,
  fetchDistrictData,
} from "./dataActions";
import dataReducer from "./dataReducer";

export {
  PROVINCE_FETCHED,
  fetchProvinces,
  fetchBankData,
  fetchBankBranchData,
  fetchRelationshipData,
  fetchOccupationType,
  fetchBusinessType,
  fetchAnnualIncome,
  fetchDesignation,
  fetchOccupationDesignation,
  fetchDistrictData,
};

export default dataReducer;
