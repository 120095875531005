import axios from "axios";
import { Config } from "Config";
import { removeEmptyFields } from "./index";
import store from "Store";
import { toast } from "react-toastify";
import { performUserLogoutAction } from "app/store/auth/authAction";
import { addMessage } from "app/utils";
// import Cookies from "js-cookie";
const axiosQuery = axios.create({
  baseURL: Config.BaseUrl,
  timeout: 300000,
});

let isAlreadyFetchingAccessToken = false;

async function fetchNewToken(token, refreshToken) {
  await axios
    .post(`${Config.BaseUrl}Users/Refresh`, {
      refreshToken,
      accessToken: token,
    })
    .then((res) => {
      store.dispatch({ type: "STORE_USER_SESSION", payload: res.data });
    })
    .catch((err) => {
      store.dispatch(
        addMessage({
          type: "error",
          data: "Session has expired. Please Login Again.",
        })
      );
      // toast.error("Session has expired. Please Login Again.");
      if (err.response.status === 401) {
        performUserLogoutAction(store.dispatch);
      }
    });
}
axiosQuery.interceptors.request.use(
  async (req) => {
    const token = localStorage.getItem("pmsToken");
    const expiresOn = localStorage.getItem("expires_on");
    const refreshToken = localStorage.getItem("_refresh");

    const now = new Date();
    if (expiresOn && expiresOn < now.getTime()) {
      // if (expiresOn && expiresOn < now.getTime()) {
      if (!isAlreadyFetchingAccessToken) {
        isAlreadyFetchingAccessToken = true;

        await fetchNewToken(token, refreshToken).then((res) => {
          if (res) {
            isAlreadyFetchingAccessToken = false;
          }
        });
      }
    } else {
      // const token = Cookies.get("pmsToken")
      let urls = req.url.split("/");
      const endpoint = urls[urls.length - 1];
      if (token != null && endpoint !== "login") {
        req.headers.Authorization = `Bearer ${token}`;
      }

      //For get
      if (req.params) {
        req.params = removeEmptyFields(req.params);
      }
      return req;
    }
  },

  function (err) {
    return Promise.reject(err);
  }
);

axiosQuery.interceptors.response.use(
  function (response) {
    if (response.status === 401) {
      performUserLogoutAction(store.dispatch);
    }
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      performUserLogoutAction(store.dispatch);
    }

    if (error?.response?.status === 403) {
      store.dispatch(addMessage({ type: "error", data: "Unauthorized" }));
    }

    return Promise.reject(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
  }
);

export default axiosQuery;
