import { lazy } from "react";
import * as id from "app/authorization/authId";
import AnnouncementAdjustment from "app/pages/CorporateActions/AnnouncementAdjustment";
import { READ } from "app/authorization/componentId";

//components
const Announcement = lazy(() =>
  import("app/pages/CorporateActions/Announcement")
);
const Allotment = lazy(() => import("app/pages/CorporateActions/Allotment"));

const corporateActionsRoutes = [
  {
    path: "/app/corporate-actions/announcement",
    shortPath: "/corporate-actions/announcement",
    name: "Announcement",
    component: Announcement,
    authId: id.ANNOUNCEMENT,
  },
  {
    path: "/app/corporate-actions/allotment",
    name: "Allotment",
    component: Allotment,
    authId: id.ALLOTMENT,
  },
  {
    path: "/app/corporate-actions/announcement-adjustment",
    name: "Announcement Adjustment",
    component: AnnouncementAdjustment,
    authId: id.ANNOUNCEMENT_ADJUSTMENT,
  },
];

export default corporateActionsRoutes;
