import React, { useRef, useEffect } from "react";
import { Form, FormControl, InputGroup } from "react-bootstrap";
import { addCommas } from "app/utils";

const CustomInput = React.forwardRef(
  (
    {
      register,
      error,
      helperText,
      required,
      placeholder,
      defaultValue,
      label,
      type,
      validate,
      spanAstric,
      registerProps,
      autoFocus,
      watch,
      setValue,
      name,
      boldLabel = false,
      onChange,
      removeDuplicate,
      ...rest
    },
    ref
  ) => {
    const internalRef = useRef();

    const [changed, setChanged] = React.useState(false);
    const [inputType, setInputType] = React.useState({
      active: true,
      type: "password",
      icon: "mdi-eye",
    });
    useEffect(() => {
      if (autoFocus) {
        internalRef.current.focus();
      }
    }, [autoFocus]);

    const changeInputType = () => {
      inputType.active
        ? setInputType({
            active: !inputType.active,
            type: "text",
            icon: "mdi-eye-off",
          })
        : setInputType({
            active: !inputType.active,
            type: "password",
            icon: "mdi-eye",
          });
    };

    if (type === "amount" && !watch && !setValue) {
      throw new Error(
        "You need to pass setValue and watch props as well if type is amount"
      );
    }

    return (
      <>
        <Form.Group>
          {label && !boldLabel && (
            <>
              <Form.Label>
                {label}
                {spanAstric || required ? (
                  <span className="text-danger ml-1">*</span>
                ) : null}
              </Form.Label>
            </>
          )}
          {label && boldLabel && (
            <>
              <Form.Label>
                <b> {label} </b>
                {spanAstric || required ? (
                  <span className="text-danger ml-1">*</span>
                ) : null}
              </Form.Label>
            </>
          )}

          {type === "password" ? (
            <InputGroup>
              <FormControl
                aria-label="Password"
                placeholder={label || placeholder}
                size="sm"
                style={{ borderRadius: "0.25rem" }}
                onChange={(e) => {
                  onChange && onChange(e);
                }}
                type={inputType.type}
                ref={(e) => {
                  register(e, {
                    required: required || false,
                    validate: validate || true,
                    ...registerProps,
                  });
                  internalRef.current = e;
                  if (ref) {
                    ref.current = e;
                  }
                }}
                isInvalid={error || false}
                {...(defaultValue && { defaultValue: defaultValue })}
                {...(defaultValue === 0 && { defaultValue: defaultValue })} // To allow for legacy implementation
                name={name}
                {...rest}
              />
              <InputGroup.Append
                style={{
                  position: "absolute",
                  right: 5,
                  top: "50%",
                  transform: "translateY(-50%)",
                  zIndex: 9,
                }}
              >
                <button
                  style={{
                    border: 0,
                    background: "transparent",
                  }}
                  onClick={changeInputType}
                  type="button"
                >
                  <i
                    style={{ fontSize: "1rem", color: "#757575" }}
                    className={`mdi ${inputType.icon} action__link mr-0`}
                  ></i>
                </button>
              </InputGroup.Append>
            </InputGroup>
          ) : (
            <Form.Control
              placeholder={label || placeholder}
              size="sm"
              type={type || "text"}
              onChange={(e) => {
                onChange && onChange(e);
              }}
              {...(type === "number" && { step: "any" })}
              ref={(e) => {
                register(e, {
                  required: required || false,
                  validate: validate || true,
                  ...registerProps,
                });
                internalRef.current = e;
                if (ref) {
                  ref.current = e;
                }
              }}
              isInvalid={error || false}
              {...(defaultValue && { defaultValue })}
              {...(defaultValue === 0 && { defaultValue })} // To allow for legacy implementation
              {...rest}
              name={name}
              {...(type === "amount" && {
                readOnly: true,
                style: { visibility: "hidden", userSelect: "none" },
              })}
            />
          )}
          {helperText && error && <div className="error-msg">{helperText}</div>}
        </Form.Group>

        {type === "amount" && (
          <Form.Group
            style={{
              position: "absolute",
              top: 0,
              // zIndex: 1,
              width: "calc(100% - 1.5rem)",
            }}
          >
            {label && (
              <>
                <Form.Label>
                  {!removeDuplicate && label}
                  {spanAstric || required
                    ? !removeDuplicate && (
                        <span className="text-danger ml-1">*</span>
                      )
                    : null}
                </Form.Label>
              </>
            )}
            <Form.Control
              placeholder={label || placeholder}
              size="sm"
              onChange={(e) => {
                onChange && onChange(e.currentTarget.value);
                const inputWithoutComma = e.currentTarget.value?.replaceAll(
                  ",",
                  ""
                );
                setChanged(true);
                const hasNumber = !isNaN(+inputWithoutComma);
                if (hasNumber || inputWithoutComma === "") {
                  const numberValue =
                    inputWithoutComma === ""
                      ? null
                      : parseFloat(inputWithoutComma);
                  setValue(name, numberValue, {
                    shouldValidate: true,
                  });
                  if (
                    rest.max &&
                    numberValue !== null &&
                    numberValue > rest.max
                  ) {
                    setValue(name, rest.max, { shouldValidate: true });
                  }
                }
              }}
              {...rest}
              name={name}
              ref={internalRef}
              value={
                changed
                  ? addCommas(rest.value ?? watch(name) ?? defaultValue)
                  : addCommas(
                      rest.value ??
                        (watch(name) === ""
                          ? defaultValue
                          : watch(name) ?? defaultValue)
                    )
              }
            />
            {helperText && (
              <Form.Control.Feedback type="invalid">
                {helperText}
              </Form.Control.Feedback>
            )}
          </Form.Group>
        )}
      </>
    );
  }
);

export default CustomInput;
