import { lazy } from "react";
import * as id from "app/authorization/authId";

const Edis = lazy(() => import("app/pages/Meroshare/EDIS"));
const Wacc = lazy(() => import("app/pages/Meroshare/Wacc"));

const meroShareRoutes = [
  {
    path: "/app/meroshare/Edis",
    name: "EDIS",
    exact: true,
    component: Edis,
    authId: id.EDIS,
  },
  {
    path: "/app/meroshare/Wacc",
    name: "Wacc",
    exact: true,
    component: Wacc,
    authId: id.WACC,
    icon: "mdi mdi-gamepad-down",
  },
];

export default meroShareRoutes;
