import { axiosRequest, errorHandler } from "app/utils";
import { addMessage } from "actions/message/message";
import {
  PROVINCE_FETCHED,
  BANK_FETCHED,
  BANK_BRANCH_FETCHED,
  COMPANY_BRANCH_FETCHED,
  RELATIONSHIP_FETCHED,
  DISTRICT_FETCHED,
  OCCUPATION_TYPE_FETCHED,
  BUSINESS_TYPE_FETCHED,
  ANNUAL_INCOME_FETCHED,
  DESIGNATION_FETCHED,
  OCCUPATION_DESIGNATION_FETCHED,
} from "./dataActionTypes";

export function fetchProvinces() {
  return (dispatch) =>
    axiosRequest
      .get("data/province")
      .then((res) => {
        dispatch({
          type: PROVINCE_FETCHED,
          payload: res.data,
        });
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.reject(err);
      });
}

export function fetchBankData() {
  return (dispatch) =>
    axiosRequest
      .get("data/bank")
      .then((res) => {
        dispatch({
          type: BANK_FETCHED,
          payload: res.data,
        });
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.reject(err);
      });
}
export function fetchBankBranchData(bankId) {
  return (dispatch) =>
    axiosRequest
      .get(`data/BankBranchList/${bankId}`)
      .then((res) => {
        dispatch({
          type: BANK_BRANCH_FETCHED,
          payload: { data: res.data, id: bankId },
        });
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.reject(err);
      });
}
export function fetchCompanyBranchData(id) {
  const url = id ? `data/companyBranch/${id}` : "data/companyBranch";
  return (dispatch) =>
    axiosRequest
      .get(url)
      .then((res) => {
        dispatch({
          type: COMPANY_BRANCH_FETCHED,
          payload: { data: res.data },
        });
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.reject(err);
      });
}
export function fetchRelationshipData(id) {
  const url = id ? `data/relationship/${id}` : "data/relationship";
  return (dispatch) =>
    axiosRequest
      .get(url)
      .then((res) => {
        dispatch({
          type: RELATIONSHIP_FETCHED,
          payload: res.data,
        });
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.reject(err);
      });
}
export function fetchDistrictData(id) {
  const url = id ? `data/district/${id}` : "data/district";
  return (dispatch) =>
    axiosRequest
      .get(url)
      .then((res) => {
        dispatch({
          type: DISTRICT_FETCHED,
          payload: res.data,
        });
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.reject(err);
      });
}
export function fetchOccupationType(id) {
  const url = id ? `data/occupationType/${id}` : "data/occupationType";
  return (dispatch) =>
    axiosRequest
      .get(url)
      .then((res) => {
        dispatch({
          type: OCCUPATION_TYPE_FETCHED,
          payload: res.data,
        });
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.reject(err);
      });
}
export function fetchBusinessType(id) {
  const url = id ? `data/businessType/${id}` : "data/businessType";
  return (dispatch) =>
    axiosRequest
      .get(url)
      .then((res) => {
        dispatch({
          type: BUSINESS_TYPE_FETCHED,
          payload: res.data,
        });
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.reject(err);
      });
}
export function fetchAnnualIncome(id) {
  const url = id ? `data/annualIncome/${id}` : "data/annualIncome";
  return (dispatch) =>
    axiosRequest
      .get(url)
      .then((res) => {
        dispatch({
          type: ANNUAL_INCOME_FETCHED,
          payload: res.data,
        });
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.reject(err);
      });
}

export function fetchDesignation(id) {
  const url = id ? `data/designation/${id}` : "data/designation";
  return (dispatch) =>
    axiosRequest
      .get(url)
      .then((res) => {
        dispatch({
          type: DESIGNATION_FETCHED,
          payload: res.data,
        });
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.reject(err);
      });
}

export function fetchOccupationDesignation(id) {
  const url = id
    ? `data/occupationDesignation/${id}`
    : "data/occupationDesignation";
  return (dispatch) =>
    axiosRequest
      .get(url)
      .then((res) => {
        dispatch({
          type: OCCUPATION_DESIGNATION_FETCHED,
          payload: res.data,
        });
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.reject(err);
      });
}
