import { addMessage } from "actions/message/message";
import { axiosRequest, convertArrayToObject } from "app/utils";

export const openModal = (message, severity) => ({
  type: "OPEN_ALERT_MODAL",
  payload: { message, severity },
});
export const closeModal = () => ({
  type: "CLOSE_ALERT_MODAL",
});
