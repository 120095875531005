import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import routes from "../routes";

import CollapsedMenu from "./CollapsedMenu";
import "./index.scss";

const Sidebar = ({ drawerOpen }) => {
  const location = useLocation();
  const [state, setState] = useState({});
  //   const routes = useRoutes();

  const toggleMenuState = (menuState) => {
    if (state[menuState]) {
      setState({ [menuState]: false });
    } else if (Object.keys(state).length === 0) {
      setState({ [menuState]: true });
    } else {
      Object.keys(state).forEach((i) => {
        setState({ [i]: false });
      });
      setState({ [menuState]: true });
    }
  };

  const isPathActive = (path) => {
    return location.pathname === path;
  };

  return (
    <nav className="doc-sidebar bg-primary text-white">
      <ul className="sidebar-container">
        {routes.map((e) => {
          if (e.dropDownMenu) {
            return (
              <li
                className={
                  isPathActive(e.path)
                    ? "nav-item active sidebar-menu"
                    : "nav-item sidebar-menu"
                }
                key={e.path}
              >
                <div
                  className={state[e.name] ? "menu-expanded" : ""}
                  onClick={() => toggleMenuState(e.name)}
                  data-toggle="collapse"
                >
                  <i className={`${e.icon}  menu-icon`}></i>
                  <span className="menu-title">{e.name}</span>
                  <i className="menu-arrow"></i>
                </div>
                {state[e.name] && (
                  <CollapsedMenu
                    subRoutes={e.dropDownMenu}
                    open={state[e.name]}
                    isPathActive={isPathActive}
                  />
                )}
              </li>
            );
          }
          return (
            <li
              key={e.path}
              className={
                isPathActive(e.path)
                  ? "nav-item active text-decoration-none sidebar-menu"
                  : "nav-item sidebar-menu"
              }
            >
              <Link className="" to={e.path}>
                <i className={`${e.icon}  menu-icon`}></i>
                <span className="menu-title">{e.name}</span>
              </Link>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Sidebar;
