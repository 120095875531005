export const PROVINCE_FETCHED = "PROVINCE_FETCHED";
export const BANK_FETCHED = "BANK_FETCHED";
export const BANK_BRANCH_FETCHED = "BANK_BRANCH_FETCHED";
export const COMPANY_BRANCH_FETCHED = "COMPANY_BRANCH_FETCHED";
export const RELATIONSHIP_FETCHED = "RELATIONSHIP_FETCHED";
export const DISTRICT_FETCHED = "DISTRICT_FETCHED";
export const OCCUPATION_TYPE_FETCHED = "OCCUPATION_TYPE_FETCHED";
export const BUSINESS_TYPE_FETCHED = "BUSINESS_TYPE_FETCHED";
export const ANNUAL_INCOME_FETCHED = "ANNUAL_INCOME_FETCHED";
export const DESIGNATION_FETCHED = "DESIGNATION_FETCHED";
export const OCCUPATION_DESIGNATION_FETCHED = "OCCUPATION_DESIGNATION_FETCHED";
