// const ValueContainer = ({ children, ...props }) => {
//   const noOfValueSelected = children[0]?.length ?? 0;
//   return (
//     <components.ValueContainer {...props}>
//       {props.hasValue && (
//         <span className="placeholder">{noOfValueSelected} selected</span>
//       )}
//       {!props.hasValue && (
//         <span className="placeholder">{props?.selectProps?.placeholder}</span>
//       )}
//     </components.ValueContainer>
//   );
// };

const props = {
  isMulti: true,
  hideSelectedOptions: false,
  // closeMenuOnSelect: false,
  // components: { ValueContainer },
};
export default props;
