import { lazy } from "react";

const CustomerKyc = lazy(() => import("Kyc/Customer"));
const InstitutionKyc = lazy(() => import("Kyc/Institution"));
const CustomerDetail = lazy(() => import("Kyc/Customer/Details"));
const InstitutionDetail = lazy(() => import("Kyc/Institution/Details"));
const JointKyc = lazy(() => import("Kyc/Joint"));
const JointKycDetail = lazy(() => import("Kyc/Joint/details"));

const kycRoutes = [
  {
    path: "/kyc/client",
    name: "Customer Kyc",
    component: CustomerKyc,
  },
  {
    path: "/kyc/client/:id",
    name: "Customer Kyc Each",
    component: CustomerKyc,
  },
  {
    path: "/kyc/client-detail/:id",
    name: "Customer Detail",
    exact: true,
    component: CustomerDetail,
  },
  {
    path: "/kyc/institution",
    name: "Institution Kyc",
    exact: true,
    component: InstitutionKyc,
  },
  {
    path: "/kyc/institution/:id",
    name: "Institution Kyc Each",
    exact: true,
    component: InstitutionKyc,
  },
  {
    path: "/kyc/institution-detail/:id",
    name: "Institution Detail",
    exact: true,
    component: InstitutionDetail,
  },
  {
    path: "/kyc/joint-kyc",
    name: "Joint Kyc",
    exact: true,
    component: JointKyc,
  },
  {
    path: "/kyc/joint-kyc/:id",
    name: "Joint Kyc",
    exact: true,
    component: JointKyc,
  },
  {
    path: "/kyc/joint-kyc-detail/:id",
    name: "Joint Kyc",
    exact: true,
    component: JointKycDetail,
  },
];

export default kycRoutes;
