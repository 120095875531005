import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import FullPageLoader from "app/shared/loaders/FullPageLoader";

import Header from "app/shared/Header";
import kycRoutes from "app/routes/kycRoutes";

import LandingPage from "../LandingPage";

const Kyc = () => {
  const { isLogged } = useSelector((state) => state.getAuth);

  return (
    <Suspense fallback={<FullPageLoader />}>
      <div className="container">
        <Header />
      </div>

      <div className="container-scroller" style={{ marginTop: 75 }}>
        <div className=" container">
          <Switch>
            <Route
              exact
              path="/kyc"
              render={(props) => {
                if (isLogged === null) return <div />;
                if (isLogged) {
                  return (
                    <Redirect
                      to={{
                        pathname: "/app/dashboard",
                        state: {
                          from: props.location,
                        },
                      }}
                    />
                  );
                } else {
                  return <LandingPage />;
                }
              }}
            />
            {kycRoutes.map((e) => {
              return (
                <Route
                  key={e.path}
                  exact
                  path={e.path}
                  component={e.component}
                />
              );
            })}

            <Redirect from="/" to="/kyc" />
          </Switch>
        </div>
      </div>
    </Suspense>
  );
};

export default Kyc;
