import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import PropTypes from "prop-types";

import NepaliDatePicker from "./NepaliDatePicker";
import "./index.scss";
import { TypeConvert } from "../TypeConvert/TypeConvert";

//Regardless of calendar type chosen the returned date string is always in AD format
const DatePickerComponent = React.forwardRef((props, ref) => {
  const {
    label,
    value,
    name,
    placeholder = "YYYY-MM-DD",
    onChange,
    required = false,
    className,
    error = false,
    errorMsg,
    wrapperClassName,
    wrapperStyle = {},
    labelClassName,
    defaultValue,
    labelStyle = {},
    typeChange = "AD",
    // typeChange = null,
    getDateType,
    // check for validation if the date should exceed future or not
    isFutureDate = false,
    //apply customvalidation
    validationFromTo = [],
    //readOnlyBS prop added
    readOnlyBS,
    ...otherProps
  } = props;

  // const [dateType, setDateType] = useState(typeChange || "AD");
  const [dateType, setDateType] = useState(typeChange || "AD");
  const [dateValue, setDateValue] = useState(
    defaultValue ? new Date(defaultValue) : null
  );
  const [errorMessage, setErrorMessage] = useState("");
  useEffect(() => {
    setDateType(typeChange);
  }, [typeChange]);

  useEffect(() => {
    if (value === undefined && defaultValue) {
      setDateValue(defaultValue);
    } else setDateValue(value);
  }, [value, defaultValue]);

  const isAd = dateType === "AD";
  const labelClass = labelClassName
    ? `d-flex justify-content-between align-items-center ${
        labelClassName ?? ""
      }`
    : "d-flex justify-content-between align-items-center ";

  const wrapperClass = wrapperClassName ? `h-100 ${wrapperClassName}` : "h-100";
  // ----------------------------------------- MAIN DATE RENDERER

  const renderDate = (isAd) => {
    const todaysDate = new Date();
    return isAd ? (
      <DatePicker
        wrapperClassName="form-control-sm p-0 w-100"
        className={`form-control form-control-sm ${className} ${
          error ? "is-invalid" : ""
        }`}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        timeZone="Asia/Kathmandu"
        dateFormat="yyyy-MM-dd"
        placeholderText={placeholder}
        required={required}
        selected={
          dateValue === undefined || dateValue === null
            ? null
            : new Date(dateValue)
        }
        {...otherProps}
        isClearable
        onChange={(date) => {
          console.log(1, date);
          if (isFutureDate == false) {
            if (
              new Date(date) < new Date("January 1, 1800") ||
              new Date(date) >= todaysDate
            ) {
              setDateValue(null);
              return setErrorMessage(
                "Date must be in range between 1800 and " +
                  TypeConvert(todaysDate, "date")
              );
            }
          } else if (isFutureDate == true) {
            if (validationFromTo.length != 0) {
              if (
                new Date(date) < new Date(validationFromTo[0]) ||
                new Date(date) >= new Date(validationFromTo[1])
              ) {
                alert(2);
                setDateValue(null);
                return setErrorMessage(
                  "Date must be in range between " +
                    validationFromTo[0] +
                    " and " +
                    validationFromTo[1]
                );
              }
            }
          }
          setErrorMessage("");

          setDateValue(date);

          onChange && onChange(date ? new Date(date) : date);
        }}
      />
    ) : (
      <NepaliDatePicker
        value={
          dateValue === undefined || dateValue === null
            ? null
            : new Date(dateValue)
        }
        placeholder={placeholder}
        isClearable
        // defaultValue={dateValue}
        onChange={(date) => {
          setDateValue(date);
          // setDateType("BS");
          onChange && onChange(date ? new Date(date) : date);
        }}
        //changed code in next line
        readOnlyBS={readOnlyBS ? true : false}
      />
    );
  };
  // ------------------- END OF MAIN DATE RENDERER

  // --------------------------------------------------- COMPONENT MAIN RETURN
  return (
    <Form.Group className={wrapperClass} ref={ref} style={{ ...wrapperStyle }}>
      <Form.Label className={labelClass} style={{ ...labelStyle }}>
        <label className="m-0 mr-2">
          {label || "Date"}
          {required && <span className="text-danger">*</span>}
        </label>
        <div className="d-flex">
          {/* BS LABEL */}
          <label className="m-0 mr-2 pointer" style={{ fontSize: "0.8rem" }}>
            <input
              type="radio"
              className="mr-1"
              style={{ fontSize: "0.8rem" }}
              checked={!isAd}
              value={!isAd}
              onChange={() => {
                if (
                  new Date(dateValue).getFullYear() < 1913 ||
                  new Date(dateValue).getFullYear() > 2043
                ) {
                  setDateValue(null);
                  //validation
                  return setErrorMessage(
                    "Date must be in range between 1913 and 2043"
                  );
                }
                // validation
                setErrorMessage("");
                setDateType("BS");

                getDateType && getDateType("BS");
              }}
            />
            BS
          </label>
          {/* 
          ---  END OF BS LABEL    ---
          ---  START OF AD LABEL  ---  
          */}
          <label className=" pointer m-0" style={{ fontSize: "0.8rem" }}>
            <input
              type="radio"
              className="mr-1"
              checked={isAd}
              value={isAd}
              onChange={() => {
                setDateType("AD");
                getDateType && getDateType("AD");
              }}
            />
            AD
          </label>
          {/* END OF AD LABEL */}
        </div>
      </Form.Label>

      {/* had some issue in initial loading so have to load differently */}
      {!dateValue && renderDate(isAd)}
      {dateValue && renderDate(isAd)}
      {/* ------------------------------------------------------------- */}

      {errorMessage && <div className="error-msg">{errorMessage}</div>}
    </Form.Group>
  );
});

DatePickerComponent.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  noDefault: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  wrapperClassName: PropTypes.string,
  wrapperStyle: PropTypes.object,
  labelClassName: PropTypes.string,
  labelStyle: PropTypes.object,
};

export default DatePickerComponent;
