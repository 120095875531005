// LISTS of all Auth ID
// List in sidebar order of routes

export const DASHBOARD = ["DASHBOARD"];
//Setup Routes Auth Id
export const SETUP_USER = "SETUP_USER";
export const SETUP_ROLE = "SETUP_ROLE";
export const MAINTAIN_PRIVILEGE = "MAINTAIN_PRIVILEGE";
export const ASSIGN_ROLES = "ASSIGN_ROLES";
export const SETUP_BROKER = "SETUP_BROKER";
export const SETUP_CHARGE = "SETUP_CHARGE";
export const SETUP_PRODUCT = "SETUP_PRODUCT";
export const SETUP_CHARGE_RATE = "SETUP_CHARGE_RATE";
export const SETUP_TRANSACTION_TYPE = "SETUP_TRANSACTION_TYPE";
export const CLIENT_AND_BROKER_MAPPING = "CLIENT_AND_BROKER_MAPPING";
export const SETUP_BANK = "SETUP_BANK";
export const SETUP_COMPANY = "SETUP_COMPANY";
export const MERO_SHARE_CREDENTIAL = "MERO_SHARE_CREDENTIAL";
export const SETUP_SMTP = "SETUP_SMTP";
export const SETUP_OFFICIAL_BANK_ACCOUNT = "SETUP_OFFICIAL_BANK_ACCOUNT";
export const CLOSE_OUT = "CLOSE_OUT";
export const RENEW_EXTEND = "RENEW_EXTEND";

export const CREATE_BILL_PAYMENT_RECEIPT = "CREATE_BILL_PAYMENT_RECEIPT";
export const FLOORSHEET = "FLOORSHEET";

export const MANAGE_USER_MENU = [
  SETUP_USER,
  MAINTAIN_PRIVILEGE,
  SETUP_ROLE,
  ASSIGN_ROLES,
];

export const SETUP_CHARGE_MENU = [SETUP_CHARGE, SETUP_CHARGE_RATE];

export const SETUP = [
  ...MANAGE_USER_MENU,
  ...SETUP_CHARGE_MENU,
  SETUP_CHARGE_MENU,
  SETUP_BROKER,
  SETUP_TRANSACTION_TYPE,
  SETUP_PRODUCT,
  SETUP_BANK,
  SETUP_COMPANY,
  MERO_SHARE_CREDENTIAL,
  SETUP_SMTP,
  SETUP_OFFICIAL_BANK_ACCOUNT,
];

//Order Management List
export const UPLOAD = "UPLOAD";
export const BUY_ORDER = "BUY_ORDER";
export const SELL_ORDER = "SELL_ORDER";
export const ORDERED_LIST = "ORDERED_LIST";
export const ORDER = "ORDER";
export const ORDER_LIST = "ORDER_LIST";
export const ORDER_HISTORY = "ORDER_HISTORY";

export const ORDER_MANAGEMENT = [
  UPLOAD,
  BUY_ORDER,
  SELL_ORDER,
  ORDER_LIST,
  ORDER_HISTORY,
];

export const CLIENT_MANAGEMENT = ["CLIENT_MANAGEMENT"];

//=============================Portfolio Menu AuthId====================================//
//Portfolio Routes Auth Id
export const PORTFOLIO_CREATION = "PORTFOLIO_CREATION";
export const PORTFOLIO_TRANSACTION = "PORTFOLIO_TRANSACTION";
export const ADDITIONAL_INVESTMENT = "ADDITIONAL_INVESTMENT";

export const PORTFOLIO_EXPIRATION_LIST = "PORTFOLIO_EXPIRATION_LIST";
export const RENEWED_EXTENDED_LIST = "RENEWED_EXTENDED_LIST";

export const FD_APPROVAL = "FD_APPROVAL";
export const FD_INTEREST = "FD_INTEREST";

export const PORTFOLIO_EXPIRATION_MENU = [
  PORTFOLIO_EXPIRATION_LIST,
  RENEWED_EXTENDED_LIST,
];
export const FD_MENU = [FD_APPROVAL, FD_INTEREST];

export const PORTFOLIO = [
  PORTFOLIO_CREATION,
  PORTFOLIO_TRANSACTION,
  ADDITIONAL_INVESTMENT,
  ...PORTFOLIO_EXPIRATION_MENU,
  ...FD_INTEREST,
];
//======================================================================================/

//bILLS
export const GENERATE_BILL = "GENERATE_BILL";
export const CREATE_BILL_PAYMENT = "CREATE_BILL_PAYMENT";
export const CREATE_SELL_RECEIPT = "CREATE_SELL_RECEIPT";
export const VIEW_PAYMENT = "VIEW_PAYMENT";
export const VIEW_RECEIPT = "VIEW_RECEIPT";
export const BILLS_LIST = "BILLS_LIST";
export const RECEIPT = "RECEIPT";
export const PAYMENT = "PAYMENT";

export const BILL_MAPPING = "BILL_MAPPING";
export const BANK_RECONCILIATION = "BANK_RECONCILIATION";
export const BILL_ADJUSTMENT = "BILL_ADJUSTMENT";

export const BILLING = [
  GENERATE_BILL,
  CREATE_BILL_PAYMENT,
  CREATE_SELL_RECEIPT,
  VIEW_PAYMENT,
  VIEW_RECEIPT,
  BILLS_LIST,
  RECEIPT,
  PAYMENT,
  FLOORSHEET,
  BILL_MAPPING,
  BANK_RECONCILIATION,
  BILL_ADJUSTMENT,
];

//===============Corporate Actions=============//
export const ANNOUNCEMENT = "ANNOUNCEMENT";
export const ANNOUNCEMENT_ADJUSTMENT = "ANNOUNCEMENT_ADJUSTMENT";
export const ANNOUNCEMENT_DETAIL = "ANNOUNCEMENT_DETAIL";
export const ALLOTMENT = "ALLOTMENT";
export const CORPORATE_ACTIONS = [ANNOUNCEMENT, ALLOTMENT, ANNOUNCEMENT_DETAIL];
export const IPO = "IPO";
// ============================================//

// ===============Data Analytics ==============//
export const DATA_ANALYTICS = ["DATA_ANALYTICS"];
//===========================================//

//setup fixed deposit
export const BANK_ASSESSMENT = "BANK_ASSESSMENT";
export const FR_ACCOUNT = "FR_ACCOUNT";
export const FRPAYMENT = "FRPAYMENT";
export const BFI_BANK_ACCOUNT = "BFI_BANK_ACCOUNT";
export const BFI_BANK_LIMIT = "BFI_BANK_LIMIT";
export const FR_REPORTS = "FR_REPORTS";
export const FR_INTEREST_REPORTS = "FR_INTEREST_REPORTS";
export const FR_DASHBOARD = "FR_DASHBOARD";
export const FR_INTEREST = "FR_INTEREST";
export const FIXED_RETURN = [
  FR_DASHBOARD,
  BANK_ASSESSMENT,
  BFI_BANK_ACCOUNT,
  FR_ACCOUNT,
  FRPAYMENT,
  BFI_BANK_LIMIT,
  FR_REPORTS,
  FR_INTEREST,
  FR_INTEREST_REPORTS,
];

//setup meroshare
export const WACC = "WACC";
export const EDIS = "EDIS";
export const MEROSHARE = [WACC, EDIS];

//EXIT CHARGE
export const EXIT = "EXIT";
export const AMC_DMAT = "AMC_DMAT";
export const CASH_WITHDRAWAL = "CASH_WITHDRAWAL";
export const RENEW = "RENEW";
export const CHARGE_EXPIRATION = "CHARGE_EXPIRATION";
export const STOCK_WITHDRAW = "STOCK_WITHDRAW";

// export const REPORT = "REPORT";
//Reports Routes Auth Id
export const PORTFOLIO_SUMMARY_REPORT = "PORTFOLIO_SUMMARY_REPORT";
export const CHARGE_REPORTS = "CHARGE_REPORTS";
export const BANK_STATEMENT = "BANK_STATEMENT";
export const FIXED_RETURN_REPORT = "FIXED_RETURN_REPORT";
export const STOCK_DEVIATION_REPORT = "STOCK_DEVIATION_REPORT";
export const STOCK_STATEMENT = "STOCK_STATEMENT";
export const TOTAL_CLIENTS = "TOTAL_CLIENTS";
export const FR_SEBON_REPORT = "FR_SEBON_REPORT";

export const CLIENT_INFORMATION_REPORT = "CLIENT_INFORMATION_REPORT";
export const FR_CLIENT_DETAIL_REPORT = "FR_CLIENT_DETAIL_REPORT";

export const FEE_DEDUCTION_REPORT = "FEE_DEDUCTION_REPORT";
export const PORTFOLIO_INVESTMENT_REPORT = "PORTFOLIO_INVESTMENT_REPORT";
export const PURCHASE_SALES_REPORT = "PURCHASE_SALES_REPORT";
export const INCOME_REPORT = "INCOME_REPORT";
export const AUM_REPORT = "AUM_REPORT";
export const CLIENT_REPORT = "CLIENT_REPORT";
export const TTR_REPORT = "TTR_REPORT";
export const CLIENT_AGREEMENT = "CLIENT_AGREEMENT";
export const CLIENT_DETAILS_REPORT = "CLIENT_DETAILS_REPORT";
export const PORTFOLIO_TRADING_REPORT = "PORTFOLIO_TRADING_REPORT";
export const PORTFOLIO_PRODUCT_REPORT = "PORTFOLIO_PRODUCT_REPORT";

export const REPORTS = [
  PORTFOLIO_SUMMARY_REPORT,
  CHARGE_REPORTS,
  BANK_STATEMENT,
  FIXED_RETURN_REPORT,
  STOCK_DEVIATION_REPORT,
  STOCK_STATEMENT,
  PORTFOLIO_EXPIRATION_LIST,
  TOTAL_CLIENTS,
  FR_SEBON_REPORT,
  CLIENT_INFORMATION_REPORT,
  FEE_DEDUCTION_REPORT,
  PORTFOLIO_INVESTMENT_REPORT,
  FR_CLIENT_DETAIL_REPORT,
  PURCHASE_SALES_REPORT,
  INCOME_REPORT,
  AUM_REPORT,
  TTR_REPORT,
  CLIENT_AGREEMENT,
  CLIENT_DETAILS_REPORT,
  PORTFOLIO_TRADING_REPORT,
  PORTFOLIO_PRODUCT_REPORT,
];

export const PERFORMANCE = "PERFORMANCE";
export const STOCK_WITHDRAWAL = "STOCK_WITHDRAWAL";
export const CHARGE = [
  EXIT,
  AMC_DMAT,
  CASH_WITHDRAWAL,
  STOCK_WITHDRAW,
  RENEW,
  CHARGE_EXPIRATION,
  // REPORT,
  PERFORMANCE,
];

export const KYC_OFFICIAL_USE = "KYC_OFFICIAL_USE";
export const KYC = [KYC_OFFICIAL_USE];

export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const PROFILE = [CHANGE_PASSWORD];

// export const ACCOUNTING = "ACCOUNTING";

// auth ids for order history
