import { GET_ALL_FLOORSHEETS, GET_FLOORSHEET_DETAILS } from "./floorsheetTypes";

const defaultState = {
  allFloorsheets: [],
  floorSheetDetails: {},
};
// ------------------------------------------------------------ Reducer Here
const FloorsheetReducer = (state = defaultState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_ALL_FLOORSHEETS:
      return { ...state, allFloorsheets: payload };

    case GET_FLOORSHEET_DETAILS:
      return { ...state, floorSheetDetails: payload };

    default:
      return state;
  }
};
// ----------------------------------------------------------------------

export default FloorsheetReducer;
