import { FETCH_BFI_BANK } from "./bfiActionsType";

const init = {
  bfiBank: [],
};

export default function bfiBankReducer(state = init, { type, payload }) {
  switch (type) {
    case FETCH_BFI_BANK:
      return { ...state, bfiBank: [...payload] };

    default:
      return state; 
  }
}
