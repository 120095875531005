import { PORTFOLIO_PRODUCT_FETCHED } from "./productActionTypes";

const init = {
  portfolioProducts: [],
};
const portfolioProductsReducer = (state = init, action) => {
  const { type, payload } = action;

  switch (type) {
    case PORTFOLIO_PRODUCT_FETCHED:
      return {
        ...state,
        portfolioProducts: payload.data,
        paginationData: payload.paginationData,
      };
    default:
      return state;
  }
};

export default portfolioProductsReducer;
