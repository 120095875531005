export const CREATE = "CREATE";
export const READ = "READ";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
export const REPORT = "REPORT";

//IPO
export const SAVE = "SAVE";
export const APPROVE = "APPROVE";
export const VIEW_DETAILS = "DETAILS";
export const APPLY = "APPLY";

//Dashboard
export const CLIENT = "CLIENT";
export const BROKER = "BROKER";
export const OPERATIONS = "PMS Operation";

//KYC
export const DOCUMENT = "DOCUMENT";

//Deposit
export const DEPOSIT = "DEPOSIT";
