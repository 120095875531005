import { addMessage } from "actions/message/message";
import { fetchCompany } from "api/company";
import { formatDate } from "app/utils";
import { orderTypeOptions, priceTypeOptions } from "common/selectOptions";

//All these fields are predefined and in sync with backend business logic
// If DATA format changes or if backend logic changes then change this accordingly
const key = (i) => (i ? `__EMPTY_${i}` : `__EMPTY`);

const __danger__excelFormatter = (data) => {
  return (dispatch) => {
    let error = [];
    try {
 

    // 5= rowNumber 6
    const tranType = Object.values(data[5])[0].trim();

    const stockDirection = tranType.toUpperCase().includes("SELL") ? 1 : 2;
    const res = [];
    // i=17 which is rowNumber 20
    for (let i = 17; i < data.length; i++) {
      let _tempError = [];
      const row = data[i];

      const clientName = Object.values(row)?.[0];
      // if the first field which is the client name isn't present then ignore
      //because it is assumed that the row contains the Total value instead of data;
      const totalRegex = /total/i;
      if (totalRegex.test(row[key(1)])) {
        const transactionNumber = row[key(1)];
        const quantity = row[key(5)];
        const amount = row[key(7)];
        const commissionAmount = row[key(8)];

        res.push({
          transactionNumber,
          quantity,
          amount,
          commissionAmount,
        });
        continue;
      }

      const stockSymbol = row[key(0)];
      const transactionNumber = row[key(1)];
      const dateBS = row[key(2)];
      const shareType = row[key(3)];

      const brokerId = row[key(4)];
      // floorsheetImport.brokerId = row[key(4)];
      // floorsheetImport.stockDirection = stockDirection;

      const quantity = row[key(5)];
      const rate = row[key(6)];
      const amount = row[key(7)];
      const commissionAmount = row[key(8)];
      const status = row[key(9)] ?? "";
      const coQty = row[key(10)] ?? 0;

      if (!stockSymbol) {
        _tempError.push("Stock");
      }
      if (!transactionNumber) {
        _tempError.push("Transaction Number");
      }
      if (!dateBS) {
        _tempError.push("Date");
      }
      if (!brokerId) {
        _tempError.push(stockDirection === 1 ? "Seller" : "Buyer");
      }

      quantity ?? _tempError.push("Quantity");
      rate ?? _tempError.push("Rate");
      amount ?? _tempError.push("Amount");

      if (_tempError.length) {
        error.push({ row: row.__rowNum__ + 1, emptyFields: _tempError });
      }
      res.push({
        clientName,
        stockSymbol,
        transactionNumber,
        dateBS,
        shareType,
        quantity,
        rate,
        amount,
        commissionAmount,
        coQty,
        status,
        brokerId,
        // floorsheetImport,
      });
    }

    return { data: res, error };
    } catch (err) {
  
      dispatch(
        addMessage({
          type: `error`,
          data:
            "The excel data might be corrupted or the file mightn't contain the data in correct format. Please check and try again",
          title: ``,
        })
      );
      return {data:[],error};
    }
  };
};

function excelDateToJSDate(serial) {
  var utc_days = Math.floor(serial - 25569);
  var utc_value = utc_days * 86400;
  var date_info = new Date(utc_value * 1000);

  var fractional_day = serial - Math.floor(serial) + 0.0000001;

  var total_seconds = Math.floor(86400 * fractional_day);

  var seconds = total_seconds % 60;

  total_seconds -= seconds;

  var hours = Math.floor(total_seconds / (60 * 60));
  var minutes = Math.floor(total_seconds / 60) % 60;

  return new Date(
    date_info.getFullYear(),
    date_info.getMonth(),
    date_info.getDate(),
    hours,
    minutes,
    seconds
  );
}

export const __danger__orderExcelFormatter = (data) => {
  const formatCompanyForSelect = (company) => {
    if (!company) return;
    return {
      ...company,
      label: `${company?.name} (${company.stockSymbol})`,
      value: company?.id,
    };
  };

  return async (dispatch) => {
    const company = await dispatch(
      fetchCompany({ pageSize: 500, pageNumber: 1 })
    );
    try {
      const excelData = [];
      const error = [];
      data.forEach((excel, i) => {
        const companyDetail = company?.contents?.find(
          (e) => e.stockSymbol === excel.Stock
        );
        //TODO  throw error msg if company Detail not Found

        const clientStringRegexMatch = excel["Client"].match(/\((.*)\)/);
        const clientName = excel["Client"];
        // const change = clientStringRegexMatch?.[1].match(
        //   /(\b[A-Z][A-Z]+|\b[A-Z]\b)/g
        // );
        const portfolioAccountNumber = clientStringRegexMatch?.[1];
        const portfolioAccountId = portfolioAccountNumber;

        const stockSymbol = excel["Stock"];

        const priceTypeLabel = excel["Price Type"];
        const priceType = priceTypeOptions.find(
          (e) => e.label === priceTypeLabel
        )?.value;

        const rate = excel["Price"];

        const orderTypeLabel = excel["Order Type"];
        const orderType = orderTypeOptions.find(
          (e) => e.label === orderTypeLabel
        )?.value;

        const quantity = excel["Order"];

        // : data.portfolioAccountId?.value,
        // : data.portfolioAccountId?.label,
        // : data.companyId?.value,
        // : data.companyId?.stockSymbol,
        // : data.companyId?.name,
        // : data.priceType?.value,
        // : data.priceType?.label,
        // : data.rate,
        // : data.orderType?.value,
        // : data.orderType?.label,
        // quantity: data.quantity,

        let _tempError = [];
        if (!stockSymbol) {
          _tempError.push("Stock");
        }
        if (!quantity) {
          _tempError.push("Quantity");
        }

        if (_tempError.length) {
          error.push({ row: i, emptyFields: _tempError });
        }

        const formattedData = {
          portfolioAccountId,
          clientName,
          stockSymbol,
          companyName: companyDetail?.name,
          priceType,
          priceTypeLabel,
          rate,
          orderType,
          orderTypeLabel,
          company: formatCompanyForSelect(companyDetail),
          companyId: companyDetail?.id,
          quantity,
        };

        excelData.push(formattedData);
      });

      return { data: excelData, error, selectedEditedData: excelData };
    } catch (err) {
      dispatch(
        addMessage({
          type: `error`,
          data:
            "The excel data might be corrupted or the file mightn't contain the data in correct format. Please check and try again",
          title: ``,
        })
      );
      return {};
    }
  };
};
export const __danger__bankStatementExcelFormatter = (data) => {
  return async (dispatch) => {
    try {
      const excelData = [];
      const error = [];
      data.forEach((excel, i) => {
        const name = Object.values(excel);

        let formattedData = {};
        for (let propName in excel) {
          if (excel.hasOwnProperty(propName)) {
            if (propName.includes("__")) {
              // formattedData.balance = excel[key(4)] + "";
              formattedData.credit = excel[key(3)];
              formattedData.debit = excel[key(2)];

              const date = excel[key(1)];
              formattedData.date = date
                ? formatDate(excelDateToJSDate(excel[key(1)]))
                : undefined;
            } else {
              // formattedData.name = name[1];
              const details = excel[propName];
              formattedData.details = name[1];
              formattedData.balance = details;
              const chequeMatchRegex = /Chq No.(.*)/;
              const chequeNo = details.match(chequeMatchRegex)?.[1];
              formattedData.chequeNo = chequeNo;
            }
          }
        }

        let _tempError = [];

        if (_tempError.length) {
          error.push({ row: i, emptyFields: _tempError });
        }

        excelData.push(formattedData);
      });

      return { data: excelData, error };
    } catch (err) {
      dispatch(
        addMessage({
          type: `error`,
          data:
            "The excel data might be corrupted or the file mightn't contain the data in correct format. Please check and try again",
          title: ``,
        })
      );
    }
  };
};

const swapMonthDay = (value) => {
  const newDate =
    value.substr(6, 4) + "-" + value.substr(3, 2) + "-" + value.substr(0, 2);
  return newDate;
};

export const __danger__bankReconcilationExcelFormatter = (data) => {
  return async (dispatch) => {
    try {
      const excelData = [];
      const error = [];
      data.forEach((excel, i) => {
        const name = Object.values(excel);

        let formattedData = {};
        for (let propName in excel) {
          if (excel.hasOwnProperty(propName)) {
            if (propName.includes("__")) {
              // formattedData.balance = excel[key(4)] + "";
              formattedData.credit = excel[key(3)];
              formattedData.debit = excel[key(2)];

              const date = excel[key(1)];
              formattedData.date = date
                ? formatDate(excelDateToJSDate(excel[key(1)]))
                : undefined;
            } else {
              // formattedData.name = name[1];
              const date = name[0];

              const details = excel[propName];
              formattedData.details = excel[1];
              formattedData.date = swapMonthDay(name[0]);
              formattedData.credit = excel[4]
                ? parseFloat(excel[4].replace(/[,]/g, ""))
                : 0;
              formattedData.debit = excel[3]
                ? parseFloat(excel[3].replace(/[,]/g, ""))
                : 0;
              formattedData.balance = details;
              const chequeMatchRegex = /Chq No.(.*)/;
              const chequeNo = details.match(chequeMatchRegex)?.[1];
              formattedData.chequeNo = chequeNo;
            }
          }
        }

        let _tempError = [];

        if (_tempError.length) {
          error.push({ row: i, emptyFields: _tempError });
        }

        excelData.push(formattedData);
      });

      return { data: excelData, error };
    } catch (err) {
      dispatch(
        addMessage({
          type: `error`,
          data:
            "The excel data might be corrupted or the file mightn't contain the data in correct format. Please check and try again",
          title: ``,
        })
      );
    }
  };
};
export const __danger__stockExcelFormatter = (data) => {
  const formatCompanyForSelect = (company) => {
    if (!company) return;
    return {
      ...company,
      label: `${company?.name} (${company.stockSymbol})`,
      value: company?.id,
    };
  };

  return async (dispatch) => {
    const company = await dispatch(
      fetchCompany({ pageSize: 500, pageNumber: 1 })
    );
    try {
      const excelData = [];
      const error = [];
      data.forEach((excel, i) => {
        const companyDetail = company?.contents?.find(
          (e) => e.stockSymbol === excel[1]
        );

        const stockSymbol = excel[1];
        const quantity = excel[2];

        const stockEvaluationDate = formatDate(
          typeof excel[3] === "number" ? excelDateToJSDate(excel[3]) : excel[3]
        );
        const wacc = excel[4];
        const rate = excel[5];
        const amount = excel[6];

        let _tempError = [];
        if (!stockSymbol) {
          _tempError.push("Stock");
        }
        if (!quantity) {
          _tempError.push("Quantity");
        }
        if (!stockEvaluationDate) {
          _tempError.push("Last Trade Date");
        }
        if (!rate) {
          _tempError.push("Last Trade Price");
        }
        if (!amount) {
          _tempError.push("Value Amount");
        }
        if (_tempError.length) {
          error.push({ row: i, emptyFields: _tempError });
        }
        const formattedData = {
          stockSymbol,
          company: formatCompanyForSelect(companyDetail),
          companyId: companyDetail?.id,
          quantity,
          amount,
          rate,
          wacc,
          stockEvaluationDate,
        };

        excelData.push(formattedData);
      });

      return { data: excelData, error };
    } catch (err) {
      dispatch(
        addMessage({
          type: `error`,
          data:
            "The excel data might be corrupted or the file mightn't contain the data in correct format. Please check and try again",
          title: ``,
        })
      );
    }
  };
};

export default __danger__excelFormatter;
