import { applyMiddleware, createStore, compose } from "redux";
import promise from "redux-promise-middleware";
import thunk from "redux-thunk";
import allReducers from "./reducers/State";

const middleware = [promise, thunk];
const initialState = {};

// const composeEnhancer =
//   (process.env.NODE_ENV !== "production" &&
//     typeof window !== "undefined" &&
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true })) ||
//   compose;

const composeEnhancer =
  (process.env.NODE_ENV === "development"
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null) || compose;

export default createStore(
  allReducers,
  initialState,
  composeEnhancer(applyMiddleware(...middleware))
);
