import { axiosRequest, errorHandler, addMessage } from "app/utils";

import { GET_ALL_FLOORSHEETS, GET_FLOORSHEET_DETAILS } from "./floorsheetTypes";

const ENDPOINT = "Floorsheet";

// ---------------------------------------- Action 1: Get all floorsheets
const getFloorsheets = (params) => async (dispatch) => {
  try {
    const response = await axiosRequest.get(ENDPOINT, { params });
    dispatch({ type: GET_ALL_FLOORSHEETS, payload: response.data });
  } catch (error) {
    dispatch(addMessage(errorHandler(error)));
  }
};

// --------------------------------------- Action 2: Get Floorsheet Details
const getFloorsheetDetails = (params) => async (dispatch) => {
  try {
    const response = await axiosRequest.get(`${ENDPOINT}/Details`, {
      params,
    });
    dispatch({ type: GET_FLOORSHEET_DETAILS, payload: response.data });
  } catch (error) {
    dispatch(addMessage(errorHandler(error)));
  }
};

// --------------------------- Action 3: Delete Single Floorsheet
const deleteFloorsheet = (id, remarks) => async (dispatch) => {
  return axiosRequest
    .delete(`${ENDPOINT}/${id}`, {
      params: { deleteRemarks: remarks },
    })
    .then((res) => {
      dispatch(
        addMessage({
          type: "success",
          data: "Portfolio Detail Deleted Successfully",
        })
      );
      return Promise.resolve(res);
    })
    .catch((err) => {
      dispatch(addMessage(errorHandler(err)));
      return Promise.resolve(err);
    });
};

// ------------------------ Action 4: Approve Floorsheet
// TODO: update api for mass approve (AKA Bulk approve)
// -----------------------------------------------------
const approveFloorsheet = (data) => async (dispatch) => {
  return axiosRequest
    .post(`${ENDPOINT}/Approve`, data)
    .then((res) => {
      dispatch(
        addMessage({
          type: "success",
          data: "Successfully Approved Floorsheet",
        })
      );
      return Promise.resolve(res);
    })
    .catch((err) => {
      dispatch(addMessage(errorHandler(err)));
      return Promise.resolve(err);
    });
};

export {
  getFloorsheets,
  getFloorsheetDetails,
  deleteFloorsheet,
  approveFloorsheet,
};
