import { combineReducers } from "redux";

import loading from "./activateLoading/activate-loading";
import message from "./message/message";

import allStoreReducers from "app/store/State";
import jointKycReducer from "Kyc/store/jointKyc/reducers";

const allReducers = combineReducers({
  loading,
  message,
  jointKyc: jointKycReducer,
  ...allStoreReducers,
});

export default allReducers;
