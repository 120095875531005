import React from "react";
import SaveAdjustment from "./SaveAdjusment";
import { Card } from "react-bootstrap";

const AnnouncementAdjustment = ({ authId }) => {
  return (
    <Card>
      <Card.Body>
        <SaveAdjustment authId={authId} />
      </Card.Body>
    </Card>
  );
};

export default AnnouncementAdjustment;
