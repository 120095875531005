import getPortfolioAccounts from "./admin/portfolio/portfolioAccounts/portfolioAccountsReducer";
import getProducts from "./admin/setup/product/productReducer";
import getBrokers from "./admin/setup/broker/brokerReducer";
import getRoles from "./admin/setup/role/roleReducer";
import getCharges from "./admin/setup/charge/chargeReducer";
import getChargeRates from "./admin/setup/chargeRate/chargeRateReducer";
import getTransactionTypes from "./admin/setup/transactionType/transactionTypeReducer";
import getOrderFromBroker from "./dealer/orderFromBroker/orderReducer";
import getAuth from "./auth/authReducer";
import getSectors from "./sectors/sectorReducer";
import getFiscalYear from "./fiscalYear";
import getIPO from "./ipo";
import getBfiBank from "./bfiBank/bfiReducer";
import getRiskGrading from "./riskGradingParameter/riskGradingReducer";
import getData from "./data";
import Floorsheet from "./Floorsheet";
import ErrorModal from "./errorModal";
import Filters from "./filters/filterReducer";

const allStoreReducers = {
  getBrokers,
  getChargeRates,
  getCharges,
  getPortfolioAccounts,
  getProducts,
  getRoles,
  getTransactionTypes,
  getOrderFromBroker,
  getAuth,
  getSectors,
  getFiscalYear,
  getIPO,
  getBfiBank,
  getRiskGrading,
  getData,
  floorsheet: Floorsheet,
  errorModalState: ErrorModal,
  filters: Filters,
};

export default allStoreReducers;
