import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useSelector } from "react-redux";

export const ProtectedApp = ({ component: Component, ...rest }) => {
  const { isLogged } = useSelector((state) => state.getAuth);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isLogged === null) return <div />;
        if (isLogged) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
};
