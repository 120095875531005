import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";

import { Link } from "react-router-dom";
import { useAuth } from "app/authorization";
import { useDispatch } from "react-redux";
import { useRoutes } from "app/routes";
import Avatar from "app/components/Avatar";

import SidebarLogoLong from "assets/images/logo.JPG";

const SubRouteRender = ({ routes }) => {
  const [show, setShow] = React.useState(false);

  const { isAuthorized } = useAuth();
  const dispatch = useDispatch();

  return routes?.map((e) => {
    if (e.hideInSidebar) return null;
    if (e.dropDownMenu) {
      // hide if all the dropdown menu are unauthorized.
      const hideMenu = e.dropDownMenu.every(
        (x) =>
          !isAuthorized({
            authId: x.authId,
            componentId: x.componentId,
          })
      );
      if (hideMenu) return null;

      return (
        <div onClick={() => dispatch({ type: "RESET_LOADING" })} key={e.name}>
          <NavDropdown.Item as="div">
            <NavDropdown
              title={`${e.name}`}
              className="mr-auto"
              key={e.name}
              drop="right"
              onMouseOver={() => setShow(e.name)}
              onMouseOut={() => setShow(false)}
              show={show === e.name}
            >
              <SubRouteRender routes={e.dropDownMenu} />
            </NavDropdown>
          </NavDropdown.Item>
        </div>
      );
    }
    if (isAuthorized({ authId: e.authId, componentId: e.componentId })) {
      return (
        <NavDropdown.Item
          as="li"
          onClick={() => dispatch({ type: "RESET_LOADING" })}
          key={e.name}
        >
          <Link to={e.path} className="nav-link " role="buton">
            {e.name}
          </Link>
        </NavDropdown.Item>
      );
    }
    return null;
  });
};

const NavHeader = () => {
  const routes = useRoutes();
  // const { refUserId } = useSelector((state) => state.getAuth);
  const { checkMenuAuth } = useAuth();

  const dispatch = useDispatch();

  return (
    <Navbar
      collapseOnSelect
      bg="light"
      expand="xl"
      fixed="top"
      className="shadow-sm"
    >
      <Link className="nav-link" to="/app/dashboard">
        <Navbar.Brand>
          <img src={SidebarLogoLong} alt="logo" style={{ maxWidth: 180 }} />
        </Navbar.Brand>
      </Link>
      <Navbar.Toggle aria-controls="responsive-navbar-nav" />
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="mr-auto">
          {routes.map((e) => {
            if (checkMenuAuth({ authId: e.authId })) {
              if (e.hideInSidebar) return null;
              if (e.goTo) {
                return (
                  <li key={e.goTo} className={"nav-item"}>
                    <a
                      href={e.goTo}
                      // http://45.115.218.198:1011
                      className="nav-link"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {/* <i className={`${e.icon}  menu-icon`}></i> */}
                      <span className="menu-title">
                        <span>{e.name}</span>
                      </span>
                    </a>
                  </li>
                );
              }
              if (e.dropDownMenu) {
                return (
                  <NavDropdown
                    title={`${e.name}`}
                    className="mr-auto"
                    key={e.name}
                  >
                    <SubRouteRender routes={e.dropDownMenu} />
                  </NavDropdown>
                );
              } else {
                return (
                  <li
                    onClick={() => dispatch({ type: "RESET_LOADING" })}
                    key={e.name}
                  >
                    <Link className="nav-link" to={e.path}>
                      {e.name}
                    </Link>
                  </li>
                );
              }
            } else return null;
          })}
        </Nav>
        <Nav>
          <Avatar username={"Super Admin"} dashboard />
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavHeader;
