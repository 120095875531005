import React from "react";
import Avatar from "../Avatar";
import sidebarLogo from "assets/images/sidebarLogo.png";

const DashboardHeader = ({ setDrawerOpen, drawerOpen }) => {
  const toggleOffcanvas = () => {
    // setDrawerOpen(!drawerOpen)
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };

  return (
    <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
        <a
          className="navbar-brand brand-logo-mini align-self-center d-lg-none"
          href="!#"
          onClick={(evt) => evt.preventDefault()}
        >
          <img src={sidebarLogo} alt="logo" style={{ maxWidth: 60 }} />
        </a>
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          onClick={() => {
            setDrawerOpen(!drawerOpen);
            document.body.classList.toggle("sidebar-icon-only");
          }}
        >
          <i className="mdi mdi-menu"></i>
        </button>
        <ul className="navbar-nav navbar-nav-left header-links align-self-center">
          <li className="nav-item font-weight-semibold d-none  d-md-flex">
            Portfolio Management System
          </li>
        </ul>
        <ul className="navbar-nav navbar-nav-right">
          <Avatar username={"Super Admin"} dashboard />
        </ul>
        <button
          className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
          type="button"
          onClick={toggleOffcanvas}
        >
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
};

export default DashboardHeader;
