import { SET_FILTER_PARAMS } from "./filterActionsType";

const init = {
  floorSheetFilterParams: {},
};

export default function filterReducer(state = init, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_FILTER_PARAMS:
      //console.log("here in reducer>>", type, payload);
      return { ...state, floorSheetFilterParams: payload };

    default:
      return state;
  }
}
