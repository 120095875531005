import { FETCH_TRANSACTION_TYPE } from "./transactionTypeTypes";

const init = [];

const transactionTypeReducer = (state = init, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TRANSACTION_TYPE:
      return payload;

    default:
      return state;
  }
};

export default transactionTypeReducer;
