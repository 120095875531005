import {
  FETCH_FISCAL_YEAR,
  FETCH_FISCAL_YEAR_DATE,
} from "./fiscalYearActionType";

const init = {
  fiscalYear: null,
  fiscalYearDate: {},
};

export default function fiscalYearReducer(state = init, { type, payload }) {
  switch (type) {
    case FETCH_FISCAL_YEAR:
      return { ...state, fiscalYear: [...payload] };

    case FETCH_FISCAL_YEAR_DATE:
      return { ...state, fiscalYearDate: payload };

    default:
      return state;
  }
}
