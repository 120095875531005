import * as React from "react";
import { useSelector } from "react-redux";

import "./full_page_loader_style.css";
import "./ProcessLoader";

function FullPageLoader({ style = {} }) {
  const { flag: startLoader, top, fullPageLoader } = useSelector(
    (state) => state.loading
  );

  const Loader = ({ className }) => (
    <div
      className={`full-page-loader-container ${className}`}
      style={{
        top,
        ...style,
      }}
    >
      <div>
        <div className="spinner">
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </div>
    </div>
  );
  return (
    <div>
      {fullPageLoader ? (
        <Loader className="fullPageLoader" />
      ) : startLoader ? (
        <Loader className="main" />
      ) : (
        <></>
      )}
    </div>
  );
}

export default FullPageLoader;
