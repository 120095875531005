import { FETCH_RISK_GRADING } from "./riskGradingActionType";
const init = {
  riskGrading: [],
};

export default function createAnnouncement(state = init, { type, payload }) {
  switch (type) {
    case FETCH_RISK_GRADING:
      return { ...state, riskGrading: [...payload] };

    default:
      return state;
  }
}
