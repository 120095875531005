import React from "react";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "app/store/errorModal";

const ErrorModal = ({ show, handleClose, children }) => {
  const { openAlert, message } = useSelector((state) => state.errorModalState);

  return (
    <Modal
      show={openAlert}
      onHide={() => closeModal()}
      dialogClassName="upload_error_modal"
    >
      <Modal.Header closeButton>
        <Modal.Title className="text-center text-danger w-100">
          Error Description
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ height: 150, overflowY: "scroll" }}>{message}</div>
      </Modal.Body>
    </Modal>
  );
};
export default ErrorModal;
