import { lazy } from "react";

const Table = lazy(() => import("Documentation/Table"));

const routes = [
  {
    path: "/doc/get-started",
    name: "Get Started",
    component: Table,
  },
  {
    path: "/doc/table",
    name: "Table",
    component: Table,
  },

  {
    path: "/doc/react-select",
    name: "React Select",
    component: Table,
  },
  {
    path: "/doc/filter-form",
    name: "Filter Form",
    component: Table,
  },
  {
    path: "/doc/folder-structure",
    name: "Folder Structure",
    component: Table,
  },
  {
    path: "/doc/kyc",
    name: "KYC",
    component: Table,
  },
  {
    path: "/doc/Experimental",
    name: "Experimental",
    component: Table,
  },
  {
    path: "/doc/Authorization",
    name: "Authorization",
    component: Table,
  },
];

export default routes;
