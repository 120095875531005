import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import Avatar from "app/components/Avatar";
import SidebarLogoLong from "assets/images/logo.JPG";

const HorizontalNavbar = () => {
  const history = useHistory();

  const handleLoginRoute = () => {
    history.push("/login");
  };
  const { isLogged } = useSelector((state) => state.getAuth);
  return (
    <Navbar
      collapseOnSelect
      bg="light"
      expand="lg"
      fixed="top"
      className="shadow-sm"
    >
      <Link className="nav-link" to="/app/dashboard">
        <Navbar.Brand>
          <img src={SidebarLogoLong} alt="logo" style={{ maxWidth: 180 }} />
        </Navbar.Brand>
      </Link>
      <Nav className="mr-auto" />
      <Nav>
        {isLogged ? (
          <Avatar username={"Super Admin"} dashboard />
        ) : (
          <button
            className="btn btn-md btn-primary mx-1"
            onClick={handleLoginRoute}
          >
            Login
          </button>
        )}
      </Nav>
    </Navbar>
  );
};

export default HorizontalNavbar;
