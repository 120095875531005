import { lazy } from "react";
import * as id from "app/authorization/authId";

//Setup Routes Components
const SetupUser = lazy(() => import("app/pages/Setup/SetupUser"));
const SetupProduct = lazy(() => import("app/pages/Setup/SetupProduct"));
const SetupBroker = lazy(() => import("app/pages/Setup/SetupBroker"));
const SetupCharge = lazy(() => import("app/pages/Setup/SetupCharge"));
const SetupTransactionType = lazy(() =>
  import("app/pages/Setup/SetupTransactionType")
);
const SetupChargeRate = lazy(() => import("app/pages/Setup/SetupChargeRate"));
const SetupRole = lazy(() => import("app/pages/Setup/SetupRole"));
const MaintainPrivilege = lazy(() =>
  import("app/pages/Setup/MaintainPrivilege")
);
const AssignRoles = lazy(() => import("app/pages/Setup/AssignRoles"));

const ClientBrokerMapping = lazy(() =>
  import("app/pages/Setup/ClientBrokerMapping")
);

const SetupBank = lazy(() => import("app/pages/Setup/SetupBank"));
const SetupCompany = lazy(() => import("app/pages/Setup/SetupCompany"));
const MeroShareCredential = lazy(() => import("app/pages/Setup/Meroshare"));
// const SetupBank = lazy(() => import("app/pages/Setup/SetupBank"));
const SetupSMTP = lazy(() => import("app/pages/Setup/SMTP"));
const OfficialBankAccount = lazy(() =>
  import("app/pages/Setup/OfficialBankAccount")
);

const manageUserRoutes = [
  {
    path: "/app/setup/user",
    name: "Setup User",
    component: SetupUser,
    authId: id.SETUP_USER,
  },
  {
    path: "/app/setup/role",
    name: "Setup Role",
    component: SetupRole,
    authId: id.SETUP_ROLE,
  },
  {
    path: "/app/setup/maintain-privilege",
    name: "Maintain Privilege",
    component: MaintainPrivilege,
    authId: id.MAINTAIN_PRIVILEGE,
  },
  {
    path: "/app/setup/assign-roles",
    name: "Assign Roles",
    component: AssignRoles,
    authId: id.ASSIGN_ROLES,
  },
];
const chargeSetupRoutes = [
  {
    path: "/app/setup/charge",
    name: "Setup Charge",
    component: SetupCharge,
    authId: id.SETUP_CHARGE,
  },
  {
    path: "/app/setup/charge-rate",
    name: "Setup Charge Rate",
    component: SetupChargeRate,
    authId: id.SETUP_CHARGE_RATE,
  },
];

//Setup Routes config distributed to whole project
const setupRoutes = [
  {
    path: "/app/setup/manage-user",
    name: "Manage Users",
    dropDownMenu: manageUserRoutes,
    authId: id.MANAGE_USER_MENU,
  },
  {
    path: "/app/setup/setup-charge",
    name: "Charge",
    dropDownMenu: chargeSetupRoutes,
    authId: id.SETUP_CHARGE_MENU,
  },
  {
    path: "/app/setup/broker",
    name: "Setup Broker",
    component: SetupBroker,
    authId: id.SETUP_BROKER,
  },

  {
    path: "/app/setup/product",
    name: "Setup Product",
    component: SetupProduct,
    authId: id.SETUP_PRODUCT,
  },

  {
    path: "/app/setup/transaction-type",
    name: "Setup Transaction Type",
    component: SetupTransactionType,
    authId: id.SETUP_TRANSACTION_TYPE,
  },
  {
    path: "/app/setup/setup-bank",
    shortPath: "/",
    name: "Setup Bank",
    component: SetupBank,
    authId: id.SETUP_BANK,
  },
  {
    path: "/app/setup/client-broker-mapping",
    name: "Client Broker Mapping",
    component: ClientBrokerMapping,
    authId: id.CLIENT_AND_BROKER_MAPPING,
  },
  {
    path: "/app/setup/setup-company",
    name: "Setup Company",
    component: SetupCompany,
    authId: id.SETUP_COMPANY,
  },
  {
    path: "/app/setup/mero-share-credential",
    name: "Meroshare Credential",
    component: MeroShareCredential,
    authId: id.MERO_SHARE_CREDENTIAL,
  },
  {
    path: "/app/setup/SMTP-config",
    name: "SMTP Configuration",
    component: SetupSMTP,
    authId: id.SETUP_SMTP,
  },
  {
    path: "/app/setup/Official Bank Account",
    name: "Official Bank Account",
    component: OfficialBankAccount,
    authId: id.SETUP_OFFICIAL_BANK_ACCOUNT,
  },
];

export { manageUserRoutes, chargeSetupRoutes };
export default setupRoutes;
