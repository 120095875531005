import React from "react";
// import { Link } from "react-router-dom";
// import errorPng from "assets/images/error.png";
import { Alert } from "react-bootstrap";

const Error = () => {
  return (
    // <Alert variant="danger" onClose={() => setShow(false)} dismissible>
    <Alert variant="danger">
      <Alert.Heading>Oh snap! </Alert.Heading>
      <p>
        Please Reload and try again. If the problem persists, please contact the
        technical team with ways to reproduce the error.
      </p>
    </Alert>
  );
};

export default Error;
