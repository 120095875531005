import {
  PORTFOLIO_ACCOUNT_CREATED,
  PORTFOLIO_ACCOUNT_FETCHED,
  POPULATE_PORTFOLIO_ACCOUNT_LIST,
  FETCH_INITIAL_CHARGE,
  FETCH_PORTFOLIO_PAGES
} from "./portfolioAccountsActionType";

const init = {
  portfolioAccounts: [],
  portfolioAccountList: {}, // This is a minimized version of PortfolioAccounts. could later be removed to only use the above value
  initialDeposit: [],
  portfolioAccountsPages: {}
};
const portfolioAccountsReducer = (state = init, action) => {
  const { type, payload } = action;

  switch (type) {
    case PORTFOLIO_ACCOUNT_CREATED:
      return {
        ...state,
      };
    case PORTFOLIO_ACCOUNT_FETCHED:
      return {
        ...state,
        portfolioAccounts: payload,
      };
    case POPULATE_PORTFOLIO_ACCOUNT_LIST:
      return { ...state, portfolioAccountList: { ...payload } };
    case FETCH_INITIAL_CHARGE:
      return {
        ...state,
        initialDeposit: payload
      }
    case FETCH_PORTFOLIO_PAGES: {
      return {
        ...state,
        portfolioAccountsPages: payload
      }
    }
    default:
      return state;
  }
};

export default portfolioAccountsReducer;
