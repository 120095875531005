import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import FullPageLoader from "app/shared/loaders/FullPageLoader";
import Header from "app/shared/Header";

import Sidebar from "./Sidebar";
import routes from "./routes";

const Kyc = () => {
  return (
    <Suspense fallback={null}>
      <div className="container">
        <Header />
      </div>
      <div className="d-flex" style={{ marginTop: 75 }}>
        <Sidebar drawerOpen={true} />
        <div className="flex-grow-1">
          <div className="container pt-4 grow">
            <Switch>
              {routes.map((e) => {
                return (
                  <Route
                    key={e.path}
                    exact
                    path={e.path}
                    component={e.component}
                  />
                );
              })}

              <Redirect from="/doc" to="/doc/table" />
            </Switch>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default Kyc;
