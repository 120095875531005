import { CHARGE_FETCHED } from "./chargeActionTypes";

const chargeReducer = (
  state = {
    charges: [],
  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case CHARGE_FETCHED:
      return {
        ...state,
        charges: payload,
      };

    default:
      return state;
  }
};

export default chargeReducer;
