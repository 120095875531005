import { ROLE_FETCHED } from "./roleActionTypes";

const init = {
  roles: [],
};
const rolesReducer = (state = init, action) => {
  const { type, payload } = action;

  switch (type) {
    case ROLE_FETCHED:
      return {
        ...state,
        roles: payload.data,
        paginationData: payload.paginationData,
      };
    default:
      return state;
  }
};

export default rolesReducer;
