import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";

import PortfolioAccountSelect from "app/components/PortfolioAccountSelect";
import { useDispatch, useSelector } from "react-redux";
import { getFiscalYearList } from "app/store/fiscalYear/fiscalYearAction";
import CustomSelect from "app/components/customSelect";
import CompanySelect from "app/components/CompanySelect";
import FormInput from "app/components/FormInput";
import { announcementTypeOptions } from "common/selectOptions";
import { fetchSearch } from "api/announcement";
import DatePicker from "app/components/DatePicker";
import { getTodaysDate } from "app/utils";
import { manualAddStock } from "api/announcementShare";
import { toast } from "react-toastify";
import { resetWarningCache } from "prop-types";

const SaveAdjustment = () => {
  React.state = {
    selectedFiscalYear: null,
    selectedCompany: "",
    selectedAnnouncementType: "",
  };

  const { register, handleSubmit, control, errors, setValue, reset } =
    useForm();

  const dispatch = useDispatch();
  let fiscalYear = useSelector((state) => state?.getFiscalYear?.fiscalYear);
  const [fiscalYearValue, setFiscalYearValue] = React.useState(null);
  const [company, setCompany] = React.useState(null);
  const [announcement, setAnnouncement] = React.useState(null);
  const [dividendData, setDividedendData] = useState([]);
  const [errordata, setErrorData] = useState(true);
  const [selectValue, setSelectValue] = useState({
    name: "",
    label: "Select..",
  });
  const [newFiscalYear, setNewFiscalYear] = useState({
    name: "",
    label: "Select..",
  });

  useEffect(() => {
    dispatch(getFiscalYearList());
  }, [dispatch]);
  const optiondata = [
    {
      name: "fiscalYearId",
      options: fiscalYear?.map((x) => {
        return { value: x.id, label: x.name };
      }),
    },
  ];

  // const clientIdData = watch("clientId");
  // console.log({ clientIdData });
  React.useEffect(() => {
    if (fiscalYearValue !== null && company !== null && announcement !== null) {
      fetchSearch({
        // clientId: clientId,
        CompanyId: company?.id,
        AnnouncementType: announcement,
        FiscalYearId: fiscalYearValue,
      }).then((res) => {
        const { contents } = res || {};
        if (contents.length < 1) {
          setErrorData(true);

          toast.error("Announcment Details not available");
          return 0;
        } else if (contents.length > 1) {
          setErrorData(true);

          toast.error("Multiple Anncouncment Data available.");
          return 0;
        } else {
          setErrorData(false);

          setDividedendData(contents[0]);
        }
      });
    }
  }, [fiscalYearValue, company, announcement]);
  // const getAnnouncement = React.useCallback(() => {
  //   dispatch(
  //     fetchAnnouncement({ fiscalYearValue, company, announcement })
  //   ).then((res) => {
  //     setData(res);
  //   });
  // }, [dispatch, fiscalYearValue, company, announcement]);

  const handlePostData = async (data, e) => {
    const postData = [
      {
        portfolioAccountId: data.clientId.portfolioAccountId,
        announcementId: dividendData?.id,
        announcementType: announcement,
        stockQuantity: 0,
        date: data.date,
        stockQuantity: +data.stockQuantity,
      },
    ];
    let values = await dispatch(manualAddStock(postData));
    reset(values);
    // fiscalYear = null;
    setDividedendData(null);
    setAnnouncement(null);
    setCompany(null);
    setSelectValue({ ...selectValue, label: "Select.." });
    setNewFiscalYear({ ...newFiscalYear, label: "Select" });
  };
  //console.log("announcement", announcement);
  return (
    <div>
      <Form onSubmit={handleSubmit(handlePostData)}>
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Group>
              <Form.Label htmlFor="exampleSelectGender">
                Portfolio A/C <span className="text-danger">*</span>
              </Form.Label>
              <PortfolioAccountSelect
                error={errors.clientId}
                control={control}
                name="clientId"
                defaultName="clientId"
                required
                setValue={setValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Group>
              <Form.Label htmlFor="">
                Fiscal Year <span className="text-danger">*</span>
              </Form.Label>
              <CustomSelect
                options={optiondata}
                required
                setValue={setValue}
                onChange={(r) => {
                  setNewFiscalYear({ ...newFiscalYear, label: r.label });
                  return setFiscalYearValue(r.value);
                }}
                value={newFiscalYear}
              />
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Group>
              <CompanySelect
                error={errors.companyId}
                control={control}
                name="companyId"
                required
                setValue={setValue}
                onChange={(r) => setCompany(r)}
              />
            </Form.Group>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Group>
              <Form.Label htmlFor="">
                Announcement Type <span className="text-danger">*</span>
              </Form.Label>
              <CustomSelect
                options={announcementTypeOptions?.filter(
                  (x) => x?.value !== 2 && x?.value !== 3
                )}
                onChange={(r) => {
                  setSelectValue({ ...selectValue, label: r.label });
                  return setAnnouncement(r.value);
                }}
                value={selectValue}
              />
            </Form.Group>
          </div>
          <div className="col-lg-4 col-md-6 col-sm-12">
            <Form.Group>
              <Form.Label htmlFor="">
                Dividend % <span className="text-danger">*</span>
              </Form.Label>
              <span className="form-control">
                {dividendData?.announcementType === 1
                  ? dividendData?.bonusPercent
                  : dividendData?.announcementType === 2
                  ? dividendData?.cashDividendPercent
                  : dividendData?.announcementType === 4
                  ? dividendData?.rightSharePercent
                  : 0}
              </span>
            </Form.Group>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <FormInput
              register={register}
              name={"stockQuantity"}
              label={"Quantity"}
              type="number"
              step="any"
              required
            />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <Controller
              name={"date"}
              as={React.forwardRef(({ onChange, value, name }, ref) => (
                <DatePicker
                  name={name}
                  value={value}
                  onChange={(e) => onChange(e)}
                  ref={ref}
                  label="Date"
                  required
                />
              ))}
              defaultValue={getTodaysDate()}
              control={control}
            />
          </div>
        </div>
        <button
          className="btn btn-primary btn-sm "
          disabled={errordata}
          name="Save"
          type="submit"
        >
          Save
        </button>
      </Form>
    </div>
  );
};

export default SaveAdjustment;
