import { BSToAD, ADToBS } from "bikram-sambat-js";

/**
 * @returns {string} - returns Today's Date in format YYYY-MM-DD
 */
const getTodaysDate = () => {
  const date = new Date();
  return dateFormatter(date);
};

const timeFormatter = (d, format) => {
  const date = new Date(d);

  if (format === "h:mm:ss a") {
    // TODO make dynamic
    const time = {
      h: date.getHours() % 12 || 12,
      mm: String(date.getSeconds()).padStart(2, 0),
      ss: String(date.getSeconds()).padStart(2, 0),
      a: date.getHours() > 12 ? "PM" : "AM",
    };
    return time.h + ":" + time.mm + ":" + time.ss + " " + time.a;
  } else return date;
};

function formatDate(dateStr, format) {
  if (!dateStr) return dateStr;
  try {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, 0);
    const day = String(date.getDate()).padStart(2, 0);

    if (format === "YYYY-MM-DD h:mm:ss a") {
      // TODO make dynamic

      const time = {
        h: date.getHours() % 12 || 12,
        mm: String(date.getSeconds()).padStart(2, 0),
        ss: String(date.getSeconds()).padStart(2, 0),
        a: date.getHours() > 12 ? "PM" : "AM",
      };
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        time.h +
        ":" +
        time.mm +
        ":" +
        time.ss +
        " " +
        time.a
      );
    }
    return year + "-" + month + "-" + day;
  } catch (err) {
    console.error(err);
    return "-";
  }
}

/**
 *
 * @param {object} dateFormat - {year, month date} - how many year/month/date to add  (negative value also allowed)
 * @param {Date} dateStr  - Base Date value from which the offset is calculated. default=Today
 * @returns
 */
function getDateOffset(dateFormat, dateStr) {
  const date = dateStr ? new Date(dateStr) : new Date();
  return new Date(
    date.getFullYear() + +(dateFormat?.year ?? 0),
    date.getMonth() + +(dateFormat?.month ?? 0),
    date.getDate() + +(dateFormat?.date ?? 0)
  );
}

/**
 *Depreacted!! Use fomatDate instead;
 * @param {dateString} dateStr Date String
 * @param {string} format -TODO Date format
 * @param {string} - returns in this YYYY-MM-DD
 */

function dateFormatter(dateStr, format) {
  try {
    const date = dateStr ? new Date(dateStr) : new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, 0);
    const day = String(date.getDate()).padStart(2, 0);

    if (format === "YYYY-MM-DD h:mm:ss a") {
      // TODO make dynamic

      const time = {
        h: date.getHours() % 12 || 12,
        mm: String(date.getMinutes()).padStart(2, 0),
        ss: String(date.getSeconds()).padStart(2, 0),
        a: date.getHours() > 12 ? "PM" : "AM",
      };
      return (
        year +
        "-" +
        month +
        "-" +
        day +
        " " +
        time.h +
        ":" +
        time.mm +
        ":" +
        time.ss +
        " " +
        time.a
      );
    }
    return year + "-" + month + "-" + day;
  } catch (err) {
    console.error(err);
    return "-";
  }
}

const addMonths = (date, noOfMonths) => {
  const startingDate = new Date(date);
  if (startingDate && noOfMonths) {
    return dateFormatter(
      startingDate.setMonth(startingDate.getMonth() + +noOfMonths)
    );
  } else return date;
};
const addDays = (date, noOfDays) => {
  const startingDate = new Date(date);
  if (startingDate && noOfDays) {
    return dateFormatter(
      startingDate.setDate(startingDate.getDate() + +noOfDays)
    );
  } else return date;
};

const noOfDaysTillNextYear = (date) => {
  try {
    const thisDate = new Date(date);
    const nextDate = thisDate.setFullYear(+thisDate.getFullYear() + 1);
    return dateFormatter(nextDate);
  } catch (err) {
    return "-";
  }
};

const noOfDaysBetweenDates = (firstDate, secondDate) => {
  const startingDate = new Date(firstDate);
  const endingDate = new Date(secondDate);

  return (endingDate - startingDate) / 86400000;
};

/**
 * converts 2054-01-25 = 1997-05-07
 * @param{ string} bs- 2054-01-25
 */
const convertToAd = (bs) => {
  try {
    return BSToAD(bs);
  } catch (err) {
    return "-";
  }
};

/**
 * converts 1997-05-07 = 2054-01-25
 * @param {string} ad - 1997-05-07
 */
const convertToBs = (ad, format) => {
  try {
    if (ad) return ADToBS(ad);
    else return "-";
  } catch (err) {}
};

const totalDaysInYear = (year) => {
  return isLeapYear(year) ? 366 : 365;
};

const isLeapYear = (year) => {
  return year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0);
};

export {
  getTodaysDate,
  getDateOffset,
  dateFormatter,
  noOfDaysTillNextYear,
  noOfDaysBetweenDates,
  addMonths,
  convertToAd,
  convertToBs,
  totalDaysInYear,
  addDays,
  timeFormatter,
  formatDate,
};
