import { SECTOR_FETCHED } from "./sectorActionTypes";

const init = {
  sectors: { isEmpty: true },
};
const clientListReducer = (state = init, action) => {
  const { type, payload } = action;

  switch (type) {
    case SECTOR_FETCHED:
      return { ...state, sectors: payload };
    default:
      return state;
  }
};

export default clientListReducer;
