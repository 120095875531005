import { GET_SINGLE_JOINT_KYC, CACHE_KYC } from "./types";

const InitialState = {
  SingleKyc: {},
  cache: {},
};

const jointKycReducer = (state = InitialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_SINGLE_JOINT_KYC:
      return { ...state, SingleKyc: payload };

    case CACHE_KYC:
      return { ...state, cache: payload };
    default:
      return state;
  }
};

export default jointKycReducer;
