import React, { lazy, Suspense } from "react";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import FullPageLoader from "app/shared/loaders/FullPageLoader";
import { useSelector } from "react-redux";

import { useAuth } from "app/authorization";
import setupRoutes, {
  manageUserRoutes,
  chargeSetupRoutes,
} from "./setupRoutes";
import billingRoutes from "./billingRoutes";
import portfolioRoutes, {
  portfolioExpirationRoutes,
  fdRoutes,
} from "./portfolioRoutes";
import reportsRoutes, {
  clientReportRoutes,
  frReportRoutes,
  managementReportRoutes,
  sebonReportRoutes,
} from "./reportsRoutes";

import corporateActionsRoutes from "./corporateActionsRoutes";
import fixedReturnRoutes from "./fixedReturnRoutes";
import chargeRoutes from "./chargeRoutes";
import clientLoginRoutes from "./clientLoginRoutes";
import orderManagementRoutes from "./orderManagementRoutes";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import meroShareRoutes from "./meroShareRoutes";

const Dashboard = lazy(() => import("app/pages/Dashboard"));
const Converter = lazy(() => import("app/pages/XlsxConverter/Converter"));
const MyPortfolio = lazy(() => import("app/pages/Dashboard/MyPortfolio"));
const ProfitLoss = lazy(() => import("app/pages/Dashboard/ProfitLoss"));
const ManageClient = lazy(() => import("app/pages/ManageClient"));

const ChangePassword = lazy(() => import("app/pages/Profile/ChangePassword"));
const Account = lazy(() => import("app/pages/Profile/Account"));

const DASHBOARD = "DASHBOARD";

export const routes = [
  {
    path: "/app/dashboard",
    name: "Dashboard",
    component: Dashboard,
    authId: DASHBOARD,
    icon: "mdi mdi-gauge",
    hideInSidebar: true,
  },
  {
    path: "/app/dashboard/:id",
    name: "Dashboard",
    component: Dashboard,
    authId: DASHBOARD,
    icon: "mdi mdi-gauge",
    hideInSidebar: true,
  },
  {
    path: "/app/setup",
    name: "Setup",
    dropDownMenu: setupRoutes,
    authId: "SETUP",
    icon: "mdi mdi-cogs",
  },
  {
    path: "/app/meroshare",
    name: "Meroshare Automation",
    dropDownMenu: meroShareRoutes,
    authId: "MEROSHARE",
    icon: "mdi mdi-finance",
  },
  {
    path: "/app/manage-client",
    name: "Manage Client",
    component: ManageClient,
    authId: "CLIENT_MANAGEMENT",
    icon: "mdi mdi-account-plus",
  },

  {
    path: "/app/order-management",
    name: "Order Mgmt",
    dropDownMenu: orderManagementRoutes,
    authId: "ORDER_MANAGEMENT",
    icon: "mdi mdi-contacts",
  },

  {
    path: "/app/portfolio",
    name: "Portfolio",
    dropDownMenu: portfolioRoutes,
    authId: "PORTFOLIO",
    icon: "mdi mdi-contacts",
  },

  {
    path: "/app/Billing",
    name: "Billing",
    dropDownMenu: billingRoutes,
    authId: "BILLING",
    icon: "mdi mdi-cash-100",
  },
  {
    path: "/app/accounting",
    name: "Accounting",
    authId: "ACCOUNTING",
    icon: "mdi mdi-calculator-variant",
  },
  {
    path: "/app/corporate-action",
    name: "Corporate Actions",
    dropDownMenu: corporateActionsRoutes,
    authId: "CORPORATE_ACTIONS",
    icon: "mdi mdi-settings",
  },
  {
    path: "/app/fix-return",
    name: "FR",
    dropDownMenu: fixedReturnRoutes,
    authId: "FIXED_RETURN",
    icon: "mdi mdi-bank",
  },

  {
    path: "/app/charges",
    name: "Charge",
    dropDownMenu: chargeRoutes,
    authId: "CHARGE",
    icon: "mdi mdi-cash",
  },
  {
    path: "/app/reports",
    name: "Reports",
    dropDownMenu: reportsRoutes,
    authId: "REPORTS",
    icon: "mdi mdi-chart-areaspline",
  },
  {
    path: "/app/excel-converter",
    name: "Excel Converter",
    authId: "DATA_ANALYTICS",
    component: Converter,
  },
  {
    path: "/app/data-analytics",
    name: "Data Analytics",
    goTo:
      "https://merolagani.com/dalogin.aspx?userid=107542&useremail=admin@test.com",
    authId: "DATA_ANALYTICS",
    icon: "mdi mdi-account-plus",
  },
];

export const useRoutes = (props) => {
  const { location } = useHistory();

  // const approvalRoutes = useApprovalRoutes();
  const { profile } = useSelector((state) => state.getAuth);

  const isClientLogin = profile.roleName === "Client";
  if (isClientLogin) return [...routes, ...clientLoginRoutes];

  // const viewLikeClient =
  //   profile.roleName === "Super Admin" &&
  //   location.pathname.includes("/app/dashboard/");

  // if (viewLikeClient) return [...clientLoginRoutes];

  return [...routes];
};

const allRoutes = [
  ...routes.filter((e) => !e.dropDownMenu),
  ...billingRoutes,
  ...clientReportRoutes,
  ...managementReportRoutes,
  ...sebonReportRoutes,
  ...frReportRoutes,
  ...reportsRoutes,

  ...portfolioRoutes,
  ...portfolioExpirationRoutes,
  ...fdRoutes,

  ...corporateActionsRoutes,
  ...manageUserRoutes,
  ...chargeSetupRoutes,
  ...setupRoutes,
  // ...fixedDepositRoutes,
  ...chargeRoutes,
  ...clientLoginRoutes,
  ...orderManagementRoutes,
  // ...uploadRoutes,
  // ...clientRegistrationRoutes,

  {
    path: "/app/profile/change-password",
    name: "Change Password",
    exact: true,
    component: ChangePassword,
    authId: "CHANGE_PASSWORD",
    hideInSidebar: true,
  },
  {
    path: "/app/profile/account",
    name: "Manage Account",
    exact: true,
    component: Account,
    authId: "CHANGE_PASSWORD",
    hideInSidebar: true,
  },
  ...fixedReturnRoutes,
  ...meroShareRoutes,
];

const Routes = () => {
  const { isAuthorized, checkAuth } = useAuth();

  const history = useHistory();
  React.useEffect(() => {
    const listenerHandler = () => {
      //CHECK Authorization here
    };
    const unListen = history.listen(listenerHandler);

    return () => unListen();
  }, [history]);
  return (
    <Suspense fallback={<FullPageLoader />}>
      <Switch>
        <Route
          exact
          path="/app/manage-client"
          component={() => <ManageClient authId={"CLIENT_MANAGEMENT"} />}
        />
        <Route
          exact
          path="/app/dashboard"
          component={() => <Dashboard authId={"DASHBOARD"} />}
        />
        <Route
          exact
          path="/app/profit-loss"
          component={() => <ProfitLoss authId={"DASHBOARD"} />}
        />
        <Route
          exact
          path="/app/my-portfolio"
          component={() => <MyPortfolio authId={"DASHBOARD"} />}
        />
        <Route
          exact
          path="/app/profile/change-password"
          component={ChangePassword}
        />
        <Route exact path="/app/profile/account" component={Account} />

        {allRoutes.map((e) => {
          if (
            e.authId &&
            isAuthorized({ authId: e.authId, componentId: e.componentId }) &&
            e.component
          ) {
            return (
              <Route
                key={e.path}
                exact
                path={e.path}
                // component={e.component}
                component={checkAuth(e.component, e.authId, e.componentId)}
              />
            );
          } else return null;
        })}
        <Redirect to="/app/dashboard" />
      </Switch>
    </Suspense>
  );
};

export default Routes;

// {
//   path: "/app/approval",
//   name: "Approval",
//   dropDownState: "approvalState",
//   dropDownMenu: approvalRoutes,
//   authId: "CORPORATE_ACTIONS",
//   icon: "mdi mdi-settings",
// },
