import React, { useState,useEffect } from "react";
import { Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { sortOptionByLabel } from "app/utils";
import CustomSelect from "app/components/customSelect";

import { searchCompany } from "api/company";
import MultiSelectProps from "../MultiSelectProps";

const dataFormatter = (data) =>
  data?.map((e) => {
    return { ...e, value: e.id, label: `${e.name}(${e.stockSymbol})` };
  });

const CompanySelect = ({
  onChange: onChangeFromProps,
  value,
  control,
  name,
  setValue,
  error,
  defaultValue,
  defaultName,
  required,
  label,
  isMulti,

  ...otherProps
}) => {
  const dispatch = useDispatch();
  //TODO
  const [data, setData] = useState();
  const [companyOption, setCompanyOption] = useState();

  const promiseOptions = (inputValue) => {
    const islengthMultipleOfThree = !(inputValue.length % 1);

    if (inputValue.length === 1 || islengthMultipleOfThree) {
      return new Promise((resolve) => {
        dispatch(searchCompany({ name: inputValue })).then((res) => {
          setData(sortOptionByLabel(dataFormatter(res)));
          resolve(sortOptionByLabel(dataFormatter(res)));
        });
      });
    }
    return new Promise((resolve) => {
      resolve(
        data?.filter((e) => {
          const regex = new RegExp(inputValue, "gi");
          return regex.test(e.name) || regex.test(e.stockSymbol);
        })
      );
    });
  };

  useEffect(() => {
    dispatch(searchCompany()).then((res) => {
      const new_options = res.map((x) => ({
        ...x,
        value: x.id,
        label: `${x.name}`,
      }));

      setCompanyOption(new_options);
    });
  }, [dispatch]);
  // useEffect(() => {
  //   if (setValue && defaultValue)
  //     if (defaultName) {
  //       const temp = promiseOptions?.find(
  //         (e) => e[defaultName] === defaultValue
  //       );
  //       setValue(name, temp);
  //     } else {
  //       const temp = promiseOptions?.find((e) => e.value === defaultValue);
  //       setValue(name, temp);
  //     }
  // }, [promiseOptions, defaultValue, defaultName, name, setValue]);
  // useEffect(() => {
  //   if (defaultValue && setValue) {
  //   }
  // }, [defaultValue]);

  return (
    <Form.Group>
      <Form.Label htmlFor="exampleSelectGender">
        {label || "Company"}
        {required && <span className="text-danger">*</span>}
      </Form.Label>
      <Controller
        control={control}
        name={name || "companyId"}
        render={({ onChange, ...rest }) => (
          <CustomSelect
            onChange={(e) => {
              onChangeFromProps && onChangeFromProps(e);
              onChange(e);
              
            }}
            async
            name={name || "companyId"}
            loadOptions={promiseOptions}
            // defaultValue={
            //   companyOption?.find((e) => e.value === defaultValue) ?? null
            // }            
            {...(isMulti && MultiSelectProps)}
            className={error && "select_error"}
            {...rest}
          />
        )}
        rules={{ required }}
        defaultValue={
          companyOption?.find((e) => e.value === defaultValue) ?? null
        }  
        {...(isMulti && MultiSelectProps)}
        {...otherProps}
      />
     
      {/* {error && <div className="error-msg">{error}</div>} */}
    </Form.Group>
  );
};
export default CompanySelect;
