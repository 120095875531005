import { addMessage } from "actions/message/message";
import axiosRequest from "app/utils/axiosRequest";
import jwt from "jwt-decode";

import { STORE_USER_SESSION, USER_LOGGED_OUT } from "./authActionType";

const ENDPOINT_USER_LOGIN = "users/VerificationCode";
const ENDPOINT_VERIFY_CODE = "users/Login";

//login
export const login = (loginName, password) => (dispatch) => {
  return axiosRequest
    .post(ENDPOINT_USER_LOGIN, { loginName, password })
    .then((res) => {
      // if (!res?.data?.success) {
      //   dispatch(
      //     addMessage({
      //       type: `error`,
      //       data: "Invalid username or password",
      //       titile: ``,
      //     })
      //   );
      // }
      dispatch(
        addMessage({
          type: `success`,
          data: res?.data?.message,
          title: ``,
        })
      );
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      const { data } = err?.response || {};
      dispatch(
        addMessage({
          type: `error`,
          data: data?.error?.message || `Invalid username or password`,
          title: ``,
        })
      );
      return Promise.reject("Invalid username or password");
    });
};

export const performUserLogoutAction = (dispatch) => {
  dispatch({ type: "ACTIVATE_LOADING" });
  localStorage.clear();
  dispatch({ type: USER_LOGGED_OUT });
  dispatch({ type: "DEACTIVATE_LOADING" });
};

const NAME_IDENTIFIER =
  "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier";
export const getUserDetails = (accessToken, refreshToken) => async (
  dispatch
) => {
  const decoded = jwt(accessToken);
  return await axiosRequest
    .get(`Users/GetUserDetails/${decoded[NAME_IDENTIFIER]}`)
    .then((res) => {
      
      dispatch({ type: "USER_PROFILE_FETCHED", payload: res.data });
      dispatch({
        type: "STORE_USER_SESSION",
        payload: { accessToken, refreshToken },
      });
    })
    .catch((err) => {
      return Promise.reject("Please check your credentials and try again");
    });
};

export const saveAccessToken = (accessToken, refreshToken) => async (
  dispatch
) => {
  dispatch({
    type: "STORE_USER_SESSION",
    payload: { accessToken, refreshToken },
  });
};

export const apIverifyCode = (loginData) => (dispatch) => {
  return axiosRequest
    .post(ENDPOINT_VERIFY_CODE, loginData)
    .then((res) => {
      if (res.data) {
        const { accessToken, refreshToken } = res.data;
        // localStorage.setItem("pmsToken", sessionToken);

        dispatch({ type: STORE_USER_SESSION, payload: res.data });
        dispatch(getUserDetails(accessToken, refreshToken));
        return Promise.resolve(res.data);
      } else {
        dispatch(
          addMessage({
            type: `error`,
            data: `Invalid Verification code`,
            title: ``,
          })
        );
        return Promise.reject("ERROR");
      }
    })
    .catch((err) => {
      const { errors, Otp } = err?.response?.data;
      dispatch(
        addMessage({
          type: `error`,
          data: errors?.Otp[0] || Otp[0] || `Invalid Verification code`,
          title: ``,
        })
      );
      return Promise.reject("Network or Server Error");
    });
};
