import { lazy } from "react";
import * as id from "app/authorization/authId";

const PortfolioTransaction = lazy(() =>
  import("app/pages/Portfolio/PortfolioTransaction")
);
const PortfolioCreation = lazy(() =>
  import("app/pages/Portfolio/PortfolioCreation")
);
const AdditionalInvestment = lazy(() =>
  import("app/pages/Portfolio/AdditionalInvestment")
);

const FDAccountList = lazy(() =>
  import("app/pages/Portfolio/PortfolioCreation/ViewFDAccountList")
);

const PortfolioExpiration = lazy(() =>
  import("app/pages/Portfolio/PortfolioExpiration")
);
const RenewedExtendedList = lazy(() =>
  import("app/pages/Portfolio/RenewedExtendedList")
);

const portfolioExpirationRoutes = [
  {
    path: "/app/portfolio/expiration",
    name: "Expiration",
    component: PortfolioExpiration,
    authId: id.PORTFOLIO_EXPIRATION_LIST,
  },
  {
    path: "/app/portfolio/renewed-slash-extended-list",
    name: "Renewed/Extended List",
    component: RenewedExtendedList,
    authId: id.RENEW_EXTEND,
  },
];

const FDApproval = lazy(() => import("app/pages/Portfolio/FD/FDApproval"));
const FdInterest = lazy(() => import("app/pages/Portfolio/FD/FdInterest"));

const fdRoutes = [
  {
    path: "/app/portfolio/fd-approval",
    name: "FD Approval",
    exact: true,
    component: FDApproval,
    authId: id.FD_APPROVAL,
  },
  {
    path: "/app/portfolio/fd-interest",
    name: "FD Interest",
    exact: true,
    component: FdInterest,
    authId: id.FD_INTEREST,
  },
];
//routes
const portfolioRoutes = [
  {
    path: "/app/portfolio/portfolio-creation",
    name: "Portfolio Creation",
    component: PortfolioCreation,
    authId: id.PORTFOLIO_CREATION,
  },

  // {
  //   path: "/app/portfolio/portfolio-transaction",
  //   name: "Portfolio Transaction",
  //   component: PortfolioTransaction,
  //   authId: id.PORTFOLIO_TRANSACTION,
  // },
  {
    path: "/app/portfolio/additional-investment",
    name: "Additional Investment",
    component: AdditionalInvestment,
    authId: id.ADDITIONAL_INVESTMENT,
  },
  {
    path: "/app/portfolio/portfolio-expiration",
    name: "Portfolio Expiration",
    authId: id.PORTFOLIO_EXPIRATION_MENU,
    dropDownMenu: portfolioExpirationRoutes,
  },
  {
    path: "/app/portfolio/fd",
    name: "FD",
    dropDownMenu: fdRoutes,
    authId: id.FD_MENU,
  },
  {
    path: "/app/portfolio/portfolio-creation/fd-account-list", // navigates form portfolio/Approved
    name: "Fixed Deposit Account List",
    exact: true,
    component: FDAccountList,
    authId: id.FR_ACCOUNT,
    componentId: "CREATE",
    hideInSidebar: true,
  },
];

export default portfolioRoutes;
export { portfolioExpirationRoutes, fdRoutes };
