import {
  USER_PROFILE_FETCHED,
  FETCH_USER,
  STORE_USER_SESSION,
  USER_LOGGED_OUT,
  STORE_USER_PERMISSION,
} from "./authActionType";
import jwt from "jwt-decode";
// import Cookies from "js-cookie";

const init = {
  isLogged: null,
  profile: {},
  users: [],
  loginName: "",
  permissions: {},
  refUserId: null,
};
const AuthReducer = (state = init, action) => {
  const { type, payload } = action;
  let newState = {};
  switch (type) {
    case USER_PROFILE_FETCHED:
      return { ...state, profile: payload };
    case STORE_USER_SESSION:
      const { accessToken, refreshToken } = payload;

      localStorage.setItem("_refresh", refreshToken);
      localStorage.setItem("pmsToken", accessToken);
      const decoded = jwt(accessToken);
      localStorage.setItem("expires_on", decoded.exp * 1000);
      // Cookies.set("pms_token", accessToken);

      const applicationUserId = decoded.ApplicationUserId;
      const refUserId = decoded.RefUserId;
      const loginName = decoded.LoginName;
      const nameIdentifier =
        decoded[
          "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
        ];
      // const loginName = decoded.LoginName;
      // const iss = decoded.iss;
      // const jti = decoded.jti;

      //All this key on decoded data is supposed to be predefined. so useless fields are deleted as backend was unable to send a fomatted data
      delete decoded[
        "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
      ];
      delete decoded.LoginName;
      delete decoded.iss;
      delete decoded.iss;
      delete decoded.jti;
      delete decoded.ApplicationUserId;
      delete decoded.RefUserId;
      //When only one permission is allowed whether Create or update or read or delete then string is sent by backend
      //So converting that string to array

      const formattedAccessRights = Object.entries(decoded).reduce(
        (obj, curr) => {
          if (curr[0] === "CLIENT_DASHBOARD") {
            return { ...obj, DASHBOARD: ["CLIENT"] };
          }
          return {
            ...obj,
            [curr[0]?.trim()]: Array.isArray(curr[1]) ? curr[1] : [curr[1]],
          };
        },
        {}
      );

      return {
        ...state,
        isLogged: true,
        applicationUserId,
        permissions: formattedAccessRights,
        refUserId,
        loginName,
        nameIdentifier,
      };

    case STORE_USER_PERMISSION:
      //When only one permission is allowed whether Create or update or read or delete then string is sent by backend
      //So converting that string to array

      const formattedAccessPermissions = Object.entries(payload).reduce(
        (obj, curr) => {
          if (curr[0] === "CLIENT_DASHBOARD") {
            return { ...obj, DASHBOARD: ["CLIENT"] };
          }
          return {
            ...obj,
            [curr[0]?.trim()]: Array.isArray(curr[1]) ? curr[1] : [curr[1]],
          };
        },
        {}
      );

      return { ...state, permissions: formattedAccessPermissions };
    case USER_LOGGED_OUT:
      return { ...init, isLogged: false };
    case FETCH_USER:
      return newState;
    default:
      return state;
  }
};

export default AuthReducer;
