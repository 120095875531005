import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const PageNotFound = () => {
  return (
    <Container>
      <Row
        className="justify-content-md-center align-items-center"
        style={{ height: "80vh" }}
      >
        <Row>
          <Col md={12}>
            <h1
              className="font-weight-bold text-center"
              style={{ fontSize: "120px" }}
            >
              404
            </h1>
            <h4 className="text-center">Page Not Found</h4>
            <h6 className="text-center">
              The page you are looking for doesn't exist or an other error
              occured.Go to{" "}
              <Link
                to="/"
                style={{ textDecoration: "underline", color: "#45976a" }}
              >
                Home Page
              </Link>
            </h6>
          </Col>
        </Row>
      </Row>
    </Container>
  );
};

export default PageNotFound;
