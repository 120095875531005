import React, { Component } from "react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import './toast.css'

class ToastEmitter extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    let { message } = this.props;
    if (message && prevProps.message !== message) {

        toast(message.message.data, {
          type: message.message.type,
          autoClose: message.message.type === "error" ? 7000 : 5000,
          className:message.message.type=='success' ?'toastsuccess':'toasterror'
         
        });
    
     
    }
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }
}

function mapStateToProps(state) {
  let { message } = state;
  return {
    message,
  };
}

export default connect(mapStateToProps)(ToastEmitter);
