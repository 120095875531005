import { DateIndividualGetter } from "../DynamicDataTable/DateComponents";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import RemoveIcon from "@material-ui/icons/Remove";

const convertDateTime = (value) => {
  if (value) {
    let date = convertDate(value);
    let dateObject = new Date(value);
    let time = `${dateObject.getHours()}:${dateObject.getMinutes()}:${dateObject.getSeconds()}`;
    return `${date}, ${time}`;
  } else {
    return "";
  }
};
const convertDate = (dateValue) => {
  if (dateValue) {
    let dateObject = new Date(dateValue);
    // 7/31/2020
    let todayYear = dateObject.getFullYear();

    let todayMonth = () => {
      let currentMonth = (dateObject.getMonth() + 1).toString();
      if (currentMonth.length < 2) {
        return "0" + currentMonth;
      } else {
        return currentMonth;
      }
    };
    let todayDay = () => {
      if (dateObject.getDate().toString().length < 2) {
        return "0" + dateObject.getDate().toString();
      } else {
        return dateObject.getDate().toString();
      }
    };
    let stringDate =
      todayYear.toString() + "/" + todayMonth() + "/" + todayDay();
    return stringDate;
  } else {
    return "";
  }
};
export const TypeConvert = (value, type) => {
  switch (type) {
    case "date":
      return convertDate(value) ? convertDate(value) : "";
    case "float":
      return parseFloat(value).toFixed(2) ? parseFloat(value).toFixed(2) : "";
    case "monthYear":
      return new DateIndividualGetter(value).getCurrentMonth()
        ? `${new DateIndividualGetter(
            value
          ).getTodayYear()}-${new DateIndividualGetter(
            value
          ).getCurrentMonth()}`
        : "";
    case "boolean":
      return value ? (
        <CheckIcon fontSize="small" />
      ) : (
        <RemoveIcon fontSize="small" />
      );
    case "datetime":
      return convertDateTime(value);
    default:
      return value;
  }
};
