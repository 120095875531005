import { addMessage, axiosRequest, errorHandler } from "app/utils";
import axiosQuery from "app/utils/axiosQuery";
const ENDPOINT = "/fixedDepositAccount";

export const fetchFdAccountApi = ENDPOINT;
const ENDPOINT_APPROVE_FR = "/FixedDepositAccount/Approve";
const ENDPOINT_MANAGEMENT_REPORT = "/FixedDepositAccount/GetManagementReport";
const ENDPOINT_AUM_CHART = "/FixedDepositAccount/GetAumChart";

export const fetchFDAccount = (params) => async (dispatch) => {
  return await axiosRequest
    .get(ENDPOINT, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      } else {
        dispatch(addMessage({ type: "error", data: "Data Not Found" }));
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const performfrApprove = (data) => async (dispatch) => {
  //console.log(data);
  return axiosRequest
    .patch(ENDPOINT_APPROVE_FR, data)
    .then((res) => {
      dispatch(
        addMessage({
          type: `success`,
          data: "FR Acount Approved",
          title: ``,
        })
      );
      return Promise.resolve(true);
    })
    .catch((err) => {
      dispatch(addMessage(errorHandler(err)));
      return Promise.reject("ERROR");
    });
};
export const fetchIpsFundTransfer = (id) => (dispatch) => {
  return axiosRequest
    .get(`${ENDPOINT}/ipsFundTransfer/${id}`)
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const fetchFrReceipt = (params) => (dispatch) => {
  return axiosRequest
    .get(`${ENDPOINT}/getFRReceipt`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const fetchFrClientDetails = `${ENDPOINT}/FRClientDetails`;

export const fetchFdMemo = (params) => (dispatch) => {
  return axiosRequest
    .get(`${ENDPOINT}/getMemoReport`, { params })

    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
    });
};

export const fetchBfiReport = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/getBfiReport`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const deleteFixedDepositAccount = (id, remarks) => (dispatch) => {
  return axiosRequest
    .delete(`${ENDPOINT}/${id}`, {
      params: { id, deleteRemarks: remarks },
    })
    .then((res) => {
      if (res.data) {
        dispatch(
          addMessage({
            type: `success`,
            data: "Successfully Deleted",
            title: ``,
          })
        );
        return Promise.resolve(res.data);
      } else {
        return Promise.reject("ERROR");
      }
    })
    .catch((err) => {
      dispatch(addMessage(errorHandler(err, "chargeRate")));
      return Promise.reject("ERROR");
    });
};

export const deleteInterest = (id) => (dispatch) => {
  return axiosRequest
    .patch(`${ENDPOINT}/DeleteInterest`, id)
    .then((res) => {
      if (res.data) {
        dispatch(
          addMessage({
            type: `success`,
            data: "Successfully Deleted",
            title: ``,
          })
        );
        return Promise.resolve(res.data);
      } else {
        return Promise.reject("ERROR");
      }
    })
    .catch((err) => {
      dispatch(addMessage(errorHandler(err, "chargeRate")));
      return Promise.reject("ERROR");
    });
};

export const rejectInterest = (id) => (dispatch) => {
  return axiosRequest
    .patch(`${ENDPOINT}/RejectInterest`, id)
    .then((res) => {
      if (res.data) {
        dispatch(
          addMessage({
            type: `success`,
            data: "Successfully Deleted",
            title: ``,
          })
        );
        return Promise.resolve(res.data);
      } else {
        return Promise.reject("ERROR");
      }
    })
    .catch((err) => {
      dispatch(addMessage(errorHandler(err, "chargeRate")));
      return Promise.reject("ERROR");
    });
};

export const fetchExpirationReport = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/getExpirationReport`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const fetchFdInterestDetails = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/getFDInterestDetailsSolo`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      } else {
        dispatch(addMessage({ type: "error", data: "Data Not Found" }));
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};
export const fetchAllFdInterestDetails = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/GetFDInterestCalculationSummary`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      } else {
        dispatch(addMessage({ type: "error", data: "Data Not Found" }));
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const fetchIncomeReportDetails = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/GetProfitLossReport`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      } else {
        dispatch(addMessage({ type: "error", data: "Data not found" }));
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const fetchAllFdbficlientInterestDetails = (params) => async (
  dispatch
) => {
  return await axiosRequest
    .get(`${ENDPOINT}/GetClientFDInterestCalculationByBFIBank`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      } else {
        dispatch(addMessage({ type: "error", data: "Data Not Found" }));
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};
export const fetchMemoBfis = (data) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/GetMemoBfis`, {
      params: {
        BfiiBankAccountIds: data.BfiiBankAccountIds,
        FiscalYearId: data.fiscalYearId,
        fiscalPaymentPeriod: data.fiscalPaymentPeriod,
      },
    })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      } else {
        dispatch(
          addMessage({
            type: `error`,
            data: "No Data Available",
            title: ``,
          })
        );
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const fetchFRAccountData = (id) => (dispatch) => {
  return axiosRequest
    .get(`${ENDPOINT}/${id}`)
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export const createFRAccount = (data) => (dispatch) => {
  return axiosRequest
    .post(ENDPOINT, data)
    .then((res) => {
      if (res.data) {
        dispatch(
          addMessage({
            type: `success`,
            data: "FR Account created successfully",
            title: ``,
          })
        );
        return Promise.resolve(res.data);
      } else {
        return Promise.reject("ERROR");
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject("ERROR");
    });
};

export const editFRAccount = (id, data) => (dispatch) => {
  return axiosRequest
    .put(`${ENDPOINT}/${id}`, { id, ...data })
    .then((res) => {
      if (res.data) {
        dispatch(
          addMessage({
            type: `success`,
            data: "FR Account edited successfully",
            title: ``,
          })
        );
        return Promise.resolve(res.data);
      } else {
        return Promise.reject("ERROR");
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject("ERROR");
    });
};

export const fetchFrQuaterlyPaymantReports = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT}/FrQuaterlyPaymantReports`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      } else {
        dispatch(addMessage({ type: "error", data: "Data Not Found" }));
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

// Exit ,Partial Exit and Renew Service starts here

export const createRenewAccount = (data) => (dispatch) => {
  return axiosRequest
    .post(`FixedDepositAccount/RenewFRAccount`, data)
    .then((res) => {
      if (res.data) {
        dispatch(
          addMessage({
            type: `success`,
            data: "FR Account created successfully",
            title: ``,
          })
        );
        return Promise.resolve(res.data);
      } else {
        return Promise.reject("ERROR");
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject("ERROR");
    });
};

export const partialExit = (data) => (dispatch) => {
  return axiosRequest
    .post(`FixedDepositAccount/PartialFRAccount`, data)
    .then((res) => {
      if (res.data) {
        dispatch(
          addMessage({
            type: `success`,
            data: "FR Partial Exit Successfull",
            title: ``,
          })
        );
        return Promise.resolve(res.data);
      } else {
        return Promise.reject("ERROR");
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject("ERROR");
    });
};
export const frExit = (data) => (dispatch) => {
  return axiosRequest
    .post(`FixedDepositAccount/ExitFR`, data)
    .then((res) => {
      if (res.data) {
        dispatch(
          addMessage({
            type: `success`,
            data: "FR Exit Successfull",
            title: ``,
          })
        );
        return Promise.resolve(res.data);
      } else {
        return Promise.reject("ERROR");
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject("ERROR");
    });
};

export function fetchPartialExitData(id) {
  return (dispatch) =>
    axiosRequest
      .get(`FixedDepositAccount/GetExitDatainfo/${id}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.reject(err);
      });
}

export function fetchExitInterest(id, exitDate) {
  return (dispatch) =>
    axiosQuery
      .get(`FixedDepositAccount/GetExitInterest/${id}?Exitdate=${exitDate}`)
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.reject(err);
      });
}

export const getManagementReport = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT_MANAGEMENT_REPORT}`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      } else {
        dispatch(addMessage({ type: "error", data: "Data Not Found" }));
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};
export const getAumChart = (params) => async (dispatch) => {
  return await axiosRequest
    .get(`${ENDPOINT_AUM_CHART}`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      } else {
        dispatch(addMessage({ type: "error", data: "Data Not Found" }));
      }
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};
