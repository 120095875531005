import { addMessage, axiosRequest, errorHandler } from "app/utils";

const ENDPOINT = "Announcement";

export const postTransaction = (id, date) => async (dispatch) => {
  return await axiosRequest
    .put(`${ENDPOINT}/AllotedResult/${id}`, {
      
      ResultDate: date,
    })
    .then((res) => {
      dispatch(
        addMessage({
          type: `success`,
          data: `Transaction updated Successfully`,
          title: ``,
        })
      );

      return Promise.resolve(res.data);
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};
export const updateTransaction = (id, date) => async (dispatch) => {
  return await axiosRequest
    .post(`${ENDPOINT}/TransactionPost`, {
      id,
      transactionPostDate: date,
    })
    .then((res) => {
      dispatch(
        addMessage({
          type: `success`,
          data: `Transaction updated Successfully`,
          title: ``,
        })
      );

      return Promise.resolve(res.data);
    })
    .catch((error) => {
      dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};

export function getAnnouncementBonusDetails(id, params, portfolioAccountId) {

  return (dispatch) => {
    return axiosRequest
      .get(`${ENDPOINT}/AnnouncementShare/${id}`, {
        params,
        ...portfolioAccountId,
      })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((error) => {
        dispatch(addMessage(errorHandler(error)));
        return Promise.reject(error);
      });
  };
}

export function createAnnouncement(data) {
  return (dispatch) =>
    axiosRequest
      .post(ENDPOINT, data)
      .then((res) => {
        dispatch(
          addMessage({
            type: `success`,
            data: "Annoucement created successfully",
            title: ``,
          })
        );
        return Promise.resolve(res);
      })
      .catch((error) => {
        dispatch(addMessage(errorHandler(error)));
        return Promise.reject(error);
      });
}

export function editAnnouncement(id, data) {
  return (dispatch) =>
    axiosRequest
      .put(`${ENDPOINT}/${id}`, data)
      .then((res) => {
        dispatch(
          addMessage({
            type: `success`,
            data: "Annoucement Edited successfully",
            title: ``,
          })
        );
        return Promise.resolve(res);
      })
      .catch((error) => {
        dispatch(addMessage(errorHandler(error)));
        return Promise.reject(error);
      });
}

export function deleteAnnouncement(id, data) {
  let isSure = window.confirm("Are you sure?");
  if (isSure)
    return (dispatch) =>
      axiosRequest
        .delete(`${ENDPOINT}/${id}`, data)
        .then((res) => {
          dispatch(
            addMessage({
              type: `success`,
              data: "Annoucement Deleted successfully",
              title: ``,
            })
          );
          return Promise.resolve(res);
        })
        .catch((error) => {
          dispatch(addMessage(errorHandler(error)));
          return Promise.reject(error);
        });
}

export function fetchAnnouncement(data = {}) {
  return (dispatch) =>
    axiosRequest
      .get(`${ENDPOINT}/search`, { params: data })
      .then((res) => {
        return Promise.resolve(res.data);
      })
      .catch((err) => {
        dispatch(addMessage(errorHandler(err)));
        return Promise.reject(err);
      });
}
export const fetchSearch = async (params) => {
  return await axiosRequest
    .get(`${ENDPOINT}/search`, { params })
    .then((res) => {
      if (res.data) {
        return Promise.resolve(res.data);
      }
    })
    .catch((error) => {
      //   dispatch(addMessage(errorHandler(error)));
      return Promise.reject(error);
    });
};
