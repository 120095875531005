import React, { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addMessage } from "app/utils";
import "bootstrap/dist/css/bootstrap.min.css";
import ToastEmitter from "app/shared/Toasts/ToastEmitter";
import FullPageLoader from "app/shared/loaders/FullPageLoader";
import Kyc from "app/pages/kyc/";
import Login from "app/pages/Login";
import documentation from "Documentation";
import NotFound from "./pages/ErrorPage/PageNotFound";

import { getUserDetails } from "app/store/auth/authAction";
import { toast } from "react-toastify";
import Navbar from "app/components/Navbar";
import BreadcrumbHeader from "app/components/BreadcrumbHeader";

import AppRoutes from "app/routes";

import { ProtectedApp } from "./ProtectedRoutes";
import jwt from "jwt-decode";
import ErrorModal from "./components/CollapsableModal";
import ErrorPage from "app/pages/ErrorPage/";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import Footer from "./components/Footer/footer";

const AdminRoutes = () => {
  return (
    <React.Fragment>
      <Navbar />
      <FullPageLoader style={{ top: 72 }} />
      <div className="px-4 pt-3" style={{ marginTop: 75 }}>
        <BreadcrumbHeader />
        <AppRoutes />
      </div>
    </React.Fragment>
  );
};
const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    const accessToken = localStorage.getItem("pmsToken");

    if (accessToken) {
      const decoded = jwt(accessToken);
      const exp = decoded.exp;
      const now = new Date(exp * 1000);
      const naw = new Date();
      // const noo = new Date();

      // debugger;

      if (now < naw.getTime()) {
        // alert("the session have expired ");
        // dispatch(
        //   addMessage({
        //     type: `success`,
        //     data: "The Session have expired.",
        //     title: ``,
        //   })
        // );
      }
    }
    //Devtools Warning
    console.log(
      "%cStop!",
      "color:red; font-size:60px; font-weight: bold; -webkit-text-stroke: 1px black;"
    );
    console.log(
      "%cThis is a browser feature intended for developers. Please dont share any information from here.",
      "font-size: 18px;color:red;"
    );

    //preloading image before

    const img = new Image();
    img.src = require("../assets/images/sidebarLogo.png");
  }, []);

  const { isLogged } = useSelector((state) => state.getAuth);
  const auth = useSelector((state) => state.getAuth);
  const roleName = auth.profile?.roleName;

  const errorModalState = useSelector((state) => state.errorModalState);

  useEffect(() => {
    const token = localStorage.getItem("pmsToken");
    const refreshToken = localStorage.getItem("_refresh");
    if (token) {
      dispatch(getUserDetails(token, refreshToken));
    } else dispatch({ type: "USER_LOGGED_OUT" });
  }, [dispatch]);
  return (
    <div style={{ overflow: "hidden" }} className="App">
      <Switch>
        <Footer>
          <Route
            exact
            path="/login"
            render={(props) => {
              if (isLogged === null) return <div />;
              if (isLogged) {
                if (roleName === undefined) {
                  return <div />;
                } else if (
                  roleName === "Client Creation" ||
                  roleName === "Operation"
                ) {
                  return (
                    <Redirect
                      to={{
                        pathname: "/app/manage-client",
                        state: {
                          from: props.location,
                        },
                      }}
                    />
                  );
                } else {
                  return (
                    <Redirect
                      to={{
                        pathname: "/app/dashboard",
                        state: {
                          from: props.location,
                        },
                      }}
                    />
                  );
                }
              } else {
                return <Login {...props} />;
              }
            }}
          />
          <ProtectedApp path="/app" component={AdminRoutes} />

          <Route
            exact
            path="/"
            render={(props) => {
              if (isLogged === null) return <div />;
              if (isLogged) {
                if (roleName === undefined) {
                  return <div />;
                } else if (
                  roleName === "Client Creation" ||
                  roleName === "Operation"
                ) {
                  return (
                    <Redirect
                      to={{
                        pathname: "/app/manage-client",
                        state: {
                          from: props.location,
                        },
                      }}
                    />
                  );
                } else {
                  return (
                    <Redirect
                      to={{
                        pathname: "/app/dashboard",
                        state: {
                          from: props.location,
                        },
                      }}
                    />
                  );
                }
              } else {
                return <Login {...props} />;
              }
            }}
          />
          <Route path="/kyc" component={Kyc} />
          <Route path="/doc" component={documentation} />
        </Footer>
        <Route component={NotFound} />
      </Switch>
      <ErrorModal {...errorModalState} />
      <ToastContainer
        position="bottom-right"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={3}
      />
      <ToastEmitter />
    </div>
  );
};
export default App;
