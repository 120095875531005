/* Get the login Token */

import { Config } from "../../../Config";

export const loginToken = () => {
  return localStorage.getItem("pmsToken");
};

export const getRole = () => {
  return localStorage.getItem("role");
};

export const objectIsEmpty = (obj) => {
  //eslint-disable-next-line
  for (let key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }

  return true;
};

export const getImage = (id) => {
  return Config.ImageUrl + id;
};

export const ucFirst = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const firstLetterCapital = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function lowercaseFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

const scrollToPositionFrom = (source, destination) => {
  let id;
  destination -= 120;
  if (destination < 0) destination = 0;
  if (destination > source) {
    id = setInterval(increase, 3);
  } else {
    id = setInterval(decrease, 3);
  }
  function increase() {
    if (source >= destination) {
      clearInterval(id);
    } else {
      source += 10;
      window.scrollTo(0, source);
    }
  }
  function decrease() {
    if (destination >= source) {
      clearInterval(id);
    } else {
      source -= 10;
      window.scrollTo(0, source);
    }
  }
};

export function scrollToError() {
  var error = document.getElementsByClassName("is-invalid");
  if (error[0]) {
    scrollToPositionFrom(
      window.scrollY,
      error[0].getBoundingClientRect().top + window.scrollY
    );
  }
}
