export default function message(state = { message: null }, action) {
  switch (action.type) {
    case "ADD_MESSAGE":
      return { ...state, ...{ message: action.message } };

    case "DELETE_MESSAGE":
      return { ...state, ...{ message: null } };

    default:
      return state;
  }
}
