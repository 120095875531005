import React from "react";
import { Dropdown } from "react-bootstrap";
import { performUserLogoutAction } from "app/store/auth/authAction";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const Avatar = ({ username, dashboard, ...otherProps }) => {
  const dispatch = useDispatch();

  const { loginName } = useSelector((state) => state.getAuth);
  const history = useHistory();

  const handleLogoutCLick = () => {
    performUserLogoutAction(dispatch);
    history.push("/");
  };

  const AvatarIcon = () => {
    return (
      <div
        className="d-flex flex-column align-items-center pointer "
        {...otherProps}
      >
        <span className="img-xs rounded-circle bg-primary text-white d-flex align-items-center justify-content-center font-weight-bold fs-1 text-uppercase">
          {loginName.charAt(0)}
          {loginName.slice(-1)}
        </span>

        <span className="d-block user__name">{loginName}</span>
      </div>
    );
  };

  const { profile } = useSelector((state) => state.getAuth);
  const isClientLogin = profile.roleName === "Client";

  if (!dashboard) return <AvatarIcon />;
  return (
    <li className="nav-item  nav-profile border-0">
      <Dropdown>
        <AvatarIcon />
        <Dropdown.Menu className="preview-list navbar-dropdown pb-3">
          {/* Todo a better implementation */}
          {isClientLogin && (
            <Dropdown.Item
              className="dropdown-item preview-item d-flex align-items-center border-0 mt-2"
              onClick={(evt) => history.push("/app/profile/account")}
            >
              <span>Manage Accounts</span>
            </Dropdown.Item>
          )}
          <Dropdown.Item
            className="dropdown-item preview-item d-flex align-items-center border-0"
            onClick={(evt) => history.push("/app/profile/change-password")}
          >
            <span>Change Password</span>
          </Dropdown.Item>

          <Dropdown.Item
            className="dropdown-item preview-item d-flex align-items-center border-0"
            onClick={(evt) => {
              evt.preventDefault();
              handleLogoutCLick();
            }}
          >
            <span>Sign Out</span>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </li>
  );
};

export default Avatar;
