import {
  PROVINCE_FETCHED,
  BANK_FETCHED,
  BANK_BRANCH_FETCHED,
  COMPANY_BRANCH_FETCHED,
  RELATIONSHIP_FETCHED,
  DISTRICT_FETCHED,
  OCCUPATION_TYPE_FETCHED,
  BUSINESS_TYPE_FETCHED,
  ANNUAL_INCOME_FETCHED,
  DESIGNATION_FETCHED,
  OCCUPATION_DESIGNATION_FETCHED,
} from "./dataActionTypes";

const init = {
  provinces: null,
  banks: null,
  bankBranch: { isEmpty: true },
  companyBranch: null,
  relationship: null,
  districts: null,
  occupationType: null,
  businessType: null,
  annualIncome: null,
  designation: null,
  occupationDesignation: null,
};
const dataReducer = (state = init, action) => {
  const { type, payload } = action;

  switch (type) {
    case PROVINCE_FETCHED:
      return { ...state, provinces: payload };
    case BANK_FETCHED:
      return { ...state, banks: payload };
    case BANK_BRANCH_FETCHED:
      const { id, data } = payload;
      if (state.bankBranch.isEmpty) {
        return { ...state, bankBranch: { [id]: data } };
      }
      return {
        ...state,
        bankBranch: { ...state.bankBranch, [id]: data },
      };
    case COMPANY_BRANCH_FETCHED:
      return { ...state, companyBranch: payload.data };
    case RELATIONSHIP_FETCHED:
      return { ...state, relationship: payload };
    case DISTRICT_FETCHED:
      return { ...state, districts: payload };
    case OCCUPATION_TYPE_FETCHED:
      return { ...state, occupationType: payload };
    case BUSINESS_TYPE_FETCHED:
      return { ...state, businessType: payload };
    case ANNUAL_INCOME_FETCHED:
      return { ...state, annualIncome: payload };
    case DESIGNATION_FETCHED:
      return { ...state, designation: payload };
    case OCCUPATION_DESIGNATION_FETCHED:
      return { ...state, occupationDesignation: payload };
    default:
      return state;
  }
};

export default dataReducer;
