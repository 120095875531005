import { lazy } from "react";

const BuySellTransaction = lazy(() =>
  import("app/pages/ClientLogin/BuySellTransaction")
);
const PortfolioSummaryReport = lazy(() =>
  import("app/pages/ClientLogin/PortfolioSummaryReport")
);
const ChargeReport = lazy(() => import("app/pages/ClientLogin/ChargeReport"));

const chargeRoutes = [
  {
    path: "/app/portfolio-summary-report",
    name: "Portfolio Report",
    component: PortfolioSummaryReport,
    authId: "DASHBOARD",
    icon: "mdi mdi-chart-areaspline",
  },
  {
    path: "/app/buy-sell-transaction",
    name: "Buy Sell Transaction",
    component: BuySellTransaction,
    authId: "DASHBOARD",
    icon: "mdi mdi-gamepad-down",
  },
  {
    path: "/app/charge-report",
    name: "Charge Report",
    component: ChargeReport,
    authId: "DASHBOARD",
    icon: "mdi mdi-gamepad-down",
  },
];

export default chargeRoutes;
