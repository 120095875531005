import { FETCH_DP } from "./ipoActionType";

const init = {
  dp: [],
};

export default function ipoReducer(state = init, { type, payload }) {
  switch (type) {
    case FETCH_DP:
      return { ...state, dp: payload };
    default:
      return state;
  }
}
