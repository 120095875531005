import React from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Card, Button, Container, Col, Row } from "react-bootstrap";
import FormInput from "app/components/FormInput";
import { login } from "app/store/auth/authAction";
import FullPageLoader from "app/shared/loaders/FullPageLoader";
import logo from "assets/images/logo.JPG";
import loginImage from "assets/images/login_image.jpg";
import { apIverifyCode } from "app/store/auth/authAction";
import "./login.scss";
const Login = (props) => {
  const { register, handleSubmit, errors } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [verificationCode, setVerificationCode] = React.useState(null);
  const [didVerified, setDidVerifed] = React.useState(true);
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");

  const performLogin = async (data) => {
    const { loginName, password } = data;

    try {
      dispatch(login(loginName, password))
        .then((res) => {
          if (res?.success) setDidVerifed(false);
        })
        .catch((err) => console.log(err));
    } catch (err) {}
  };

  const verifyCode = () => {
    try {
      dispatch(
        apIverifyCode({
          otp: verificationCode,
          loginName: username,
          password: password,
        })
      ).then((res) => {
        // history.push("/app/dashboard");
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <FullPageLoader style={{ top: 0 }} />

      <Container>
        <Row className="justify-content-md-center align-items-center mt-2">
          {/* <Col lg={6} md={6}>
            <Card>
              <Card.Header style={{ padding: "0px" }}>
                <img src={logo} alt="logo" style={{ width: "100%" }} />
              </Card.Header>
              <Card.Body>
                <img src={loginImage} alt="logo" style={{ width: "100%" }} />
              </Card.Body>
            </Card>{" "}
          </Col> */}

          <Col lg={6} md={3}>
            {didVerified ? (
              <div
                className="card text-left py-3 pb-5 px-4 px-sm-3 mx-5"
                style={{
                  boxShadow: "0px 0px 8px 6px rgb(0 0 0 / 5%)",
                }}
              >
                <Card>
                  <Card.Header style={{ padding: "0px" }}>
                    <img
                      src={logo}
                      alt="logo"
                      style={{ width: "100%", height: "80px" }}
                    />
                  </Card.Header>
                </Card>
                {/* <div className="text-left" style={{marginTop:'50px'}}>
                  <h2 className="font-weight-bold text-gray">Welcome</h2>
                  <h6
                    className="font-weight-light"
                    style={{ color: "#6c757d" }}
                  >
                    Sign in to continue.
                  </h6>
                </div> */}
                <Form className="pt-3" onSubmit={handleSubmit(performLogin)}>
                  <Form.Label>Username</Form.Label>

                  <FormInput
                    name="loginName"
                    placeholder="User Name"
                    register={register}
                    required
                    error={errors.loginName && true}
                    autoFocus
                    helperText={errors.loginName && "Required"}
                    autoComplete="username"
                    onChange={(e) => setUsername(e?.target?.value)}
                    className="username"
                  />

                  <Form.Label>Password</Form.Label>

                  <FormInput
                    name="password"
                    placeholder="Password"
                    register={register}
                    required
                    error={errors.password && true}
                    helperText={errors.password && "Required"}
                    type="password"
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e?.target?.value)}
                    className="password"
                  />

                  <div className="mt-4 text-right">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm font-weight-medium auth-form-btn btn-login"
                      style={{ borderRadius: "50px", padding: "10px 35px" }}
                    >
                      Login
                    </button>
                  </div>

                  {/* <h5 className="mt-3 line">Sign Up</h5> */}
                </Form>

                <Card style={{ marginTop: "50px" }}>
                  <Card.Body
                    style={{ width: "70%", height: "190px", margin: "0 auto" }}
                  >
                    <img
                      src={loginImage}
                      alt="logo"
                      style={{ width: "100%", height: "170px" }}
                    />
                  </Card.Body>
                </Card>
                <div class="mt-3 title-hr">
                  <span class="hr fix"></span>
                  <span>Sign Up</span>
                  <span class="hr"></span>
                </div>
                <div
                  className="mt-3"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div className="text-left mx-2">
                    <button
                      className="btn success"
                      style={{
                        color: "#059669",
                        borderRadius: "30px",
                        borderColor: "#059669",
                        minWidth: "149px",
                      }}
                      onClick={() => history.push("/kyc/client")}
                    >
                      Individual KYC
                    </button>
                  </div>
                  <div className="text-right mx-2">
                    <button
                      className="btn success"
                      style={{
                        color: "#059669",
                        borderRadius: "30px",
                        borderColor: "#059669",
                      }}
                      onClick={() => history.push("/kyc/institution")}
                    >
                      Institutional KYC
                    </button>
                  </div>
                </div>
                {/* <p className="mt-4 text-center mb-1 ">
              <span className="text-muted text-center text-white text-sm-left d-block d-sm-inline-block">
                © {new Date().getFullYear()}
                <br />
              </span>
            </p> */}
              </div>
            ) : (
              <div>
                <div
                  className="card text-left py-3 pb-5 px-4 px-sm-3 mt-3"
                  style={{
                    boxShadow: "0px 0px 8px 6px rgb(0 0 0 / 5%)",
                  }}
                >
                  <div className="text-center">
                    <h4 className="font-weight-normal text-gray">
                      An email with a verification code has been sent to your
                      email.
                    </h4>
                    <h6 className="font-weight-light text-gray">
                      Enter verification code to continue.
                    </h6>
                    <Form onSubmit={handleSubmit(verifyCode)}>
                      <div className="row">
                        <div className="col-lg-10 mt-3 m-auto pt-3">
                          <FormInput
                            name="otp"
                            placeholder="Verification Code"
                            register={register}
                            type="number"
                            required
                            error={errors.otp && true}
                            autoFocus
                            className="p-3 verification-code-input"
                            helperText={
                              errors.otp && "Verification Code is Required"
                            }
                            onChange={(code) =>
                              setVerificationCode(code.target.value)
                            }
                            // onKeyPress ={(e) => verifyCodes(e)}
                          />
                        </div>
                        <div className="col-lg-10 mt-3 m-auto">
                          <button
                            className="btn btn-primary w-100 p-3 verify-code-btn"
                            // onClick={() => verifyCode()}
                          >
                            Continue
                          </button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Login;
