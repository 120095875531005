import {
  BROKER_FETCHED,
  BROKER_ADDED,
  BROKER_CREATED,
} from './brokerActionTypes';

const brokerReducer = (
  state = {
    brokers: [],
    brokersObj: {},
  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case BROKER_ADDED:
      return {
        ...state,
      };
    case BROKER_FETCHED:
      return {
        ...state,
        brokers: payload.arr,
        brokersObj: payload.obj,
        paginationData: payload.paginationData,
      };
    case BROKER_CREATED:
      return {
        ...state,
        brokers: [...state.brokers, payload],
      };
    default:
      return state;
  }
};

export default brokerReducer;
