import { lazy } from "react";
import * as id from "app/authorization/authId";

const Exit = lazy(() => import("app/pages/Charge/Exit"));
const ChargeExpiration = lazy(() =>
  import("app/pages/Charge/ChargeExpiration")
);
const CashWithdrawal = lazy(() => import("app/pages/Charge/CashWithdrawal"));
// const Renew = lazy(() => import("app/pages/Charge/Renew"));
const Performance = lazy(() => import("app/pages/Charge/Peformance"));
const StockWithdrawal = lazy(() => import("app/pages/Charge/StockWithdraw"));
// const ChargeReports = lazy(() => import("app/pages/Charge/Report"));

const chargeRoutes = [
  {
    path: "/app/charge/exit",
    name: "Exit",
    component: Exit,
    authId: id.EXIT,
  },
  {
    path: "/app/charge/charge-expiration",
    name: "Charge Expiration",
    component: ChargeExpiration,
    authId: id.CHARGE_EXPIRATION,
  },
  {
    path: "/app/charge/cash-withdrawal",
    name: "Cash Withdrawal",
    exact: true,
    component: CashWithdrawal,
    authId: id.CASH_WITHDRAWAL,
  },

  {
    path: "/app/charge/performance",
    name: "Performance",
    exact: true,
    component: Performance,
    authId: id.PERFORMANCE,
    // hideInSideBar: true,
  },
  {
    path: "/app/charges/stock-withdraw",
    name: "Stock Withdrawal",
    exact: true,
    component: StockWithdrawal,
    authId: id.STOCK_WITHDRAWAL,
  },
];

export default chargeRoutes;
