import axiosRequest from "./axiosRequest";
import axiosQuery from "./axiosQuery";
import { addMessage } from "actions/message/message";
import { errorHandler } from "app/shared/helpers/ErrorHandler";
import { setDefaultValueForSelect, sortOptionByLabel } from "./hookForm";
import {
  getTodaysDate,
  getDateOffset,
  dateFormatter,
  noOfDaysTillNextYear,
  noOfDaysBetweenDates,
  addMonths,
  convertToAd,
  convertToBs,
  totalDaysInYear,
  addDays,
  timeFormatter,
  formatDate,
} from "./date";
import excelToJSON from "./excelToJSON";

/**
 * Converts Array to object based on "prop" property
 * convertArrayToObject=([{id:1,name:"prajwal",{id:2,name:"pradhan"}}],id) ==> {1:{id:1,name:"prajwal"},2:{id:2:name:"pradhan"}}
 * @param {array} data - the data that is to be converted to object
 * @param {string} prop - the property of each data value that is used to convert array to object
 */
const convertArrayToObject = (data, prop) => {
  return data?.reduce((obj, curr) => {
    return { ...obj, [curr[prop]]: { ...curr } };
  }, {});
};

/**
 * @param {object} data - object with empty fields
 * @returns {object} - returns object with filled fields
 */
const removeEmptyFields = (data) => {
  const temp = { ...data };

  for (var key in data) {
    if (
      temp[key] === "" ||
      temp[key] === null ||
      temp[key] === undefined ||
      Object.is(NaN, temp[key])
    )
      delete temp[key];
  }
  return { ...temp };
};
const removeEmptyFieldsFromArray = (data) => {
  if (Array.isArray(data)) {
    return data.map((e) => removeEmptyFields(e));
  } else {
  }
};
/**
 *
 * @param {number} n - The number to be converted
 * @param {number} x - The number of points that need to appear after decimal
 */
function toFloat(n, x) {
  if (Object.is(NaN, parseFloat(n))) return "-";
  return parseFloat(n).toFixed(x);
}

function toStringDateFormat(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const dateString = `${year}-${month}-${day}`;
  return dateString;
}
/**
 * //TODO please Check if there are any better implementation
 * 123434.00 ==>1,23,434.00
 * @param {string} num - The string number where comma is to be added
 * @return - Returns string
 */
function addCommas(num) {
  try {
    let splitted =
      num < 0
        ? num.toString()?.slice(1)?.split(".")
        : num.toString().split(".");

    if (splitted[0].length > 3) {
      const onlyBeginningParts = splitted[0].slice(0, splitted[0].length - 3);
      const lastParts = splitted[0].slice(splitted[0].length - 3);
      splitted[0] =
        onlyBeginningParts.replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
        "," +
        lastParts;

      return num < 0 ? "-" + splitted.join(".") : splitted.join(".");
    } else return num;
  } catch {
    return "";
  }
}

function removeCommas(num) {
  try {
    return num?.replaceAll(",", "");
  } catch {
    return num;
  }
}
/**
 *
 * @param {number} n The number to be converted
 * @return {number} - Adding only two trailin number/ zero after n
 */
function toFloatAmount(n) {
  if (n === Infinity) return "Infinity";
  return addCommas(toFloat(n, 2));
}

function toFloatQuantity(n) {
  if (n === Infinity) return "Infinity";
  return addCommas(toFloat(n));
}

/**
 *
 * @param {number} n The number to be converted
 * @return {number} - Adding only four trailin number/ zero after n
 */
function toFloatPercent(n) {
  return toFloat(n, 4);
}

function toFloatPercent2(n) {
  return toFloat(n, 2);
}

/**
 * maintain-privilege ==> Maintain privilege
 * import-sell-/-buy-data ==> Import sell/buy data
 * @param {string} str - dashed string;
 * @returns {string} - readable Strings
 */
const convertDashedToReadable = (str) => {
  const remove_dashes = str
    .replaceAll("-/-", "/")
    .replaceAll("-", " ")
    .replaceAll("slash", "/");

  const first_char_cap =
    remove_dashes.charAt(0).toUpperCase() + remove_dashes.slice(1);

  return first_char_cap;
};

/**
 * converts "1/22/2021 12:00:00 AM" = 2021-22-01
 * @param {string} str- "1/22/2021 12:00:00 AM" date in this formate
 */
const convertSlashedDateToDashedDate = (str) => {
  try {
    const date = new Date(str);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, 0);
    const day = String(date.getDate()).padStart(2, 0);
    return year + "-" + month + "-" + day;
  } catch (err) {
    return "-";
  }
};

/**
 * Contact number Validation
 * @param {string} x-98326415726
 */
const checkPhone = (x) => {
  let invalid = /^983|^987|^989|^988/;
  let landline = /^0[0-9]{7}$/;
  let CDMA = /^97[0-9]{8}$/;
  let validStarter = /^98/;
  const result = { valid: false };

  if (isNaN(x)) {
    result.reason = "alphanumeric";
  } else if (invalid.test(x)) {
    result.reason = "invalid Nepali Carrier";
  } else if (landline.test(x)) {
    result.valid = "true";
  } else if (CDMA.test(x)) {
    result.valid = "true";
  } else if (x.length !== 10) {
    if (validStarter.test(x)) {
      result.reason = "invalid length";
    } else result.reason = "random";
  } else result.valid = "true";
  return result;
};

const sortByField = (data, { field, direction = "ASC", type }) => {
  return data?.sort((a, b) => {
    if (type === "date") {
      if (direction === "ASC") return new Date(a[field]) - new Date(b[field]);
      else return new Date(b[field]) - new Date(a[field]);
    }
    const isString = (s) => typeof s === "string";
    const fa = isString(a[field]) ? a[field].toLowerCase() : a[field];
    const fb = isString(b[field]) ? b[field].toLowerCase() : b[field];
    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });
};

const preserveSelect = (obj, selected) => {
  if (Array.isArray(obj)) {
    const allSelected = obj.reduce(
      (obj, e) => {
        return {
          ...obj,
          [e.id]: e,
        };
      },
      { ...selected }
    );

    return allSelected;
  }

  if (obj.isSelected) {
    return { ...selected, [obj.id]: obj };
  }
  let temp = { ...selected };
  delete temp[obj.id];
  return temp;
};

const toBoolean = (data) => {
  if (typeof data === "string") {
    return (
      data.toUpperCase() === "TRUE" ||
      data === "1" ||
      data.toUpperCase() === "YES" ||
      false
    );
  }
  if (typeof data === "boolean") return data;
  // if(typeof data==="number")
};

function debounce(cb, timeout = 300) {
  let timer;
  return (...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      cb.apply(this, args);
    }, timeout);
  };
}

function promiseDebounce(f, interval) {
  let timer = null;

  return (...args) => {
    clearTimeout(timer);
    return new Promise((resolve) => {
      timer = setTimeout(() => resolve(f(...args)), interval);
    });
  };
}

const getParams = (params) => new URL(window.location).searchParams.get(params);
const renderDashIfEmpty = (data) => {
  if (data === null || data === undefined) return " ";
  return data;
};

let sumFromArray = (propertyName, array) => {
  let sum = 0;
  array.forEach((item) => {
    sum += item[propertyName] ?? 0;
  });
  return sum;
};

export {
  promiseDebounce,
  axiosRequest,
  axiosQuery,
  convertArrayToObject,
  debounce,
  removeEmptyFields,
  removeEmptyFieldsFromArray,
  toFloat,
  toFloatAmount,
  toFloatPercent,
  toFloatPercent2,
  convertDashedToReadable,
  convertSlashedDateToDashedDate,
  addMessage,
  errorHandler,
  checkPhone,
  addCommas,
  removeCommas,
  setDefaultValueForSelect,
  sortOptionByLabel,
  sortByField,
  preserveSelect,
  toBoolean,
  excelToJSON,
  getTodaysDate,
  getDateOffset,
  dateFormatter,
  noOfDaysTillNextYear,
  noOfDaysBetweenDates,
  addMonths,
  convertToAd,
  convertToBs,
  totalDaysInYear,
  addDays,
  timeFormatter,
  toStringDateFormat,
  formatDate,
  getParams,
  renderDashIfEmpty,
  sumFromArray,
  toFloatQuantity,
};
