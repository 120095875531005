import {
  getFloorsheets,
  getFloorsheetDetails,
  deleteFloorsheet,
  approveFloorsheet,
} from "./floorsheetActions";
import FloorsheetReducer from "./floorsheetReducer";

export {
  getFloorsheets,
  getFloorsheetDetails,
  deleteFloorsheet,
  approveFloorsheet,
};
export default FloorsheetReducer;
