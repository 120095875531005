import {
  CHARGE_RATE_FETCHED,
  CHARGE_RATE_CREATED,
} from "./chargeRateActionTypes";

const chargeRateReducer = (
  state = {
    chargeRates: [],
  },
  action
) => {
  const { type, payload } = action;

  switch (type) {
    case CHARGE_RATE_FETCHED:
      return {
        ...state,
        chargeRates: payload.contents,
        totalPages: payload.totalPages,
        currentPage: payload.currentPage,
        totalRecords: payload.totalRecords,
      };
    case CHARGE_RATE_CREATED:
      return { ...state, chargeRates: [...state.chargeRates, payload] };
    default:
      return state;
  }
};

export default chargeRateReducer;
