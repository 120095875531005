import React, { useState, useEffect } from "react";
import CustomSelect from "../customSelect";
import { useDispatch } from "react-redux";
import { Controller } from "react-hook-form";
import { Form } from "react-bootstrap";
import { fetchPortfolioAccountListsForSelect } from "api/portfolioAccounts";
import { sortOptionByLabel } from "app/utils";
import { axiosRequest, promiseDebounce } from "app/utils";
import { toast } from "react-toastify";
const PortFolioAccountSelect = (props) => {
  const {
    required,
    error = false,
    name = "clientName",
    subLabel = "contactNumber",
    defaultValue,
    defaultName,
    setValue,
    helperText,
    onChange: onChangeFromProps,
    ...otherProps
  } = props;
  const dispatch = useDispatch();
  const [clientOptions, setClientOptions] = useState();
  const [data, setData] = useState();
  const [defaultVal, setDefaultVal] = useState();
  //console.log("default value -->", defaultValue);
  const dataFormatter = (data) =>
    data?.map((e) => {
      return {
        ...e,
        value: e.portfolioAccountId,
        label: `${e.name} (${e.portfolioAccountNumber})`,
        accountNumber: e.portfolioAccountNumber,
      };
    });
  useEffect(() => {
    dispatch(fetchPortfolioAccountListsForSelect()).then((res) => {
      const new_options = res.map((x) => ({
        ...x,
        value: x.portfolioAccountId,
        label: `${x.name}(${x?.portfolioAccountNumber})`,
      }));

      setClientOptions(new_options);
    });
  }, [dispatch, subLabel]);


  
  useEffect(() => {
    if (defaultValue) {
  
      axiosRequest
        .get(`portfolioAccounts/list?Id=${defaultValue}`)
        .then((res) => {
         // console.log(res.data);
         
  
        setDefaultVal({
          label: `${res?.data[0].name}(${res.data[0]?.portfolioAccountNumber})`,
          value: res.data[0].portfolioAccountId,
        });
        setValue(name, {
          label: `${res?.data[0].name}(${res.data[0]?.portfolioAccountNumber})`,
          value: res.data[0].portfolioAccountId,
        });

       
           
     
        });
    }
  }, [defaultValue, name, setValue, clientOptions]);

  useEffect(() => {
    if (setValue && defaultValue)
      if (defaultName) {
        const temp = clientOptions?.find(
          (e) => e[defaultName] === defaultValue
        );
        setValue(name, temp);
      } else {
        const temp = clientOptions?.find((e) => e.value === defaultValue);
        setValue(name, temp);
      }
  }, [clientOptions, defaultValue, defaultName, name, setValue]);


  const promiseOptions = (inputValue) => {
    const islengthMultipleOfThree = !(inputValue.length % 1);

    if (inputValue.length === 1 || islengthMultipleOfThree) {
      return new Promise((resolve) => {
        dispatch(fetchPortfolioAccountListsForSelect(inputValue)).then(
          (res) => {
            setData(sortOptionByLabel(dataFormatter(res)));
            resolve(sortOptionByLabel(dataFormatter(res)));
          }
        );
      });
    }

    return new Promise((resolve) => {
      resolve(
        data?.filter((e) => {
          const regex = new RegExp(inputValue, "gi");
          return regex.test(e.name) || regex.test(e.stockSymbol);
        })
      );
    });
  };

  const debouncedOptions = promiseDebounce(promiseOptions, 700);

  const portfolioDropdown = (
    <Controller
      name={name}
      render={({ onChange, ...rest }) => (
        <CustomSelect
          async
          name={name}
          className={error && "select__error"}
          onChange={(e) => {
            onChangeFromProps && onChangeFromProps(e);
            onChange(e);
          }}
          loadOptions={debouncedOptions}
          isSearchable={true}
          {...rest}
        />
      )}
      placeholder={error ? "Please Select Client" : "Select Client"}
      defaultValue={defaultVal} //formatting the value as required by react-select based on the value prop provided;
      rules={{ required }}
      {...otherProps}
    />
  );

  return (
    <>
      {!defaultVal && portfolioDropdown}

      {defaultVal && portfolioDropdown}

      {helperText && (
        <Form.Control.Feedback type="invalid">
          {helperText}
        </Form.Control.Feedback>
      )}
    </>
  );
};

export default PortFolioAccountSelect;
