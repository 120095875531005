import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { convertDashedToReadable } from "app/utils";
import { Link } from "react-router-dom";

// interface NavProps{
//     title:string,
//     onClick?:()=>{},
//     current?:boolean
// }

/**
 * A common Header UI for Broker views
 * @param {string} title - title of the header
 * @param {array} nav - NavProps Array
 */
const Header = () => {
  const location = useLocation();
  const history = useHistory();
  let nav = [];

  const arr = location.pathname.split("/");

  let path = "/app";
  arr.forEach((e, i) => {
    if (e === "app") {
    } else if (e === "") {
    } else {
      const isLastItem = i === arr.length - 1;
      const repairedString = convertDashedToReadable(e);
      path += "/" + e;
      nav.push({
        title: repairedString.replaceAll("/", "or"),
        path,
        onClick: (e) => {
          e.preventDefault();
        },
        current: isLastItem,
      });
    }
  });
  return (
    <div className="d-flex w-100 pb-2 mb-0">
      <span className="font-weight-bold ">
        <i
          className="mdi mdi-arrow-left pointer"
          onClick={() => history.goBack()}
        ></i>
      </span>
      <nav aria-label="breadcrumb p-0 ">
        <ol className="breadcrumb mb-0 px-3 py-0">
          {nav.map((e, i) => {
            if (e.current)
              return (
                <li
                  className="breadcrumb-item active "
                  aria-current="page"
                  key={i}
                >
                  {e.title}
                </li>
              );
            else
              return (
                <li className="breadcrumb-item link" key={i}>
                  <Link to={e.path} className="link">
                    {e.title}
                  </Link>
                </li>
              );
          })}
        </ol>
      </nav>
    </div>
  );
};

export default Header;
