//Comment the line below for authorization

//Dummy data for mocking all access permissions
const allPermissions = ["CREATE", "READ", "UPDATE", "DELETE", "APPROVE"];
const IPOAllPermissions = [
  "SAVE",
  "APPLY",
  "APPROVE",
  "READ",
  "UPDATE",
  "DELETE",
];
const dashboardPermissions = ["CLIENT"];

const permissions = {
  DASHBOARD: dashboardPermissions,
  DEALER_DASHBOARD: allPermissions,
  BROKER_DASHBOARD: allPermissions,

  //=====================Setup  Permissions=======//
  SETUP_BROKER: allPermissions,
  SETUP_USER: allPermissions,
  SETUP_ROLE: allPermissions,
  MAINTAIN_PRIVILEGE: allPermissions,
  ASSIGN_ROLES: allPermissions,
  SETUP_CHARGE: allPermissions,
  SETUP_CHARGE_RATE: allPermissions,
  SETUP_PRODUCT: allPermissions,
  SETUP_TRANSACTION_TYPE: allPermissions,
  CLIENT_AND_BROKER_MAPPING: allPermissions,
  SETUP_BANK: allPermissions,
  SETUP_COMPANY: allPermissions,
  MERO_SHARE_CREDENTIAL: allPermissions,
  SETUP_SMTP: allPermissions,
  SETUP_OFFICIAL_BANK_ACCOUNT: allPermissions,
  //===============================================//

  //========Client Registration Permissions========//
  CLIENT_MANAGEMENT: allPermissions,
  //===============================================//

  //=====================Order Mgmt==============//
  UPLOAD: allPermissions,
  BUY_ORDER: ["CREATE"],
  SELL_ORDER: allPermissions,
  ORDER_LIST: allPermissions,
  //=============================================//

  //=====Portfolio Permissions==================//
  PORTFOLIO_CREATION: allPermissions,
  PORTFOLIO_TRANSACTION: allPermissions,
  ADDITIONAL_INVESTMENT: allPermissions,
  PORTFOLIO_EXPIRATION_LIST: allPermissions,
  RENEWED_EXTENDED_LIST: allPermissions,
  FD_APPROVAL: allPermissions,
  FD_INTEREST: allPermissions,
  //==========================================//

  //Order From Broker Routes
  BILL_MAPPING: allPermissions,

  //reports
  PORTFOLIO_SUMMARY_REPORT: allPermissions,
  CHARGE_REPORTS: allPermissions,
  BANK_STATEMENT: allPermissions,
  STOCK_DEVIATION_REPORT: allPermissions,
  FIXED_RETURN_REPORT: ["CREATE"],
  STOCK_STATEMENT: allPermissions,
  TOTAL_CLIENTS: allPermissions,
  FR_SEBON_REPORT: ["DELETE"],
  CLIENT_INFORMATION_REPORT: ["REPORT"],
  //Billing
  GENERATE_BILL: allPermissions, //////create new one kundan
  CREATE_BILL_PAYMENT: allPermissions,
  CREATE_BILL_RECEIPT: allPermissions,
  CREATE_SELL_RECEIPT: allPermissions,
  VIEW_PAYMENT: allPermissions,
  VIEW_RECEIPT: allPermissions,
  BILLS_LIST: allPermissions,
  RECEIPT: allPermissions,
  BANK_RECONCILIATION: allPermissions,

  //Accounting
  //Accounting<PaymentReceiptCreate authId={authId} type="Buy" /
  PAYMENT: allPermissions,
  ACCOUNTING: allPermissions,
  BILL: allPermissions,

  //===========Corporate_Actions===============//
  ANNOUNCEMENT: allPermissions,
  ANNOUNCEMENT_ADJUSTMENT: allPermissions,
  ANNOUNCEMENT_DETAIL: allPermissions,
  ALLOTMENT: allPermissions,
  IPO: IPOAllPermissions,
  //==========================================//

  FR_DASHBOARD: allPermissions,
  BANK_ASSESSMENT: allPermissions,
  BFI_BANK_ACCOUNT: allPermissions,
  FR_ACCOUNT: allPermissions,
  FRPAYMENT: allPermissions,
  FR_REPORTS: allPermissions,
  FR_INTEREST_REPORTS: allPermissions,
  BFI_BANK_LIMIT: allPermissions,
  FR_INTEREST: allPermissions,

  //exitCharge
  EXIT: allPermissions,
  AMC_DMAT: allPermissions,
  CASH_WITHDRAWAL: allPermissions,
  RENEW: allPermissions,
  CHARGE_EXPIRATION: allPermissions,
  // REPORT: allPermissions,
  PERFORMANCE: allPermissions,

  //================DATA_ANALYTICS==============//
  DATA_ANALYTICS: allPermissions,
  //============================================//
  KYC: [],
  KYC_OFFICIAL_USE: allPermissions,
  CHANGE_PASSWORD: allPermissions,
};
export default permissions;
