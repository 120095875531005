import { Config } from "Config";

//Add constant values here
const values = {
  DEFAULT_PAGE_SIZE: 15,
  AMC_CHARGE_ID: Config.amcChargeId,
  PF_CHARGE_ID: Config.pfChargeId,
  ELF_CHARGE_ID: Config.elfChargeId,
  PORTFOLIO_ANALYST_ID: Config.PortfolioAnalyst,
  RESOURCE_MANAGER_ID: Config.ResourceManager,
};
//69 => pmstest
// 84=>Live
// 100 =>live clone
const constant = Object.freeze(values);

// const denyUndefined = (target) => {
//   const handler = {
//     get: (obj, key) => {
//       if (key in obj) return obj[key];
//       else
//         throw new Error(
//           `Property ${key} doesn't exist in constant. If you need additional constant, please add the value in constant.js file.`
//         );
//     },
//     set: (obj, key, value) => (obj[key] = value),
//   };
//   return new Proxy(target, handler);
// };

export default constant;
