import React from "react";
import Select from "react-select";
import AsyncSelect from "react-select/async";

const sortOptionByLabel = (options) =>
  options?.sort((a, b) => {
    const isString = (s) => typeof s === "string";
    const fa = isString(a.label) ? a.label.toLowerCase() : a.label;
    const fb = isString(b.label) ? b.label.toLowerCase() : b.label;

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });

const CustomSelect = React.forwardRef((props, ref) => {
  const options = props.options;

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: 12,
      textAlign: "left",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
    placeholder: (provided, state) => ({
      ...provided,
      fontSize: 12,
    }),
    control: (provided, state) => ({
      ...provided,
      padding: "2px",
      border: `1px solid ${props.error ? "#f11c1c" : "#b3b3b3"}`,
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 2,
    }),
    container: (provided, state) => ({
      ...provided,
      height: 42,
    }),
  };
  if (props.async) {
    return (
      <AsyncSelect
        cacheOptions
        defaultOptions
        styles={customStyles}
        {...props}
        ref={ref}
        isClearable
      />
    );
  }
  return (
    <Select
      styles={customStyles}
      options={options}
      {...props}
      ref={ref}
      isClearable
    />
  );
});

export default CustomSelect;
